import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Navigate
} from "react-router-dom";
import Masonry from 'react-masonry-css'
import Button from './Buttons';
import {BsGeoAlt, BsCurrencyPound, BsBriefcase, BsChevronDown, BsChevronUp, BsFillShareFill, BsHeart, BsClock, BsLaptop} from 'react-icons/bs'
import {FaRegBuilding, FaIndustry, FaRegGem, FaGraduationCap} from 'react-icons/fa'
import moment from 'moment'
import '../css/search.css'
import { BiLinkExternal } from 'react-icons/bi';
import axios from 'axios';

const SearchUrl = "https://api.archjobs.co.uk/search"
const applyAnalyticsUrl = "https://api.archjobs.co.uk/application_analytics"

const BuildingIcon = <svg className="JobAdIcon" width="24" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 5a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v15a1 1 0 1 1-2 0V6H7v14a1 1 0 1 1-2 0V5z" fill="currentColor"/><path d="M11 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM11 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2 11a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2H4v8a1 1 0 1 1-2 0v-9zM17 11a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v9a1 1 0 1 1-2 0v-8h-2a1 1 0 0 1-1-1z" fill="currentColor"/></svg>


const InfoTagIcons = {
  "sector": {name: "Sectors", "icon": BuildingIcon}, 
  "software": {name: "Software", "icon": <BsLaptop className="JobAdIcon"/>}, 
  "work_arrangement": {name: "Work Style", "icon": <BsBriefcase className="JobAdIcon"/>}, 
  "experience": {name: "Experience", "icon": <FaGraduationCap className="JobAdIcon"/>}, 
}

class JobAd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      layout: "Default",
      navigate: false
    };  
}    

  componentDidMount = () => {
    document.getElementById("JobDescription"+String(this.props.data.job_id)).innerHTML = this.props.data.job_description.replaceAll('<p><br></p>', "")
    window.addEventListener("resize", this.getSize.bind(this));
    this.getSize()
  }

  getTitle = (type) => {
    if (type in InfoTagIcons){
      return <div className="JobAdSummaryTitle">{InfoTagIcons[type].icon} {InfoTagIcons[type].name}</div>
    }
  }

  
  getSize = () => {
    var pagewidth = window.innerWidth
    var layout = "Default"
    if (pagewidth < 600){
      layout = "Mobile"
    }
    // } else if (pagewidth < 991){
    //   layout = "Small"
    // } else if (pagewidth < 1741){
    //   layout = "Medium"
    // } 
    if (this.state.layout !== layout){
      this.setState({layout: layout})
    }
  }

  apply = (jobData) => {
    const Job_ids = jobData.job_id
    let eventType = "apply"
    
    if (jobData.apply_method >= 16){
      eventType = "apply_offsite"
    }
    let page = "job"
    if (this.props.isSearch){
      page = "search"
    }

    axios.post(applyAnalyticsUrl+`?job_ids=${Job_ids}&event=${eventType}&page=${page}`).then(response => {
      console.log("Analytics response: ", response.data)
    })

    if (jobData.apply_method >= 16){
      window.open(
            jobData.apply_url,
            '_blank' // <- This is what makes it open in a new window.
          )
    } else {
      this.setState({navigate: true})
    }
  }

  render() {

    var img_url="https://cdn.archjobs.co.uk/file/Archjobs/company_logos/" +this.props.data.company_name.replaceAll("/[\W_]+/g", "").replaceAll(" ", "").replaceAll("\\", "").toLowerCase()+".png"

    var salary

    if (this.props.data.salary_min){
      salary = <div className="SearchResultSalary"><BsCurrencyPound className="SalaryIcon"/>{this.props.data.salary_min} - {this.props.data.salary_max}</div>
    } else {
      var sectors = this.props.data.parsed_tags.filter(row => row.type == "sector")
      if (sectors.length > 3){
        sectors = sectors.slice(0, 3)
      }
      salary = <div className="SearchResultSectors"><BsBriefcase className="SectorIcon"/>{sectors.map(row => row.tag).join(", ")}</div>
    }

    var location = ""
    var JobAdHeaderInfoRow = []
    this.props.data.parsed_location_list.forEach(parsed_location => {
      // if (parsed_location.label){
      //   JobAdHeaderInfoRow.push(parsed_location.label)
      //   JobAdHeaderInfoRow.push(<div className="RowDivider"></div>)
      // } else 
      if (("locality" in parsed_location) && (parsed_location != null)){
        location = parsed_location.locality
        // if (parsed_location.county && (parsed_location.locality != parsed_location.county)){
        //   location += ", " + parsed_location.county
        // }
        JobAdHeaderInfoRow.push(location)
        JobAdHeaderInfoRow.push(<div className="RowDivider"></div>)
      } else if ("region" in parsed_location) {
        location = parsed_location.region
        JobAdHeaderInfoRow.push(location)
        JobAdHeaderInfoRow.push(<div className="RowDivider"></div>)
      }
    })

    if ("job_type" in this.props.data.parsed_tag_types){
      this.props.data.parsed_tag_types.job_type.forEach(job_type => {
        JobAdHeaderInfoRow.push(job_type)
        JobAdHeaderInfoRow.push(<div className="RowDivider"></div>)
      })
    }
    
    if ("other" in this.props.data.parsed_tag_types){
      this.props.data.parsed_tag_types.other.forEach(other => {
        JobAdHeaderInfoRow.push(other)
        JobAdHeaderInfoRow.push(<div className="RowDivider"></div>)
      })
    }
    

    var closing_date = new Date(this.props.data.posted)
    closing_date.setDate(closing_date.getDate() + 28)
    if (this.props.data.deadline){
      closing_date = new Date(this.props.data.deadline)
    }
    var closing_date_string = moment(closing_date).format('D MMM, YYYY')
    JobAdHeaderInfoRow.push("Closing Date: "+closing_date_string)
    // JobAdHeaderInfoRow.push(<div className="RowDivider"></div>)
    // JobAdHeaderInfoRow = JobAdHeaderInfoRow.slice(0, -1)

    const InfoTags = ["sector", "software", "work_arrangement", "experience"]
    var SummaryInfo = []
    InfoTags.forEach(tag => {
      if (tag in this.props.data.parsed_tag_types){
        SummaryInfo.push({"type": tag, "tags": this.props.data.parsed_tag_types[tag]})
      }
    })
    SummaryInfo = SummaryInfo.sort((a, b) => b.tags.length - a.tags.length)

    var JobAdSummaryLeft = []
    var JobAdSummaryRight = []

    if (this.props.data.salary_min) {
      if (this.props.data.salary_max) {
        JobAdSummaryLeft.push(<div className="JobAdSummarySection">
          <div className="JobAdSummaryTitle"><BsCurrencyPound className="SalaryIcon"/> Salary</div>
          <ul>
            <li>{this.props.data.salary_min.toLocaleString()} - {this.props.data.salary_max.toLocaleString()}</li>
          </ul>
        </div>)
      } else {
        JobAdSummaryLeft.push(<div className="JobAdSummarySection">
        <div className="JobAdSummaryTitle"><BsCurrencyPound className="SalaryIcon"/> Salary</div>
        <ul>
          <li>{this.props.data.salary_min.toLocaleString()}+</li>
        </ul>
      </div>)
      }
    }

    if (this.props.data.usp) {
      JobAdSummaryLeft.push(<div className="JobAdSummarySection">
        <div className="JobAdSummaryTitle"><FaRegGem className="USPIcon"/> Highlights</div>
        <ul>
          {this.props.data.usp.split(", ").map(usp_item => <li>{usp_item}</li>)}
        </ul>
      </div>)
    }

    if (SummaryInfo.length > 0) {
      JobAdSummaryLeft.push(<div className="JobAdSummarySection">
        {this.getTitle(SummaryInfo[0].type)}       
        <ul>
          {SummaryInfo[0].tags.map(list_item => <li>{list_item}</li>)}
        </ul>
      </div>)
    }

    if (SummaryInfo.length > 1) {
      SummaryInfo.slice(1).forEach(tag_type => {
        JobAdSummaryLeft.push(<div className="JobAdSummarySection">
        {this.getTitle(tag_type.type)}
        <ul>
          {tag_type.tags.map(list_item => <li>{list_item}</li>)}
        </ul>
      </div>)
      })

    }

    var ApplyButton
    if (closing_date > Date.now()){
      if (this.props.data.disabled == 1){
          ApplyButton = <div className="ErrorMessage" style={{fontSize: "20px"}}>Job Closed</div>
      } else if (this.props.data.apply_method >= 16){
        ApplyButton = <Button btnClassName="JobAdHeaderApplyButton" onClickFunc = {() => {this.apply(this.props.data)}}>Apply Now <BiLinkExternal/></Button>
      } else {
        ApplyButton = <Button btnClassName="JobAdHeaderApplyButton"  onClickFunc = {() => {this.apply(this.props.data)}}>Apply Now</Button>
      }
    } else {
      ApplyButton = <div className="ErrorMessage" style={{fontSize: "20px"}}>Job Expired</div>
    }
    return <div className="JobAd" key={this.props.data.job_id}>
          {this.state.navigate && <Navigate to={{pathname: "/apply?job_id="+this.props.data.job_id, state: this.props.data}} />} 
          <div className="JobAdHeader">
            <div className="JobAdHeaderInfo">
              <div className="SearchResultTitle">{this.props.data.job_title}</div>
              <div className="JobAdHeaderInfoCompany">{this.props.data.company_name}</div>
              <div className="JobAdHeaderInfoRow">{JobAdHeaderInfoRow}</div>
              {/* <div className="JobAdHeaderInfoClosing">Closing Date: {closing_date_string}</div> */}
            </div>
            <div className="JobAdHeaderApply">
                {ApplyButton}
            </div>
          </div>
          <div className="JobAdSummary">
            <Masonry
              breakpointCols={this.state.layout == "Mobile"? 2: 3}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {JobAdSummaryLeft}
            </Masonry>
          </div>
          <div className="JobAdDescriptionHeader">
            Job Description
          </div>
          <div className="SearchResultsMain" id={"JobDescription"+String(this.props.data.job_id)}>

          </div>
          <div className="JobAdApplyCentered">
           {ApplyButton}
          </div>
      </div>
  }
}

export default JobAd