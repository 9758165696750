import React, { Component } from 'react'
import Button from '../components/Buttons'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ContactForm from './ContactForm'


class RecruitmentPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }


  render() {
    return <div className="HomePage" >
      <div className="WhyWorkWithUsBanner">
        <div className="WhyWorkWithUsBannerTextContainer">
          <h1 className="WhyWorkWithUsSectionTitle">ArchJobs Recruitment</h1>
          <p className = "WhyWorkWithUsSectionSubtitle">
            Matching you to top architectural practices in Dubai
          </p>

        </div>
        <div className="WhyWorkWithUsBannerBG WhyWorkWithUsBannerJobseekerBG"> </div>
      </div>
      {/* <div className="HomePageSection RecruitmentFirstSection">
        <div className="HomePageSectionText"> */}
            {/* <h2 className="HomePageSectionTitle">Are you looking for your next architectural role in Dubai?</h2> */}
            {/* <Link to="/submit-cv" className="Link">
              <Button className="Button">Submit CV</Button>
            </Link> */}
            {/* <a href="https://recruitcrm.io/talent_pool_submission/ArchJobs" target="_blank" rel="noreferrer" className="Button">
            Submit CV</a> */}
        {/* </div>
      </div> */}
      <div className="WhyWorkWithUsSection" id="ChoosePackageSection">
          <div className="HomePageSectionTitle">Why Work With Me?</div>
          <div className="HomePageSectionSubtitle"  style={{textAlign:"center"}}>Open Communication, Support and Industry Knowledge</div>
          <div className="PricingPackages CoachingPricingPackages WorkWithMeList">
            <div className="HomePageWorkWithMeReason">
              <div className="WorkWithMeNumber">
                1
              </div>
              <div className="WorkWithMeText">
                I take pride in getting to know you and learning more about your skills, experiences, and ambitions, in order to put you in front of the right practices.
              </div>
            </div>
            <div className="HomePageWorkWithMeReason">
              <div className="WorkWithMeNumber">
                2
              </div>
              <div className="WorkWithMeText">
                I’ll be there through every single step of your job search, from helping you with your CV and sample portfolio, right up to interview prep and negotiating the best offer.
              </div>
            </div>
            <div className="HomePageWorkWithMeReason">
              <div className="WorkWithMeNumber">
                3
              </div>
              <div className="WorkWithMeText">
                My years of experience within the sector have given me a keen eye for matching you with suitable practices, allowing for targeted approaches and saving you time.
              </div>
            </div>
          </div>
        </div>
      {/* <div className="HomePageSectionSeparatorContainer">
        <div className="HomePageSectionSeparator"></div>
      </div> */}
      <div className="HomePageSection RecruitmentHomePageSection">
        <div className="HomePageSplitSection SplitRight">
          <div className="SplitSectionText">
            <h2 className="HomePageSectionTitle">Hi, I’m Aylin the founder and owner of ArchJobs Recruitment.</h2>
            <p className="HomePageSectionSubtitle">
              As someone who has been active within the UK architectural market for nearly 5 years, I’m witnessing a market shift. Talented professionals, like yourself, are increasingly relocating to the Middle East to join forward-thinking practices and work on boundary-pushing projects.
            </p>
            <p className="HomePageSectionSubtitle">
              I want to bridge the gap between you and those practices. If you're an ARB/RIBA registered Architect, ACIAT/MCIAT Architectural Technologist, BIM professional, Associate or Director then I’m the recruiter for you.
            </p>
            {/* <Link to="/submit-cv" className="Link">
              <Button className="Button">Contact Me</Button>
            </Link> */}
            <a href="https://recruitcrm.io/jobs/archjobs" target="_blank" rel="noreferrer" className="Button">
            View Jobs</a>
          </div>
          <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/aylin_round_profile_picture_medium.jpg" alt="Aylin Round - Archjobs Recruitment"/>
        </div>
      </div>
      <div className="HomePageSection MyApproachSection">
        <h2 className="HomePageSectionTitle" style={{textAlign:"center"}}>What Working With Me Looks Like</h2>
        <p className="HomePageSectionSubtitle"  style={{textAlign:"center"}}>I’ll be there throughout the whole process. </p>
        <div className="MyApproachList">
          <div className="MyApproachLine"></div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">1</div>
            <div className="MyApproachText">Everything will start with an in-depth conversation, as I want to learn as much about you, your skills and ambitions as possible. If your CV and Sample Portfolio aren’t up-to-date yet, don’t worry, we can work on it together. </div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">2</div>
            <div className="MyApproachText">I will curate a list of suitable practices, and we will go through them together. You can then tell me whether you are interested in them or not. Only with your permission will I share your CV and Sample Portfolio with my clients.</div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">3</div>
            <div className="MyApproachText">This is the stage where you’ll either get invited for an interview or not. I will keep you in the loop.  </div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">4</div>
            <div className="MyApproachText">Wohoo! You got an interview request. I will support you in preparing for it and provide you with any tips. </div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">5</div>
            <div className="MyApproachText">Let’s have a quick catch up after your interview and tell me your thoughts. In the meantime I will also ask for feedback from my client. </div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">6</div>
            <div className="MyApproachText">If the interview(s) went well and they would like to make you an offer, I will be in the background negotiating the best offer for you before I give you a call. </div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">7</div>
            <div className="MyApproachText">Once you accept and sign your new job offer, I’d like to stay in contact and be there in case you have any questions before you start your new job. </div>
          </div>
        </div>
        <Link to="/submit-cv" className="Link">
          <Button className="Button">Work With Me</Button>
        </Link>
      </div>
      <div className="HomePageSection RecruitmentFirstSection">
        <div className="HomePageSectionText">
              <h2 className="HomePageSectionTitle">A Recruiter That Cares</h2>
              <p className="HomePageSectionSubtitle">I’m passionate about sharing my knowledge to help aspiring architects. My advice to job seekers has been featured in...</p>
              <div className="HomePageLogos">
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/building_design_logo.png" alt="BD Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/architects_journal_logo_small.jpg" alt="Architects Journal Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/arch_social_logo.png" alt="Architecture Social Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/aspiration_logo.jpg" alt="Aspiration Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/archademia_logo.png" alt="Archidemia Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/ciatwessex_logo.png" alt="CIAT Wessex Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/CareerSidekick.png" alt="Career Sidekick Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/architecturebusinessclub.jpg" alt="Architecture Business Club Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/thebrokearchitectpodcast_logo.png" alt="The Broke Architect Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/riba.png" alt="RIBA Logo"/>
                <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/grandbestexpo_logo.jpg" alt="Grand Best Expo Logo"/>
              </div>
          </div>
      </div>
      <div className="HomePageSectionSeparatorContainer">
        <div className="HomePageSectionSeparator"></div>
      </div>
      <div className="HomePageSection NoBottomBorder">
        <div className="HomePageSectionText" style={{marginBottom: "60px"}}>
            <div className="HomePageSectionTitle">100% Confidential</div>
            <p className="HomePageSectionSubtitle">I will never share your name or your documents unless you give me permission to do so.</p>

        </div>
        <div className="HomePageSectionText" style={{marginBottom: "40px"}}>
            <div className="HomePageSectionTitle">Like what you've read so far?</div>
            <p className="HomePageSectionSubtitle" style={{display: "flex", alignItems:"center", flexDirection: "column"}}>
              If you'd like to work together, please contact me using the form below.
           </p>
        </div>  
      </div>
      <div className="WhyWorkWithUsSection WhyWorkWithUsDarkSection" style={{paddingBottom: "60px"}}>
        <ContactForm type="Candidate"></ContactForm>
      </div>
  </div>  
  }
}

/* export the component to be used in other components */
export default RecruitmentPage