import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class UKChecklistContract extends React.Component {
    constructor(props) {
      
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="UKChecklistContract"  >
       <p>Whether it&rsquo;s your first architectural role in the UK or you&rsquo;re changing positions, it&rsquo;s important to fully understand your employment contract before signing. Even if you&rsquo;re told, &ldquo;This is our standard contract.&rdquo; it&rsquo;s still essential to read it thoroughly and check that all the information is correct.</p>
      <p>In under 3 minutes, you'll learn which 14 key sections to pay extra attention to.</p>
      <p/>
      <h3>1. Personal Details</h3>
      <p>Ensure all your personal information, such as your name and address, is correct in the contract. Any typos or inaccuracies could lead to complications down the line.</p>
      <p/>
      <h3>2. Start Date &amp; Job Title</h3>
      <p> Verify that the start date and job title in the contract align with what was discussed in your interviews or offer letter.</p>
      <p/>
      <h3>3. Salary</h3>
      <p>Double-check your base salary. If you managed to negotiate a higher salary, make sure the contract reflects that. Ensure it aligns with what was promised during the negotiation process.</p>
      <p/>
      <h3>4. Working Hours</h3>
      <p>The standard working hours in most roles are between 35-40 hours per week. Confirm the hours you&rsquo;re expected to work and whether there&rsquo;s any flexibility or expectations for overtime.</p>
      <p/>
      <h3>5. Notice Period</h3>
      <p>The notice period should align with your role. For most employees, this is typically 4 weeks, though it can vary depending on seniority. More senior staff might have up to 3 months. Contractors often have a shorter notice period of 1-2 weeks.</p>
      <p/>
      <h3>6. Holiday Allowance</h3>
      <p>Ensure the contract states at least 20 days of annual leave, plus 8 bank holidays.</p>
      <p/>
      <h3>7. Working Arrangements</h3>
      <p>Confirm whether the role is hybrid, flexible, or office-based. It’s important to clarify these details early, especially if you have specific preferences or needs for remote work, including how many days per week you can work from home (if applicable).</p>
      <p/>
      <h3>8. Bonus Structure</h3>
      <p>If applicable, review the bonus structure. Check how it&rsquo;s determined, when it&rsquo;s paid, and whether it&rsquo;s achievable.</p>
      <p/>
      <h3>9. Job Description/Responsibilities</h3>
      <p>Ensure the job description reflects what was discussed in your interviews. It should clearly outline your primary responsibilities and expectations for your role.</p>
      <p/>
      <h3>10. Probationary Period</h3>
      <p>Probation periods can range from 3 to 6 months, depending on the employer. Review this section to understand the terms and expectations during your initial period of employment.</p>
      <p/>
      <h3>11. Intellectual Property Ownership</h3>
      <p>Clarify who owns intellectual property during your employment. This typically applies to items like laptop, designs etc.</p>
      <p/>
      <h3>12. Paid Memberships</h3>
      <p>If the employer covers professional memberships (such as ARB, AABC, RIBA, etc.), make sure this is included in the contract. Some employers will cover the cost of your professional fees, while others may not.</p>
      <p/>
      <h3>13. Pension Contribution</h3>
      <p>Check the employer&rsquo;s pension contribution rate, especially if it&rsquo;s more than the standard 3%.</p>
      <p/>
      <h3>14. Other Benefits</h3>
      <p>Look for additional benefits like overtime pay, car allowance, season ticket loans, health insurance, maternity or paternity leave and so on. These benefits can improve your overall compensation package.</p>
      <p/>
      <p>If you want to learn more about contract offers and which benefits are good and which are mandatory in the UK, read <a href="https://www.archjobs.co.uk/articles/everything-you-need-to-know-before-signing-an-employment-contract"><strong>Everything You Need to Know Before Signing an Employment Contract</strong></a>.</p>

        </ArticleFrame>
            }
}