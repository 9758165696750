import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';

const thisArticleData = "CoverLetter"

export default class CoverLetter extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
          <p>In this article, I will explain why a well-written cover letter is essential for your job application and offer tips on how to structure one effectively. I will also address common questions, such as whether you need to submit a cover letter and whether generic cover letters are effective.</p>
          <p/>
          <p/>
          <h3>What is a Cover Letter?</h3>
          <p>&ldquo;<em>The cover letter is a formal business letter which is often the first contact with a prospective employer. It serves as an introduction to you and your background experience. Since it is usually the first impression you make on the employer, you want it to be your best.</em>&rdquo;&nbsp;</p>
          <p>Source: <a href="https://www.oxy.edu/sites/default/files/assets/cover-letters_0.pdf" target="_blank" rel="nofollow noopener">Oxy.edy</a></p>
          <p/>
          <p/>
          <h3>General Formatting Tips</h3>
          <div>File type: Ideally email, if not PDF</div>
          <div>Font type: Easy to read fonts are Arial, Times New Roman or Calibri</div>
          <div>Font size: 10-11</div>
          <div>Pages: Max 1 - ideally only half a page</div>
          <div>Personal details: Include full name, phone number and email adress</div>
          <p/>
          <p/>
          <h3>How Do I Research a Company for My Cover Letter?</h3>
          <p>Researching a company effectively is key to crafting a tailored cover letter. Here are some tips on where to find information about the company’s culture, projects, and values:</p>
          <p><strong>Here are some ways to research a company:</strong></p>
          <ol>
          <li><strong>Company Website:</strong>  Start with the "About Us" section to get insights into the company’s mission, vision, and values. Check their portfolio for recent projects that resonate with your interests.</li>
          <li><strong>Social Media:</strong> Follow the company on platforms like LinkedIn, Instagram or X. These channels often showcase recent projects, team activities, and company culture, giving you a sense of their brand personality.</li>
          <li><strong>Reviews:</strong> Check platforms like Glassdoor for employee reviews. While these can be subjective, they may give you a sense of the work environment and management style.</li>
          <li><strong>Industry Publications:</strong> Read articles and interviews featuring the company in architectural magazines or online publications. This can provide context about their reputation and any recent accolades or innovations.</li>
          </ol>
          <p>By gathering this information, you can craft a cover letter that not only highlights your skills but also demonstrates your genuine interest in the company and its values.</p>
          <p/>
          <p/>
          <h3>How to Address the Hiring Managerr</h3>
          <p>One of the most important aspects of writing a cover letter is addressing the hiring manager appropriately. Here are some tips for addressing the hiring manager in your cover letter:</p>
          <ol>
          <li><strong>Avoid using generic greetings:</strong> Starting your cover letter with a generic greeting like "Dear Sir/Madam", "To Whom It May Concern" or "Hi" can make it seem impersonal and unprofessional. It's best to do some research and try to find out the name of the hiring manager or HR representative.</li>
          <li><strong>Use LinkedIn or the company website to find the hiring manager's name: </strong>If the job listing doesn't include the name of the hiring manager, you can try using LinkedIn or the company's website to find the person's name. If all else fails, you can always call the company and ask for the name of the person responsible for hiring.</li>
          <li><strong>Address the person formally, unless instructed otherwise:</strong> Once you have the name of the hiring manager, it's important to address them formally in your cover letter. In the UK, it's common to use the hiring manager's first name instead of their last name. However, if the job listing specifically instructs you to use a different form of address, be sure to follow those instructions.</li>
          <li><strong>Use "Dear Hiring Manager" as a last resort:</strong> If you are unable to find the name of the hiring manager, you can use "Dear Hiring Manager" as a last resort. While it's not as personal as using the person's name, it's still better than using a generic greeting.</li>
          </ol>
          <p>By taking the time to address the hiring manager appropriately, you can make a great first impression and show that you are a professional and serious candidate for the position.</p>
          <p/>
          <p/>
          <h3>Do I Need to Attach a Cover Letter to Every Application? Why It Can Make or Break Your Application</h3>
          <p>In the architecture field, the need for a cover letter can vary depending on the employer. Not every application will require one, but when it does, a tailored cover letter can make a substantial difference in how you’re perceived.</p>
          <p>Often, companies ask for a cover letter because they want a deeper insight into your qualifications or a response to specific questions. Ignoring this request, or submitting a generic letter, can be detrimental - hiring managers might assume you didn’t read the job posting closely or lack interest in the role.</p>
          <p>In a competitive market, especially in architecture, where precision and detail are valued, even small oversights like omitting a cover letter can impact your chances. When a job posting explicitly asks for one, investing a few minutes to personalise your letter to the role and company can be the deciding factor in whether or not you progress to the next stage.</p>
          <p/>
          <p/>
          <h3>How Long Should a Cover Letter Be?</h3>
          <p>While you want to ensure your cover letter contains all the necessary information, you also don't want it to be too long. A good rule of thumb is to keep your cover letter to no longer than half an A4 page. It should be easy to scan and read quickly, as hiring managers likely have to review dozens, if not hundreds, of applications.</p>
          {/* <p>Remember that the purpose of a cover letter is to provide a brief introduction to who you are, what qualifications you have, and why you're the best candidate for the job. You don't need to go into exhaustive detail about every aspect of your career history. Instead, focus on the most important details, such as your relevant skills and experiences, and why you're interested in the position.</p>
          <p>By keeping your cover letter concise and to the point, you'll increase the chances that the hiring manager or recruiter will take the time to read it and consider you for the position.</p> */}
          <p/>
          <p/>
          <h3>Why Generic Cover Letters Don’t Work</h3>
          <p>A common mistake that job seekers make is sending a generic cover letter alongside their resume. While it may seem like a time-saver to use a template and simply swap out the company name, this approach can harm your chances of landing the job. Hiring managers and recruiters can instantly tell when a cover letter hasn’t been tailored to the specific job and company.</p>
          {/* <p>Hiring managers and recruiters can instantly tell when a cover letter has not been tailored to the specific job and company.&nbsp;</p> */}
          <p>A generic cover letter can give the impression that you are not genuinely interested in the position or the company, and it fails to showcase how your skills and experience align with the job requirements.</p>
          <p/>
          <p/>
          <h3>Common Mistakes to Avoid in a Cover Letter</h3>
          <p>When writing your cover letter, steer clear of these common pitfalls:</p>
          <div>Typos and grammatical errors: Always proofread your letter to ensure it’s polished</div>
          <div>Being overly formal or casual: Find the right balance for the industry</div>
          <div>Failing to connect your experiences to the job description: Make sure to highlight relevant experiences.</div>
          <p/>
          <p/>
          <h3>Basic Structure of Your Cover Letter (Plus Examples)</h3>
          <div>You should structure your Cover Letter in 4 parts.</div>
          <ol>
          <li>Why are you contacting them?</li>
          <li>Why would you like to work for them?</li>
          <li>Highlight your skills?</li>
          <li>Summarise&nbsp;</li>
          </ol>
          <p>The<strong> first paragraph </strong>in your Cover Letter should include why you&rsquo;re contacting them, the position you are applying for and where you saw the role advertised.&nbsp;</p>
          <p><strong>Second paragraph</strong>, explain to them why you would like to work for them (do you like the projects they work on, have they recently won any awards, have you been following the practice for a while etc.) and what skills you offer.&nbsp;</p>
          <p><strong>Third paragraph</strong>, highlight any of your key Skills and show them what you could bring to the table i.e. Revit, UK Building Regulations, Job running, Strong communicator, Site visits, Attention to detail, and team player.&nbsp;</p>
          <p><strong>Fourth paragraph</strong>, summarise why you&rsquo;re interested and why you&rsquo;re the right fit for the role.</p>
          <p/>
          <p/>
          <h3>Cover Letter Example 1</h3>
          <p><em>Dear [name of the Hiring Manager],</em></p>
          <p><em>I am excited to apply for the Architect position at [Company]. Having followed your company’s journey for some time, I have been consistently impressed by your commitment to innovative and sustainable architectural designs. With 3 years of experience in large-scale residential and commercial projects, I am eager to contribute my skills and passion to your team.</em></p>
          <p><em>I have honed my technical abilities in Revit, AutoCAD, and Adobe Creative Suite, and I thrive in collaborative environments where I can work closely with clients and colleagues. My focus on delivering projects on time and within budget has been a key aspect of my career.</em></p>
          <p><em>What sets me apart is my genuine enthusiasm for design and my desire to continue learning and growing as an architect. I believe that [Company] offers the perfect environment for me to tackle more complex projects and further my professional development.</em></p>
          <p><em>I would love the opportunity to discuss how I can contribute to [Company's] exciting future. Thank you for considering my application.</em></p>
          <p><em>Thank you for considering my application.</em></p>
          <p><em>Kind regards,</em></p>
          <div><em>[Your Full Name]&nbsp;</em></div>
          <div><em>[Phone Number]&nbsp;</em></div>
          <div><em>[Email Address]</em></div>
          <p/>
          <p/>
          <h3>Cover Letter Example 2</h3>
          <p><em>Hello [name of the Hiring Manager],</em></p>
          <p><em>I am excited to apply for the Architect position at [Company]. As a dedicated architect with a passion for innovative and sustainable design, I have been following your impressive journey for some time. With 3 years of experience in large-scale residential projects, I believe I would be a valuable addition to your team.</em></p>
          <p><em>I admire [Company]'s commitment to creating beautiful and environmentally responsible spaces. My experience aligns well with your mission, and I am eager to contribute.</em></p>
          <p><em>In my current role at Company X, I have successfully managed high-rise residential schemes, overseeing projects from RIBA Stages 2-6. This experience has strengthened my client-facing and technical skills, which I believe would benefit your team.</em></p>
          <p><em>I look forward to discussing how my experience and passion for design make me a strong candidate for this role.</em></p>
          <p><em>Thank you for your time and consideration.</em></p>
          <p><em>Kind regards,</em></p>
          <div><em>[Your Full Name]&nbsp;</em></div>
          <div><em>[Phone Number]&nbsp;</em></div>
          <div><em>[Email Address]</em></div>
          <p/>
          <p> Before submitting your application, find out the name of the hiring manager/director. This will not only leave a good first impression behind but will also show that you invested time in carefully preparing your application. If you&rsquo;re not certain, address it to &lsquo;Dear Hiring Manager<span>&rsquo;</span>.</p>
          <p/>
          <p/>
          {/* <h3>The Risks of Not Attaching a Cover Letter</h3>
          <p>When a job posting requests a cover letter, it's important to follow through and provide one. Not doing so can instantly put you out of the running for the position. Hiring managers and recruiters may interpret a missing cover letter as a sign that you didn't read the job posting thoroughly or that you're not genuinely interested in the role.&nbsp;</p>
          <p>In a competitive job market, any small detail can make a big difference, and a missing cover letter could be the factor that disqualifies you from consideration.</p>
          <p>Larger companies, in particular, can receive hundreds of applications for a single position.&nbsp;</p>
          <p>Without a cover letter, your application may get immediately overlooked.&nbsp;</p>
          <p>In such a scenario, recruiters and hiring managers often use cover letters to quickly weed out unqualified candidates. By failing to submit a cover letter, you could be missing out on an opportunity to showcase your skills, experience, and enthusiasm for the job.</p>
          <p/>
          <p/> */}
          <h3>Should I Tailor my Cover Letter?</h3>
          <p>Yes, I highly recommend spending that extra time amending your cover letter. Hiring managers can tell if a cover letter has been tailored to a specific job or not. Don’t forget, first impressions count, and you only have their attention for a short time. Make every application count - more time spent tailoring your applications will reduce your job search time massively.</p>
          <p></p>
          <p/>
          <h3>Should I Mention Salary Expectations in My Cover Letter?</h3>
          <p>Whether to mention salary expectations in your cover letter largely depends on the job listing and industry norms. Here are some guidelines to consider:</p>
          <ul>
          <li><strong>Check the Job Listing:</strong> If the job posting explicitly asks for salary expectations, it’s best to include them. Be specific and realistic based on your research.</li>
          <li><strong>Industry Norms: </strong>In some industries, discussing salary in the cover letter is standard, while in others, it’s best to wait until later in the interview process. In architecture, it can vary, so gauge based on the company culture and norms.</li>
          <li><strong>Express Flexibility:</strong> If you choose to include your salary expectations, you can frame it by saying you are open to discussion based on the role's responsibilities and the overall compensation package.</li>
          </ul>
          <p>In general, if salary expectations aren’t requested, it’s advisable to wait until the interview stage to discuss this topic, allowing you to better understand the role and its value.</p>
          <p/>
          <p/>
 
          <h3>In Conclusion</h3>
          <p>In conclusion, a well-written cover letter can make all the difference in your job search. It is important to remember that a cover letter should never be a generic, one-size-fits-all document. Instead, it should be tailored specifically to the job and company you are applying to.&nbsp;</p>
          <p>Researching the company, understanding their needs, and demonstrating your qualifications are all key components of a successful cover letter.&nbsp;</p>
          <p>Additionally, it is important to keep your cover letter concise and easily scannable.</p>
          <p>Remember, hiring managers and recruiters receive many applications, and they don't have the time to read through lengthy cover letters that don't get to the point.&nbsp;</p>
          <p>By following these tips and guidelines, you can craft a cover letter that stands out from the crowd and helps you land the job you want.</p>
            </ArticleFrame>
    }
}


// Now, go to the App.js file
// add this line at the top, rename {YourArticle} to whatever you called it. You also need to copy and rename this file to "{YourArticle}.js"
// import YourArticle from './articles/YourArticle'
// Add another line in the Routes section, renaming "YourArticle" again:
// <Route path="/articles/YourArticle" element={<YourArticle/>} />


// now in TERMINAL below, run the following command:
// npm run-script dev
// head to the article page and review the html to make sure its properly formatted
//add <p/> between rows where you want a larger space
//add any images that were missed out (will need to upload these to article_images folder too) eg. <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="Archjobs Keywords when applying"></img>
//add rel="nofollow noopener" to any links so it doesnt impact our SEO eg.  <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="nofollow noopener">Cambridge Dictionary</a>


// finally, publish the changes to github
// When its live, go to search console and inspect the new url: https://search.google.com/search-console?resource_id=sc-domain%3Aarchjobs.co.uk
// Might need a couple of attempts for it to get cached and display properly
// Make sure the rich text snippet is working properly
// If it is, request indexing from google to speed up the process