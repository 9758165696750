import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class Resign extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="Resign">
          <p>Handing in your notice is surprisingly difficult. Whether you recently joined a practice and it&rsquo;s not working out, or you&rsquo;ve been with them for many years - it always seems awkward. You might be wondering what you can do to make the process smoother and ensure you don&rsquo;t burn any bridges. Questions like - how do I hand in my notice? Should I hand in my notice before signing my new contract? Should I do it by email or in person? What are some of the dos and don'ts? I&rsquo;ll answer all of these and more below.&nbsp;</p>
          <p/>
          <h3>Understanding the Process: What It Means to Hand in Your Notice</h3>
          <p>If you hand in your notice or give in your notice, you tell your current employer that you intend to leave your job, often within a set notice period.</p>
          <p>First, you should verbally give notice (tell your team leader first) and either after or during the meeting you hand in your notice in writing.</p>
          <p/>
          <p/>
          <h3>Top Reasons Architects Choose to Resign from Their Positions</h3>
          <p>There are different reasons why you might want to hand in your notice. Maybe you found an amazing opportunity that ticks all boxes, maybe your circumstances have changed and you need to relocate, or perhaps you&rsquo;ve reached the glass ceiling where you are and you&rsquo;re looking for a practice which offers you a more structured career development.&nbsp;</p>
          <p>However, if you genuinely love your practice, you get on with your team, your boss is great but maybe you haven&rsquo;t had a pay increase in a little while. I would highly recommend having a conversation with your team leader or boss first. You might save yourself a lot of time and heartache. Don&rsquo;t forget, nobody can read your mind and sometimes you just need to have a conversation.&nbsp;</p>
          <p><strong>Read</strong> &ldquo;<a href="https://www.archjobs.co.uk/articles/how-to-negotiate-a-pay-increase" target="_blank" rel="noopener">How to negotiate a pay increase</a>&rdquo;.&nbsp;</p>
          <p/>
          <p/>
          <h3>Pre-Resignation Checklist: What to Do Before You Hand in Your Notice</h3>
          <p>Here are a few things you should consider before you hand in your notice</p>
          <ul>
          <li>Have a new job lined up before handing in your notice (even better if you&rsquo;ve already signed and returned the new offer letter)</li>
          <li>Know your notice period (check your contract)</li>
          <li>Prepare your resignation letter (see below)</li>
          <li>Prepare what you want to say during your meeting with your team leader</li>
          <li>Set up a face-to-face meeting with your team leader to verbally hand in your notice</li>
          </ul>
          <p><strong>Tip:</strong> Never hand in your notice until you sign the new contract. A lot can happen between you receiving a new offer and signing it.&nbsp;</p>
          <p/>
          <p/>
          <h3>What to Consider Without a New Job Lined Up</h3>
          <p>If you&rsquo;re thinking about leaving your current job without having a new one lined up - make sure to go through the steps below</p>
          <ul>
          <li>Don&rsquo;t hand in your notice just because you had a bad day</li>
          <li>Write a list with all the pros and cons</li>
          <li>Check your finances, do you have enough money to pay your bills for at least 3 months?</li>
          <li>Update your <a href="https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-cv" target="_blank" rel="noopener">architectural CV</a>, <a href="https://www.archjobs.co.uk/articles/how-to-prepare-an-architecture-portfolio" target="_blank" rel="noopener">Portfolios</a> and <a href="https://www.archjobs.co.uk/articles/how-to-optimise-an-architectural-linkedin-profile" target="_blank" rel="noopener">LinkedIn profile</a></li>
          <li>Do some market research, contact recruiters, talk to other colleagues, and friends, and look at job boards i.e. <a href="https://www.archjobs.co.uk/" target="_blank" rel="noopener">ArchJobs</a> etc.</li>
          <li>Know your notice period (check your contract)</li>
          <li>What would happen if your job search takes longer than expected? What are the implications?</li>
          </ul>
          <p>If you can, have at least 1 or 2 interviews on the go before you hand in your notice. It is easier to find a new job while you&rsquo;re still employed. However, if your mental health is suffering then you need to put yourself first.&nbsp;</p>
          <p/>
          <p/>
          <h3>Writing Your Resignation Letter (2 Examples)</h3>
          <p>Below are 2 examples of how to write and structure your resignation letter. Keep it concise and to the point. Remember, keep it professional and don&rsquo;t use this opportunity to tell them you dislike working for them (even if that&rsquo;s the truth). This will only reflect badly on you and you don&rsquo;t want to leave on a bad note.&nbsp;</p>
          <p/>
          <p/>
          <h3>Resignation Letter Example 1&nbsp;</h3>
          <p/>
          <div><em>(Insert Current date)</em></div>
          <div><em>(Insert Manager&rsquo;s name)</em></div>
          <div><em>(Insert Company Name)</em></div>
          <div><em>(Insert Company Address)</em></div>
          <p/>
          <div><em>Dear (Insert Manager&rsquo;s name),</em></div>
          <p><em>Please accept this letter as formal notification of my resignation from (the position) at (company name). My last day with the company will be (date).</em></p>
          <p><em>I would like to take this chance to thank you for the opportunity to have worked in the position for the past (time in employment). I have learned a great deal during my time here and have enjoyed collaborating with my colleagues. I will take a lot of what I have learned with me in my career and will look back at my time here as a valuable period of my professional life.&nbsp;</em></p>
          <p><em>During the next (notice period in weeks) I will do what I can to make the transition as smooth as possible and will support in whatever way I can to hand over my duties to colleagues or my replacement.&nbsp;</em></p>
          <p><em>Please let me know if there is anything further I can do to assist in this process.</em></p>
          <p><em>Kind regards,</em></p>
          <div><em>(Your signature)</em></div>
          <div><em>(Your printed name)</em></div>
          <p/>
          <p/>
          <h3>Resignation Letter Example 2&nbsp;</h3>
          <p/>
          <div><em>(Insert Current date)</em></div>
          <div><em>(Insert Manager&rsquo;s name)</em></div>
          <div><em>(Insert Company Name)</em></div>
          <div><em>(Insert Company Address)</em></div>
          <p/>
          <div><em>Dear (Insert Manager&rsquo;s name),</em></div>
          <p><em>Please accept this letter as notice of my resignation from the position of (the position) at (company name).</em></p>
          <p><em>As per the terms of my employment contract, I will continue to work for the company for the next (Insert notice period length), completing my employment on (Insert last day you intend to work).</em></p>
          <p><em>I have enjoyed being a part of the team and am thankful for the opportunities you have given me during my time here. If there are any areas, in particular, you would like me to focus on during my notice period, please let me know.</em></p>
          <p><em>I hope that I can rely on you for a positive reference in future.</em></p>
          <p><em>Kind regards,</em></p>
          <div><em>(Your signature)</em></div>
          <div><em>(Your printed name)</em></div>
          <p/>
          <p/>
          <h2>Common FAQs About Resigning from Your Job in Architecture</h2>
          <p/>
          <h3>Should I Hand in My Notice Before Signing My New Contract?</h3>
          <p>You should not hand in your notice until you have signed your new offer letter/contract. I know a lot of people get very excited and can&rsquo;t wait to resign, but you don&rsquo;t want to rush this step. Once you hand in your notice, it is very difficult to go back and say&hellip; I think I made a mistake.</p>
          <p/>
          <h3>Who Should I Inform First When Resigning from My Job?</h3>
          <p>The first person you should tell is your direct line manager or boss (depending on the size of the practice). Avoid telling your colleagues first, even if you&rsquo;re close to them. You can tell them afterwards.</p>
          <p/>
          <h3>How Should I Tell My Team Leader That I’m Leaving?</h3>
          <p>The best way to tell your team leader/boss that you&rsquo;re leaving is during a face-to-face meeting. Nobody wants to receive an email saying: I&rsquo;m leaving.&nbsp;</p>
          <p>If you&rsquo;re working remotely, set up a Teams/Zoom call.</p>
          <p/>
          <h3>What Is the Best Day to Hand in My Notice at Work?</h3>
          <p>When you tell your team leader that you&rsquo;re leaving, ideally have the conversation on a Friday. That way they have the weekend to digest the news and it might be less awkward throughout the week.</p>
          <p/>
          <h3>What Should I Say to My Team Leader During My Resignation Meeting?</h3>
          <p>In this instance&hellip; less is more. Remember you&rsquo;re using this opportunity to tell your team leader before you submit your written resignation letter. You&rsquo;re not obliged to tell them why you&rsquo;re leaving but most of us feel the need to say at least a couple of reasons and if you do, stick to career-related &ldquo;excuses&rdquo;, for example: &ldquo;A wonderful opportunity has come my way and I would like to take it. I have enjoyed working here these last four years and I will ensure a smooth transition on all my projects.&rdquo;.</p>
          <p>Once you&rsquo;ve broken the news, tell your team leader that you will email your written resignation letter to X and confirm if anyone else needs to be copied into the email (HR, your boss etc.).</p>
          <p><strong>Tip:</strong> Your manager might ask you a lot of questions about your new offer i.e. which studio you&rsquo;re joining, job title, salary, benefits, responsibilities etc. Depending on how comfortable you feel sharing these details, I would suggest not telling them too much. They might use that info for a potential counter-offer.</p>
          <p/>
          <p/>
          <h3>What Should I Do If I Receive a Counter-Offer After Resigning?</h3>
          <p>You&rsquo;ll likely receive a counter-offer either during your initial meeting or a couple of days afterwards (it&rsquo;s an attempt to keep you from leaving). This conversation usually goes 2 ways: you can either kindly decline their offer or you might ask for a couple of days to think about it.&nbsp;</p>
          <p>No matter which option you chose, you might want to read <a href="https://www.archjobs.co.uk/articles/should-i-accept-a-counter-offer" target="_blank" rel="noopener">Should I accept a Counter-Offer?</a> which goes into a lot of detail on the pros and cons of accepting a counter offer, and explains why practices make counter-offers.</p>
          <p/>
          <h3>How Do I Submit My Written Resignation Letter?</h3>
          <p>After you&rsquo;ve told your manager that you&rsquo;re leaving, submit your written resignation letter either via email or provide a printed copy.&nbsp;</p>
          <p>Congratulations, it&rsquo;s official, you are leaving! At this stage, you could also update the new practice and let them know it&rsquo;s all done. I&rsquo;m sure they would appreciate a quick update.</p>
          <p/>
          <p/>
          <h3>What Should I Avoid Saying When Resigning from My Job?</h3>
          <p>No matter how much you dislike your current job, this is not the time to tell them their company is flawed or you can&rsquo;t stand your colleagues. You have to keep it professional and don&rsquo;t burn any bridges.&nbsp;</p>
          <p>However, if you feel like you need to clear the air on a few issues, stick to the facts and keep your emotions out of it. This could quickly escalate, so be careful.</p>
          <p/>
          <p/>
          <h3>Can I Leave My Job Early by Ignoring My Notice Period?</h3>
          <p>If your employer wants you to work through your notice period, then you have to do it: you&rsquo;ll be in breach of your contract otherwise.</p>
          <p>However, there are a couple of exemptions:</p>
          <ol>
          <li>You have unused holidays - you might be able to negotiate an earlier end date.&nbsp;</li>
          <li>You join one of their competitors and your practice puts you on gardening leave</li>
          </ol>
          <p/>
          <p/>
          <h3>What Is Gardening Leave and How Does It Work?</h3>
          <p>Garden or gardening leave means that during your notice period you&rsquo;ll receive the same pay and contractual benefits, but with one crucial exception: you are not required to work. You are, however, expected to remain &ldquo;available&rdquo;, so you can&rsquo;t just go on holiday or start working your new job.</p>
          <p/>
          <p/>
          <h3>How Can I Leave a Positive Impression When Resigning?</h3>
          <p>I&rsquo;m sure you&rsquo;re counting down the days before starting your new position, but this is not the time to put your feet up and do nothing.&nbsp;</p>
          <p>While working through your notice, keep working as normal. You might be asked to write handover documents, train others in the team on a project you&rsquo;re working on, tie up any loose ends with clients etc.</p>
          <p>This is a good chance to leave a positive impression behind. Don&rsquo;t forget, you might need to ask them for future references.&nbsp;</p>
          <p/>
          <p/>
          <h3>When Should I Ask for a Reference Letter?</h3>
          <p>It's best to request a reference letter during your exit meeting or right after you've handed in your notice. This way, your manager can provide feedback based on your recent work while it’s still fresh in their mind. Be sure to ask politely and provide them with any details they might need, such as the role you’re applying for or specific skills you’d like highlighted. This proactive approach not only shows professionalism but also ensures you leave on good terms.</p>
          <p/>
          <p/>
          <h3>Final Checklist: Actions to Take After Handing in Your Notice</h3>
          <ul>
          <li>Set up another meeting with your team leader/boss and discuss the next steps</li>
          <li>Set clear targets - what needs to be done before you leave</li>
          <li>Prepare handover documents</li>
          <li>Tie up any loose ends and make the transition as smooth as possible</li>
          <li>Train others on your projects (if required)</li>
          <li>Don&rsquo;t slack, and don&rsquo;t be late</li>
          </ul>
          <p/>
          <p/> 
          <h2>Do’s and Don’ts of Resigning: A Quick Reference Guide</h2>
          <p/>
          <h3>Things you should do</h3>
          <div>✔ set up a face-to-face or Teams/Zoom meeting and tell your manager first&nbsp;</div>
          <div>✔ hand in your written resignation letter afterwards</div>
          <div>✔ keep your resignation letter upbeat and forthcoming</div>
          <div>✔ offer to do a solid handover&nbsp;</div>
          <div>✔ set clear targets on what needs to be done before you leave</div>
          <div>✔ be professional and leave a positive impression behind</div>
          <div>✔ ask for a reference</div>
          <p/>
          <p/>
          <h3>Things you shouldn&rsquo;t do</h3>
          <div>✖ don&rsquo;t just email your resignation</div>
          <div>✖ don&rsquo;t tell them how much you dislike the job (even if that&rsquo;s the truth)</div>
          <div>✖ don&rsquo;t ignore your notice period</div>
          <div>✖ don&rsquo;t be unprofessional</div>
          <div>✖ don&rsquo;t slack or be late&nbsp;</div>
          <div>✖ don&rsquo;t burn any bridges</div>
          <p/>
          <p/>
          <h2>Conclusion</h2>
          <p>How you behave during and after you handed in your notice can make or break your reputation within a practice. It doesn&rsquo;t matter if you&rsquo;ve been with them for 6 months or 6 years, there are some simple steps everyone can follow, out of courtesy and respect for the team and whoever will take over your role or projects.&nbsp;</p>
          <ol>
          <li>Tell your team leader first (face to face)</li>
          <li>Afterwards, hand in your written resignation letter</li>
          <li>Work through your notice period</li>
          <li>Set clear targets on what needs to be achieved before you leave</li>
          <li>Keep open communication with the team and help with the handover</li>
          </ol>
          <p>Hopefully, you have a new and exciting opportunity lined up. However, if you don't, it's essential to assess your finances and weigh the pros and cons carefully. If you have any questions or uncertainties, <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">consider booking a call with me</a>. I can provide an external perspective on your situation.</p>
            </ArticleFrame>
    }
}