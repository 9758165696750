import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class DubaiQuickGuideCV extends React.Component {
    constructor(props) {
      
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="DubaiQuickGuideCV" dubai={this.props.dubai} >
        <p>This guide outlines what to include in your CV when applying for architectural roles in Dubai. With over 5 years of industry experience, I&rsquo;ve reviewed countless CVs and noticed common mistakes. Often, candidates aren&rsquo;t specific enough, miss important information, or provide too much detail, making the CV overwhelming for hiring managers.</p>
        <p>The goal of your CV is to give the reader an understanding of your skills and experiences, supported by specific examples from your work history and sample portfolio.</p>
        <p>Let&rsquo;s break it down section by section.</p>
        <p/>
        <h3>Personal Information</h3>
        <p>Ensure you include all of these details and double-check your contact information. Including your nationality is important for roles in Dubai.</p>
        <ul>
        <li>Full Name</li>
        <li>Job Title</li>
        <li>Phone Number + Country Dial</li>
        <li>Email</li>
        <li>Location (City &amp; Country)</li>
        <li>Nationality</li>
        <li>LinkedIn (if available)</li>
        <li>Photo (I would suggest using the same photo on your CV and LinkedIn)</li>
        </ul>
        <p/>
        <h3>About Me</h3>
        <p>This short section gives the reader a quick overview of who you are. If you&rsquo;re planning to relocate, mention that here. Keep it between 150-200 words maximum. Here&rsquo;s what to include:</p>
        <ul>
        <li>Current Job Title</li>
        <li>Years of Experience</li>
        <li>Sector Experience</li>
        <li>Software Abilities (with a focus on Revit and Rhino)</li>
        <li>In-Demand Skills and Experiences in Dubai (i.e. large-scale residential towers, masterplans, hospitality, retail, and mixed-use projects)</li>
        </ul>
        <p>FYI - The Title of Architect is not protected in the UAE.</p>
        <p/>
        <h3>Work Experience</h3>
        <p>This is where many professionals struggle - they either provide far too much information and turn their CV into a 5 page document or they simply add too little.</p>
        <p></p>
        <p>Here&rsquo;s the issue: if you provide too much info, you overwhelm the reader and fail to highlight your key skills and responsibilities. And if you&rsquo;re too vague, it&rsquo;s a missed opportunity to showcase your experience and why you&rsquo;re the right person for the job. It&rsquo;s important to find the right balance between too much detail and too little.</p>
        <p>Here&rsquo;s what you should do:</p>
        <ul>
        <li>Use bullet points to highlight your skills and responsibilities</li>
        <li>Start with your most recent work experience</li>
        <li><strong>Subheader</strong>: Job Title, Name of the Practice, Location &amp; Country and Dates</li>
        <ul>
        <li>Who did you work with? What was the team structure?</li>
        <li>What types of projects did you work on? (Highlight Dubai-relevant experience, such as large-scale projects)</li>
        <li>What RIBA stages or equivalent phases were involved?</li>
        <li>What software did you use? (Emphasise Revit and Rhino)</li>
        <li>What were your responsibilities?</li>
        <li>Can you include any statistics or figures throughout the text?</li>
        </ul>
        </ul>
        <p>Repeat this format for each role, with emphasis on the last 3-5 years of experience.</p>
        <p/>
        <h3>Education</h3>
        <p>This section doesn&rsquo;t need to be extensive. The reader primarily wants to know:</p>
        <ul>
        <li>Name of the University (if applicable), Location &amp; Country</li>
        <li>Degree</li>
        <li>Grade</li>
        <li>Year</li>
        </ul>
        <p/>
        <h3><strong>Skills Section</strong></h3>
        <p>This is a critical part of your CV, as it allows the reader to quickly scan through your skills and understand your experience. Separate this section into <strong>Soft Skills</strong> and <strong>Software Skills</strong>:</p>
        <ul>
        <li><strong>Soft Skills</strong>: Focus on architecture-specific abilities (i.e. technical detailing, site visits, communication, design drawings, hand sketching, liaising with clients and contractors, feasibility studies etc.)</li>
        <li><strong>Software Skills</strong>: Revit and Rhino are in high demand in Dubai, but also include other relevant software (i.e. Adobe Creative Suite, Grasshopper etc.)</li>
        </ul>
        <p/>
        <h3><strong>Awards</strong></h3>
        <p>If you have received any awards that add credibility to your CV, list them concisely:</p>
        <ul>
        <li>Name of the Award</li>
        <li>Year</li>
        <li>Reason for Receiving the Award (keep it brief)</li>
        </ul>
        <p/>
        <h3><strong>References</strong></h3>
        <p>Avoid providing full reference details on your CV. This allows you to control when and who will contact your referees. You can also prepare them in advance when a company intends to reach out.</p>
        <ul>
        <li><strong>References</strong>: Available upon request.</li>
        </ul>
        <p/>
        <h3><strong>Other</strong></h3>
        <p>If you want to include additional details, consider a section at the bottom of your CV for:</p>
        <ul>
        <li>Affiliations or Registrations (i.e. ARB, RIBA, CMLI)</li>
        <li>Languages</li>
        <li>Driver&rsquo;s License</li>
        <li>Specific certificates/training courses</li>
        </ul>
        <p/>
        <h3><strong>Hobbies</strong></h3>
        <p>Including hobbies is optional but can be a nice icebreaker during conversations with hiring managers. Avoid generic terms like &ldquo;photography, gym, running, traveling.&rdquo; Instead, be specific, such as: CrossFit, marathon runner, or backpacker who has traveled through Europe, South America, South Asia. You get the hint.</p>
        <p>If you&rsquo;d like to learn more about how to structure your CV, what to avoid, and how long it should be, please check out &ldquo;<a href="https://www.archjobs.co.uk/articles/how-to-write-an-architecture-cv-for-the-dubai-market">How to Write and Structure an Architecture CV fit for the Dubai market?</a>&rdquo;.</p>
        </ArticleFrame>
            }
}