
import React from 'react'
import classNames from 'classnames';
import '../css/navbar.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
      }

      componentDidMount = () => {
        window.scrollTo(0, 0)
      }

    render() {


      return (
        <div className="TeamPage">
            <div className="TeamContainer">
                <div className="TeamTitle">Meet the Team</div>
                <div className="WhyWorkWithUsSection">
                <p>Many months and hours have gone into designing and coding ArchJobs from scratch. Despite not being a large team, our skill sets and knowledge complement each other's, making us an incredible team. Plus, we have a dog who never misses his walks, making him the perfect well-being officer.</p>
                <div className="TeamInfoContainer">
                    <div className="TeamPhotos">
                        <img className="TeamPhoto" src="https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/rsz_sa.jpg" alt="Photo of Sam and Aylin"></img>
                        <img className="TeamPhoto Otto" src="https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/rsz_ottoteamphoto.jpg" alt="Photo of Otto the dog looking happy"></img>
                    </div>
                    <div className="TeamInfo">
                        <div className="TeamInfoSection">
                            <div className="TeamName">
                            <a target="_blank" href="https://www.linkedin.com/in/aylin-round/">Aylin Round</a>
                            </div>
                            <div className="TeamJob">
                               Owner & Founder
                            </div>
                            <div className="TeamDetails">
                                <p>Hi, I’m Aylin.
                                With over 5 years of experience in the architectural industry across both the UK and Dubai, I combine my knowledge and expertise to help architectural professionals advance in their careers. What started as a job board, ArchJobs now offers tailored articles, career coaching sessions, salary guides, and more.</p>
                                <p>I’ve also had the opportunity to write for Building Design, Architects' Journal, CIAT | aspirATion magazine, and Architecture Social, as well as give presentations for RIBA and visit universities to help students with their CVs and portfolios.</p>
                            </div>
                        </div>
                        <div className="TeamInfoSection">
                            <div className="TeamName">
                                Sam
                            </div>
                            <div className="TeamJob">
                                Consulting Full-Stack Developer 
                            </div>
                            <div className="TeamDetails">
                                <p>
                                    I’m a Data Scientist/Full-Stack Developer that coded ArchJobs from scratch - so if you find a bug it’s probably my fault! I’ll keep adding new features to try to improve ArchJobs and make it the best, most user friendly job board out there. 
                                </p>
                                <p>
                                    When I’m not working on ArchJobs, I’m either travelling, dabbling in other projects or taking care of our part-time employee, Otto.
                                </p>
                            </div>
                        </div>
                        <div className="TeamInfoSection">
                            <div className="TeamName">
                                Otto
                            </div>
                            <div className="TeamJob">
                                Wellbeing Officer - Part-Time
                            </div>
                            <div className="TeamDetails">
                                <p>I moved to Dubai with Aylin and Sam, and somehow got promoted! You’ll mostly find me napping on the sofa - tough job, but someone’s got to do it.                  
                                </p>
                                <p>
                                My current goal is to work towards full-time employment so I can buy more biscuits, though my focus tends to be as short-lived as my energy levels. My software skills are non-existent, but my people skills are paw-some. Oh, and I’m also the proud caretaker of our foster kitten, Piper. She’s a handful, but I’m doing my best!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      )
    }
  }

  export default Team