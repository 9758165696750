import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


//first go to ArticleData.js and add all the relavant data
//next, rename "YourArticle" below to what you named the article data section
const thisArticleData = "Part2"


//rename YourArticle below to your article name
export default class Part2 extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
            <p>If you are looking for your next Part 2 Architectural Assistant position and want to know how to increase your chances of landing your desired role, then this article is for you. Not only will I guide you through the basics of creating your CV, sample portfolio, and writing your cover letter, but I will also share additional insights from industry professionals. Additionally, I want you to get an idea of the salary ranges and what you need to keep in mind when joining a RIBA Chartered Practice. By the end of this article, you will also understand the significance of building and utilising your network not only to secure your next Part 2 role but also in the years ahead.</p>
            <p/>
            <p/>
            <h2>Structure of the article:</h2>
            <ol>
            <li>Ways To Increase Your Visibility&nbsp;by Networking</li>
            <li>CV (Example) + FAQs</li>
            <li>Sample Portfolio (Cover Page Examples) + FAQs</li>
            <li>Cover Letter (2 Examples) + FAQs</li>
            <li>Salaries, Living Wage and RIBA Chartered Practices</li>
            </ol>
            <p/>
            <p/>
            <h2>1. Section: Ways To Increase Your Visibility by Networking</h2>
            <p>As a student, how can you increase your chances of securing your either first or next architectural role? Let&rsquo;s be honest, finding a Part 2 Architectural Assistant role straight after uni can be challenging and trust me, you are not alone. Here&rsquo;s what I recommend you should do. Grow your professional network while you&rsquo;re still in uni. The best way to grow your network is by using LinkedIn. LinkedIn will continue to be a valuable platform for many years to come, and it's worth investing time in growing your network.</p>
            <p>Your network will become a powerful tool when the time comes for you to search for a role.&nbsp;</p>
            <p>But let&rsquo;s hear some more advice from my network:&nbsp;</p>
            <p/>
            <p/>
            <h3>Jason Boyle ARB FRSA FRIBA</h3>
            <p><img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Jason Boyle.PNG" alt="Screenshot of Jason Boyle's comment: The biggest mistakes students of architect make is to start networking just as they finish there degree. You should dedicate 10 minutes a day to engage with people for all 3 years. LinkedIn is a powerful tool but few actually get it. "></img></p>
            <p/>
            <h3>Andy Shaw</h3>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Andy Shaw.PNG" alt="Screenshot of Andy Shaw's comment: I suggest engage with professional institutes while at uni - go to events and talk to people. It can be RIBA, RIAS, ACAN etc. just engage and don’t be passive. Also - make friends in the year above - they will know what’s going on in the market when you graduate."></img></p>
            <p/>
            <h3>Ian Lowe</h3>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Ian Lowe.PNG" alt="Screenshot of Ian Lowe's comment: When industry professionals give up their time to come in for mock interviews, or to offer crits / tutorials.......turn up, engage, and do your research on them. This is an opportunity to forge links, and consider that perhaps, in the back of their minds, they quietly assessing you."></img></p>
            <p/>
            <h3>Paul Iddon</h3>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Paul Iddon.PNG" alt="Screenshot of Paul Iddon's comment: Consistently amazed at the lack of interest by students at our events....great way to network and meet practices - but hardly any students show. Just had approx 500 people, 50 practices at our awards. A handful of students...I don't get it."></img></p>
            <p/>
            {/* <h3>Calin-Bogdan Maier</h3>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Calin Maier.PNG" alt="Screenshot of Calin-Bogdan Maier's comment: Since we’re talking about engaging on LinkedIn, I’d add that students should also be mindful of their profile and make it recruiter-friendly. Look at the students with the most amount of followers/connections and get inspired by their format. Most relevant parts: 1. Profile picture 2. Headline 3. About Me section 4. Experience section"></img></p> */}
            <p>You can read the full <a href="https://www.linkedin.com/posts/aylin-round_networking-connections-graduates-activity-7082266872624115712-cRME?utm_source=share&amp;utm_medium=member_desktop" target="_blank" rel="nofollow noopener">LinkedIn post here</a>.&nbsp;</p>
            <p>If you want to learn how to set up your LinkedIn profile - read my article &ldquo;<a href="https://www.archjobs.co.uk/articles/how-to-optimise-an-architectural-linkedin-profile" target="_blank" rel="noopener">Transform your LinkedIn Profile in 10 Steps &amp; Increase your professional visibility</a>&rdquo;</p>
            <p/>
            <p/>
            <h3>Where to find Architectural Networking Events in the UK?</h3>
            <p>We always talk about how important it is to grow your professional network, especially for architectural students, but where can you find them? There are so many options these days, some are being held online or in person. You could attend an awards ceremony, go to a talk, visit an exhibition, take part in webinars, join a CPD session, or just a normal networking event where you can spend time talking and meeting like-minded people like yourself. Luckily, there are plenty of events across the UK, and I will share with you where you can find them and why it’s worth growing your professional network.</p>
            <p>You can read the full article <a href="https://www.archjobs.co.uk/articles/where-to-find-architectural-networking-events-uk" target="_blank" rel="noopener">here and understand where to find them and why your professional network will play a vital part in your career</a>.&nbsp;</p>
            <p/>
            <p/>
            <h2>2. Section: CV (Example)</h2>
            <p/>
            <p/>
            <h3>What does CV stand for?</h3>
            <p><em>&ldquo;A CV is a short written description of your education, qualifications, previous jobs, and sometimes also your personal interests, that you send to an employer when you are trying to get a job.&rdquo;</em></p>
            <p>Source: <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="noopener">Cambridge Dictionary</a>&nbsp;&nbsp;</p>
            <p/>
            <p/>
            <h3>General CV Formatting Tips</h3>
            <div>CV length: Ideally 1 page</div>
            <div>File type: PDF&nbsp;</div>
            <div>File size: CV - under 5MB</div>
            <div>Font type: Easy-to-read fonts are Arial, Times New Roman or Calibri</div>
            <div>Font size: 10-11</div>
            <div>Naming your file: First and last name - CV. (i.e. Jane Doe - CV)&nbsp;</div>
            <p/>
            <p/>
            <h3>Basic Structure of your CV (Example Below)</h3>
            <p>The key parts your CV should cover are as follows:</p>
            <ol>
            <li>Full name and name of the position you&rsquo;re applying for (Part 2 Architectural Assistant)</li>
            <li>Personal Details (phone, email and location)&nbsp;</li>
            <li>About Me/Profile section (quick snapshot of who you are, your level of experience, education, motivation and skills)</li>
            <li>Education/Awards (mention your university and grades)</li>
            <li>Soft Skills (i.e. communication and problem-solving skills, ambitious and a willingness to learn, team player, time management, attention to detail, creative etc)</li>
            <li>Software &amp; Tools (i.e. Revit, AutoCAD, Photoshop, InDesign, SketchUp, ArchiCAD, Microsoft Teams etc.)</li>
            <li>Work Experience - if applicable (Part 1, Internship/Summer Placements) - begin with your most recent experience&nbsp;</li>
            <div>7.1. Job Title, Company name, Location, Dates (start - finish)</div> 
            <div>7.2. Summarise your responsibilities and transferable skills (if applicable), and mention any work highlights - I worked on a new build mixed-use scheme - supported the team with producing tender packages, attended site meetings and dealt with the planning applications.&nbsp;</div>
            <li>Hobbies and Interests - Photography, live drawing, sketching, pottery, travelling</li>
            <li>References (Available upon request) - this could be your tutor or someone from a previous worked with</li>
            </ol>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/part_2_cv_example.png" alt="An example of a Part 2 Architectural Assistant CV"></img>
            <p>💡 Tip: If you have no relevant work experience, I would suggest focussing on your university work and soft skills.&nbsp;</p>
            <p/>
            <p/>
            <h3>Are the CV templates on Canva compliant with ATS?</h3>
            <p>After doing more research, it appears that <a href="https://www.canva.com/" target="_blank" rel="nofollow noopener">Canva</a> provides you with a lot of good-looking CV templates; however, they are not optimised for <a href="https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-architectural-cv" target="_blank" rel="noopener">Application Tracking Systems (ATS)</a>. Instead, I would suggest creating your CV using one of the free Google Docs templates or using the website <a href="https://www.tealhq.com/" target="_blank" rel="nofollow noopener">Teal</a>.</p>
            <p>Please note that to create your CV through Google Docs, you will need to create a Google account (but I&rsquo;m sure most of us have one already). Simply click on Google Drive, then click on the big plus 'New' button, and select 'Google Docs' -&gt; 'From a template&rsquo;.</p>
            <p>As of now, Google Docs offers 5 different resume/CV templates. I personally prefer the layout of &ldquo;Resume Serif&rdquo;.</p>
            <p/>
            <p/>
            <h3>Which Job Title can I use?</h3>
            <p>While you are a Part 2, you need to stick to the title of Part 2 Architectural Assistant. Under no circumstances should you include the word "Architect" in your job title. "Architect" is a protected title here in the UK, and you can only use it if you are registered with the ARB. The reason I'm highlighting this is because I've noticed more and more juniors using "Part 2 Architect", and that is incorrect. Hiring managers and recruiters do not view this favourably.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/LinkedIn_post_title.png" alt="Screenshot of Aylin Round's LinkedIn post: In the post she is trying to understand why Part 1 and Part 2 Architectural Assistance use the word Architect in their job title. Is it a lack of understanding or is this turning into a trend?"></img>
            <p>To read all the replies, just <a target="_blank" rel="nofollow noopener" href="https://www.linkedin.com/posts/aylin-round_architecture-architecturalassistant-graduates-activity-7155484377710309376-D3Jc?utm_source=share&amp;utm_medium=member_desktop">click the link here</a>. It will take you straight to the post.</p>
            <p/>
            <p/>
            <h3>Which Keywords should I use in my CV?&nbsp;</h3>
            <p>Whether you&rsquo;re sending your application directly or uploading your details via a company's recruitment portal (Application Tracking System or ATS) using relevant keywords will help your application stand out.&nbsp;</p>
            <p>Below are the most commonly used keywords for any architectural positions:</p>
            <p>Presentation skills, time management skills, site visits, problem-solving skills,&nbsp; technical skills, design skills, concept design, planning applications, coordination, liaising with clients, UK Building Regulations, communication skills, Revit, AutoCAD, Photoshop, SketchUp, InDesign etc.</p>
            <p>Applications that contain relevant keywords are much more likely to be shortlisted for interviews.&nbsp;</p>
            <p/>
            <p/>
            <h3>How does an Applicant Tracking System (ATS) impact my application?</h3>
            <p>Most recruitment agencies and mid to large size companies use ATS systems which lets them store, filter, organise, and track applications but also search their database for suitable candidates by entering relevant keywords (skills, location, job titles etc.).&nbsp;</p>
            <p>It's important to understand that when submitting your application via an ATS, you're sending it to software and not an actual person. Therefore, your CV needs to be ATS-friendly and optimised to ensure that it passes the initial screening and makes it to a human reviewer.</p>
            <p>To learn more on this particular subject, read <a href="https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-cv" target="_blank" rel="noopener">How to Create an ATS-Friendly CV</a>.</p>
            <p/>
            <p/>
            <h3>Do Hiring Managers and Recruiters read every single word?</h3>
            <p>No chance. They will look for job titles, keywords, companies you worked for, length of employment and skill sets.</p>
            <p>That is why a well-structured CV that catches their eye by reflecting keywords from the job description is so important - this is what they are scanning for.</p>
            <p>Once you have their attention, they will look at your CV in more detail.</p>
            <p/>
            <p/>
            <h3>Should you work with a Recruiter if you&rsquo;re a Part 2?</h3>
            <p>It depends... You can work with a recruiter if you're a Part 2 Architectural Assistant. Some recruiters do provide support and actively seek opportunities for Part 2s in this role. However, it is important not to rely solely on a recruiter to find your next position. It is advisable to be proactive, utilise your network, and attend industry events. As you may already know, your CV, sample portfolio, and cover letter will play significant roles in the job search process.&nbsp;</p>
            <p/>
            <p/>
            <h3>I require Sponsorship, can a Recruiter help me?</h3>
            <p>I'll be honest here, you will most likely not find a recruiter who will be able to help you. The reason why UK recruiters are having a hard time placing candidates who require sponsorship is that the costs are too high for the practices. Not only would the practice need to pay the recruiter, but they would also need to cover the candidate's sponsorship. Therefore, you need to be proactive and contact practices/companies that are on the UK Register of Licensed Sponsors. You can find the full list by visiting <a href="https://www.gov.uk/government/publications/register-of-licensed-sponsors-workers" target="_blank" rel="nofollow noopener">the UK Government website</a>.</p>
            <p/>
            <p/>
            <h3>Should I add any Hobbies?</h3>
            <p>Yes, you can add hobbies but this is optional. If you decide to add some hobbies, keep it short and sweet. Maybe you and the hiring manager have something in common and it could break the ice and make you more memorable.</p>
            <p/>
            <p/>
            <h3>Should I use Logos and Diagrams when listing my Software Skills?</h3>
            <p>No, it is best to avoid relying solely on logos and diagrams to showcase your software skills and experience levels. The reason is that not only are they unreadable by Application Tracking Systems (ATS), but also stating your Revit skills as 8/10 may not convey much to someone else.</p>
            <p>Instead, it is recommended to explicitly mention the software you are proficient in, such as Revit, AutoCAD, and Adobe Creative Suite.Hear what Ian Lowe and Sonda Mvula have to say about this subject:&nbsp;</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Ian Lowe - Logos.png" alt="Ian Lowe - Interesting. I prefer to see the logo, backed up with a written note to cover the ATS angle, we are a visual industry after all.
                I'd agree that a bar chart or stars or giving yourself 8 out of 10 dots tells me nothing about your experience of software, though. Simply state how long you've used it and how frequently you use it, then flesh out with what you use it for."></img></p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Ian Lowe - Logo 2.png" alt="Ian Lowe - I am guilty of doing a horizontal bar chart in my early career. I had a sort of epiphany about it, What I am I comparing myself to? What is the baseline? Is my self appointed 8 out of 10 someone else's 6? If I consider myself 8 out of 10 now, what about the person with 20 years experience, where are they on this scale? Then you realise how limiting and arbitrary it is. When I tutor, and when I do mock interviews, we discuss this; I ask where I fit on the students self appointed scale, where do they fit in 5, 10, 20 years time? I understand the graphical rationale behind it, but it's a poor method to communicate experience to a prospective employer"></img></p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Sonda - Logos.png" alt="Sonda Mvula - Aylin Round this is great advice and I think this mainly happens with new graduates. I have had this conversation with a lot of people and always tell them to put themselves in the employer's shoes. For example, pie charts that show Revit 8/10 do not mean anything to the employer as they want to know how long you have used the software for and on what kinds of projects, etc."></img></p>
            <p/>
            <p>If you still wish to use logos, you can include the name of the software underneath or next to each respective logo.</p>
            <p>❌ Below is an example of what not to do&nbsp;❌</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Adobe Creative Suite Logos_new.png" alt="Image of the Adobe Creative Suite Logos - Credit to: Godline Art"></img></p>
            <p>💡Tip: The same goes for listing hobbies. I see a lot of CV&rsquo;s which show logos for cookings, climbing, music, painting.&nbsp;</p>
            <p/>
            <p/>
            <h3>Do I need to add a Picture of myself?</h3>
            <p>No, you do not need to add a picture of yourself, and I would advise against it. There is a law in the UK, Ireland and the United States which protects job seekers from being discriminated against.&nbsp;</p>
            <p>That being said, people might have unconscious biases and you don&rsquo;t want to give anyone a reason to dismiss you before even getting to interview.</p>
            <p><em>&ldquo;It is against the law to treat someone less favourably than someone else because of a personal characteristic such as religion, sex, gender reassignment or age.&rdquo;&nbsp;</em></p>
            <p>Source: <a href="https://www.gov.uk/employer-preventing-discrimination" target="_blank" rel="noopener">Gov.uk</a></p>
            <p/>
            <p/>
            <h2>3. Section: Sample Portfolio&nbsp; (Cover Page Examples) + FAQs</h2>
            <p/>
            <p/>
            <h3>What is a Portfolio?</h3>
            <p>An architectural portfolio is a unique body of work which represents your experience as a Designer. A portfolio should be used to showcase your best work that you can share with potential employers or clients. It provides a true reflection of your architectural skills, your design approach, technical abilities and how you have evolved in your career.</p>
            <p/>
            <p/>
            <h3>General Sample Portfolio Formatting Tips</h3>
            <div>Length: Under 10-12 pages</div>
            <div>File type: PDF</div>
            <div>File size: Under 10MB</div>
            <div>Cover Page: Include your Full Name, Job Title, Phone number and Email </div>
            <div>Font type: Easy-to-read fonts like Arial, Times New Roman, or Calibri</div>
            <div>Font size: 10-11</div>
            <div>Structure: Start with your most recent work</div>
            <div>Naming your file: First and last name - Sample Portfolio. (e.g. Jane Doe - Sample Portfolio)</div>
            <p/>
            <p/>
            <h3>2 Different Types of Portfolios</h3>
            <p>There are 2 different types of Portfolios - the Sample and the Full Portfolio. However, before you start organising your work into a portfolio, ask yourself &ldquo;Who is my audience?&rdquo;, &ldquo;What am I trying to achieve?&rdquo;.</p>
            <p>If you&rsquo;re looking for a new job, it is common to submit your CV, Cover Letter with a Sample Portfolio.&nbsp;</p>
            <p>Your Sample Portfolio goes hand in hand with your CV and shouldn&rsquo;t be longer than 12 pages and no larger than 8MB in size. It must compliment your CV i.e. if you mentioned your design and technical skills, the hiring manager would expect to see evidence of that in your Sample Portfolio.&nbsp;</p>
            <p>Once you have received an interview invite, that&rsquo;s when your Full Portfolio springs into action. You&rsquo;ll be able to show more relevant work examples and prove you&rsquo;re the right fit for the job during the interview. Your Portfolio could include various key projects you worked on, show your involvement across various stages, completed projects (if applicable), your construction detailing skills, your creative problem-solving skills and your design skills.</p>
            <p/>
            <p/>
            <h3>Ways to Improve Your Technical Skills and Enhance Your Portfolio</h3>
            <p>At ArchJobs, we are always looking for ways to support our community. We understand that some Part 2 Architectural Assistants may not have had extensive technical exposure and may feel that their portfolios are lacking. Simultaneously, practices prefer to hire candidates with better technical knowledge who have used various software before. </p>
            <p>That's why we've partnered with <a target="_blank" href="http://www.archademia.com?utm_source=ArchJobs&utm_medium=Article">ArchAdemia</a>, who share our mission of providing architectural professionals with the technical skills they need to advance in their careers.</p>
            <p>For students, ArchAdemia is your bridge from theory to practice. Software tutorials under the guidance of industry professionals will give you the capability to make an impact from day 1. Master the art of drawing, manage projects like a pro, and immerse yourself in podcasts that give you the inside scoop from those who've paved the way. Say goodbye to the daunting transition from academia to industry; ArchAdemia is here to make you practice-ready.</p>
            <p>But ArchAdemia isn't just tutorials and resources. It's a vibrant community where passions meet, discussions flourish, and connections are forged. It's a space where students and professionals alike share, learn, and grow together, transcending traditional boundaries.</p>
            <p>With 35 meticulously curated <a target="_blank" href="http://www.archademia.com/lessons?utm_source=ArchJobs&utm_medium=Article">courses</a>, offering everything from beginner basics to advanced expertise in parametric design and BIM, we will help you to master architecture. Whether you're refining your software skills or diving deep into design theory, we’ve got you covered.</p>
            <p>To get <a target=" blank" href="https://archademia.com/pricing/"><strong>10% off your Annual Membership subscription</strong></a>, simply enter <strong>ARCHJOBS10</strong>. </p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemia_Course Display.jpg" alt="Screenshot from ArchAdemia website - The image is a screenshot of the homepage of a website that offers courses in 3D design, architecture, and animation software. The homepage features a grid of cards, each of which represents a different course. The cards include the course title, a brief description, and an image. Some of the courses featured on the homepage include: SketchUp Complete Guide, The Perfect Floor Plan,Rhino & Grasshopper Essentials and much more."></img> </p> 
            <p/>
            <p/>
            <h3>2 Most Common Ways to Create Portfolios</h3>
            <p>There are a few different ways you can present your Portfolio.</p>
            <p>PDF Portfolios: PDF Portfolios are a quicker and easier way to share your work and you can prepare different versions. Also, most practices specifically ask for a Sample Portfolio in PDF version. Don&rsquo;t forget to name your document - &ldquo;Your Full Name - Sample Portfolio''.&nbsp;</p>
            <p>Portfolio Hosting Sites: Two platforms which keep popping up are Issuu.com and Behance, and they can be a great way to create and share your work. But I would recommend against it as I find the websites rather slow to load and I feel like it ruins the whole experience.</p>
            <p>There are also options to print your Portfolio but that is very expensive and some also build their own website, which can take much longer to create.</p>
            <p>I would highly recommend to stick to the PDF version.&nbsp;</p>
            <p/>
            <p/>
            <h3>What to include in your Portfolio?</h3>
            <p>As a Part 2 Architectural Assistant, it is essential to include additional elements in your portfolio to enhance its appeal to potential employers. Alongside your academic work, highlight any relevant professional experience, such as internships or work placements, showcasing your ability to apply architectural knowledge in practical settings.&nbsp;</p>
            <p>Emphasise your proficiency in software tools, providing examples of projects that demonstrate your skills effectively. Illustrate your design development process, including sketches, concept iterations, and the evolution of your designs.&nbsp;</p>
            <p>Incorporate construction documentation, such as detailed drawings and material schedules, to exhibit your understanding of technical aspects in architecture. Highlight collaborative projects to showcase your teamwork and effective communication abilities.&nbsp;</p>
            <p>Finally, present any additional skills, such as 3D modeling or sustainable design strategies. By providing context and narrative for each project, you allow potential employers to comprehend your design thinking process and assess your suitability for their specific projects.</p>
            <p/>
            <p/>
            <h3>Description and Fonts</h3>
            <p>Provide a short description for each project and keep it under 100 words. It should be short and snappy - especially if it&rsquo;s your Sample Portfolio.&nbsp;</p>
            <p>Details worth mentioning are:</p>
            <ul>
            <li>Project Title/Locations</li>
            <li>Value (if applicable)</li>
            <li>RIBA Stages</li>
            <li>Scale</li>
            <li>Your responsibilities</li>
            <li>Software&nbsp;</li>
            </ul>
            <p>For the font, use no more than 2 different types and ideally stick to Arial, Times New Roman or Calibri.&nbsp;</p>
            <p>Also, please check your documents for any spelling errors.&nbsp;</p>
            <p/>
            <p/>
            <h2>Layout and Design&nbsp;</h2>
            <p/>
            <p/>
            <h3>Design Software</h3>
            <p>When it comes to designing the layout for your Portfolio. Adobe InDesign provides simple tools that let you easily create, drag &amp; drop and edit documents.&nbsp;</p>
            <p>In case you want to edit any drawings/images, I would suggest using Photoshop and Illustrator.</p>
            <p>💡Tip: Please never send a Powerpoint presentation or dozens of single drawings to a hiring manager. This will look extremely unprofessional and they will probably not even open it.</p>
            <p/>
            <p/>
            <h3>Cover Page + Examples</h3>
            <p>First impressions count - keep it simple. You could either use one of your hand sketches or go for more of a minimalistic look.</p>
            <p>💡Tip: On the cover page and last page, you should include your name, phone number and email address.</p>
            <p>Below are some simple but clean cover pages</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/_Portfolio Cover 1.png" alt="Minimalist Cover Page - very simple Portfolio page - light background, with sample portflio, dates, name, job title and contact details"></img>
            {/* <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/keira_yang_zhan_cover_page.jpg" alt="Minimalist Cover Page by Keira Yang Zhan"></img> */}
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Sample Portfolio Cover 2.png" alt="Minimalist Cover Page - very simple Sample Portfolio page - dark background, with sample portflio, dates, name, job title and contact details"></img>
            <p/>
            <p/>
            <h3>Layout&nbsp;</h3>
            <p>Less is more! The layout is very important and shouldn&rsquo;t be confusing or busy. The way you organise and present your portfolio says a lot about you and your presentation skills.&nbsp;</p>
            <p>When the hiring manager looks through your Portfolio, is it easy to follow and understand, is there a clear cut between projects, have you provided them with a short description?</p>
            <p>💡Tip: Only use high-quality images, stick to a font colour and present it in chronological order.&nbsp;</p>
            <p/>
            <p/>
            <h3>Length</h3>
            <p>For your Sample Portfolio, aim for a max of 12 pages - remember it's only a sample.</p>
            <p>Your Full Portfolio can be up to 20-30 pages long. Show them your best/recent work but don&rsquo;t fall into the trap of showing them everything you ever worked on.&nbsp;</p>
            <p/>
            <p/>
            <h3>Ending Strong: Include Clear Contact Information</h3>
            <p>To wrap up your portfolio professionally, it's a good idea to dedicate the final page to your contact details. This ensures potential employers have easy access to reach you. Include your full name, phone number, email address, and possibly a link to your LinkedIn profile or personal website if relevant. Keep the design consistent with the rest of the portfolio - clean and simple - so the focus remains on how to connect with you. This small but important step can make a lasting impression and facilitate further communication.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Sample Portfolio Last page Dubai .png" alt="Minimalist Cover Page - very simple last page - dark background, thank you, name, job title and contact details"></img>
            <p/>
            <p/>
            <h2>Portfolio Do&rsquo;s and Don&rsquo;ts</h2>
            <p/>
            <p/>
            <h3>Things you should do</h3>
            <div>✔ Prepare 2 different Portfolios (Sample and Full Portfolio)&nbsp;</div>
            <div>✔ Only select your best work - this goes for both Sample and Full Portfolio</div>
            <div>✔ Showcase your design skills</div>
            <div>✔ Stick to a clear and simple layout</div>
            <div>✔ Include university work&nbsp;</div>
            <div>✔ Images need to be crisp and clear</div>
            <div>✔ Create a front cover for your Portfolio - first impressions count</div>
            <div>✔ Write a short paragraph on each page  (max 100 words) and explain the scheme, what problem you are trying to solve, what software you used, any key outcomes of the project etc.</div>
            <div>✔ Showcase different software abilities (if possible)</div>
            <div>✔ Add hand sketches (they love hand sketches - it&rsquo;s a great skill to have)</div>
            <div>✔ Start in chronological order - start with your most recent work&nbsp;</div>
            <p/>
            <p/>
            <h3>Things you should avoid doing</h3>
            <div>❌ Don&rsquo;t make your portfolios too long; Sample Portfolio should be a max of 12 pages long and under 8MB and your Full Portfolio should be under 30 pages.&nbsp;</div>
            <div>❌ Don&rsquo;t start with your oldest work</div>
            <div>❌ Don&rsquo;t make your Portfolio too busy - this will only distract&nbsp;</div>
            <div>❌ Don&rsquo;t make it too wordy</div>
            <div>❌ Don&rsquo;t duplicate similar projects over and over again. If applicable, show them a range of different schemes.&nbsp;</div>
            <div>❌ Don&rsquo;t change the layout halfway through - stick to one.</div>
            <div>❌ Don't use drawings/images from others and claim they are yours&nbsp;</div>
            <div>❌ Don't send single drawings or images - it should be all in one document</div>
            <p/>
            <p/>
            <h2>4. Section: Cover Letter (2 Examples) + FAQs</h2>
            <p/>
            <p/>
            <h3>What is a Cover Letter?</h3>
            <p><em>&ldquo;The cover letter is a formal business letter which is often the first contact with a prospective employer. It serves as an introduction to you and your background experience. Since it is usually the first impression you make on the employer, you want it to be your best.&rdquo;</em></p>
            <p>Source: <a href="https://www.oxy.edu/sites/default/files/assets/cover-letters_0.pdf" target="_blank" rel="noopener">Oxy.edy</a></p>
            <p/>
            <p/>
            <h3>General Cover Letter Formatting Tips</h3>
            <div>Length: Ideally half an A4 page</div>
            <div>File type: Either email or PDF&nbsp;</div>
            <div>Font type: Easy to read fonts are Arial, Times New Roman or Calibri</div>
            <div>Font size: 10-11</div>
            <div>Naming your file: First and last name - Cover Letter. (i.e. Jane Doe - Cover Letter)&nbsp;</div>
            <p/>
            <p/>
            <h3>Why a Well-Written Cover Letter is Essential to Your Job Application</h3>
            <p>When applying for a job, it's easy to focus all your attention on perfecting your CV and forget about the importance of a cover letter. However, a well-written cover letter can be the difference between getting an interview and being passed over for the position.</p>
            <p>A cover letter serves as an introduction to who you are, highlighting your qualifications and explaining why you are the best candidate for the job. It's a chance to showcase your personality, experiences, and skills in a way that goes beyond your CV or job application.</p>
            <p>In fact, some employers may even ask specific questions in their cover letter requirements such as your notice period, salary expectations, or why you want to work for them. A well-crafted cover letter can demonstrate your attention to detail, enthusiasm for the position, and professionalism.</p>
            <p>Ultimately, a cover letter can be a critical part of the job application process. It shows potential employers that you have taken the time to research the company and the position, and that you are genuinely interested in the opportunity. Don't overlook the importance of this document &ndash; take the time to craft a well-written cover letter to increase your chances of getting hired.</p>
            <p/>
            <p/>
            <h3>Do I Need to Attach a Cover Letter to Every Application?</h3>
            <p>One common question that job seekers ask is whether they need to attach a cover letter to every job application they submit. The answer to this question is simple: you should only include a cover letter if the job advert specifically asks for one.</p>
            <p>While it's always a good idea to have a general cover letter ready in case you need it, it's important to tailor your application to the specific job you're applying for. This means reading the job advert carefully and following any instructions provided by the employer. If they ask for a cover letter, make sure you include one that is tailored to the job and showcases why you're the best fit for the role.</p>
            <p>On the other hand, if the job advert doesn't mention a cover letter, it's safe to assume that the employer doesn't require one. In this case, you should focus on creating a strong CV and making sure that your application meets all the requirements listed in the job advert.</p>
            <p>Attaching a cover letter when it's not required can actually work against you, as it may indicate that you haven't read the job advert carefully. So, always check the job advert for specific instructions and follow them closely to maximise your chances of success.</p>
            <p/>
            <p/>
            <h3>How Long Should a Cover Letter Be?</h3>
            <p>While you want to make sure that your cover letter contains all the necessary information, you also don't want it to be too long. A good rule of thumb is to keep your cover letter to no longer than half an A4 page.</p>
            <p>Your cover letter should be easy to scan and read quickly. A lengthy cover letter can be overwhelming for a hiring manager or recruiter, who likely has to review dozens, if not hundreds, of applications.</p>
            <p>Remember that the purpose of a cover letter is to provide a brief introduction to who you are, what qualifications you have, and why you're the best candidate for the job. You don't need to go into exhaustive detail about every aspect of your career history. Instead, focus on the most important details, such as your relevant skills and experiences, and why you're interested in the position.</p>
            <p>By keeping your cover letter concise and to the point, you'll increase the chances that the hiring manager or recruiter will take the time to read it and consider you for the position.</p>
            <p/>
            <p/>
            <h3>Why Generic Cover Letters Don&rsquo;t Work</h3>
            <p>A common mistake that job seekers make is sending a generic cover letter alongside their resume. While it may seem like a time-saver to use a template and simply swap out the company name, this approach can actually harm your chances of landing the job.&nbsp;</p>
            <p>Hiring managers and recruiters can instantly tell when a cover letter has not been tailored to the specific job and company.&nbsp;</p>
            <p>A generic cover letter can give the impression that you are not genuinely interested in the position or the company, and it fails to showcase how your skills and experience align with the requirements of the job.</p>
            <p/>
            <p/>
            <h3>How to Research a Company</h3>
            <p>Before you start crafting your cover letter, it's useful to research the company you're applying to. Not only will this help you tailor your cover letter to their needs, but it will also help you determine if the company is the right fit for you and make your Cover Letter sound less generic.&nbsp;</p>
            <h3>Here are some ways to research a company:</h3>
            <ol>
            <li>Check out the company's website: Start by visiting the company's website and reading about their mission, values, and history. Look for any recent news or press releases to get an idea of what the company is currently working on.</li>
            <li>Use social media: Many companies have a social media presence, so follow them on platforms like LinkedIn, Twitter, and Facebook. This can give you a sense of their company culture and the types of content they share.</li>
            <li>Look up employee reviews: Websites like Glassdoor and Indeed allow current and former employees to leave anonymous reviews about their experience working at a company. This can provide valuable insights into the company's culture, management style, and work-life balance.</li>
            </ol>
            <p>By researching the company, you can show in your cover letter that you understand their needs and are genuinely interested in working for them. Additionally, it can help you determine if the company is a good fit for your career goals and values.&nbsp;</p>
            <p>I appreciate that you can't always invest that much time into every single application. However, I am a firm believer in quality over quantity, and the aim should never be to send out hundreds of applications every single day.</p>
            <p/>
            <p/>
            <h3>Which Keywords should I use in my Cover Letter?&nbsp;</h3>
            <p>Whether you&rsquo;re sending your application directly or uploading your details via a company's recruitment portal using relevant keywords will help your application stand out.&nbsp;</p>
            <p>Below are the most commonly used keywords for any architectural positions:</p>
            <p>Presentation skills, communication skills, team player, organisational skills, time management skills, creative, design skills, technical skills, pro-active, problem-solving skills, interpersonal skills, Revit, Autocad, Photoshop, Sketchup, InDesign etc.</p>
            <p/>
            <p/> 
            <h3>Basic Structure of your Cover Letter (plus Examples)</h3>
            <p>You should structure your Cover Letter in 4 parts.</p>
            <ol>
            <li>Why are you contacting them</li>
            <li>Why would you like to work for them</li>
            <li>Highlight your skills</li>
            <li>Summarise&nbsp;</li>
            </ol>
            <p>The first paragraph in your Cover Letter should include why you&rsquo;re contacting them, the position you are applying for and where you saw the role advertised.&nbsp;</p>
            <p>Second paragraph, explain to them why you would like to work for them (do you like the projects they work on, have they recently won any awards, have you been following the practice for a while etc.) and what skills you offer.&nbsp;</p>
            <p>Third paragraph, highlight any of your key Skills and show them what you could bring to the table i.e. Revit, UK Building Regulations,technical skills, design skills, strong communication skills, attention to detail, and team player.&nbsp;</p>
            <p>The fourth paragraph, summarise why you&rsquo;re interested and why you&rsquo;re the right fit for the role.</p>
            <p/>
            <h3>Example 1: Part 2 Architectural Assistant Cover Letter</h3>
            <p><em>Dear [Hiring Manager],</em></p>
            <p><em>I am writing to express my keen interest in the Part 2 Architectural Assistant position at [Company]. As an aspiring architect with a solid foundation in architectural education, a strong passion for design, and valuable working experience as a Part 1 Architectural Assistant at [Previous Architectural Practice], I am thrilled about the prospect of contributing my skills and enthusiasm to your esteemed team.</em></p>
            <p><em>Having recently completed my architectural studies at [University], I am eager to embark on the next phase of my professional journey and acquire practical experience within a reputable firm like [Company]. I have been closely following your projects and have been greatly impressed by your dedication to innovative and sustainable design solutions.</em></p>
            <p><em>Throughout my academic training and working experience, I have developed a comprehensive understanding of architectural principles and attained proficiency in essential software tools such as Revit, AutoCAD, and Adobe Creative Suite. These technical competencies, coupled with my ability to work collaboratively and communicate effectively, have empowered me to actively contribute to design projects and consistently deliver high-quality work.</em></p>
            <p><em>What distinguishes me is my unwavering drive to learn and grow as an architectural professional. I am fully committed to staying abreast of industry trends and eagerly contributing my creativity and problem-solving abilities to meaningful projects. I firmly believe that [Company] can provide the mentorship and challenging opportunities necessary to augment my skills and advance my career.</em></p>
            <p><em>Thank you for considering my application. I would be delighted to discuss how my skills, passion, and working experience align with the goals and vision of [Company].</em></p>
            <p><em>Sincerely,</em></p>
            <div><em>[Your Full Name]&nbsp;</em></div>
            <div><em>[Phone Number]&nbsp;</em></div>
            <div><em>[Email Address]</em></div>
            <p/>
            <p/>
            <h3>Example 2: Part 2 Architectural Assistant Cover Letter</h3>
            <p><em>Dear [Hiring Manager],</em></p>
            <p><em>I am writing to express my enthusiasm in applying for the Part 2 Architectural Assistant position at [Company]. With a robust background in architectural education, valuable working experience as a Part 1 Architectural Assistant at [Previous Architectural Practice], and a deep passion for innovative and sustainable design, I am confident that I would be a valuable asset to your team.</em></p>
            <p><em>Having recently completed my architectural studies at [University], I am highly motivated to acquire practical experience within a prestigious firm like [Company]. During my academic journey and working experience, I actively participated in collaborative efforts on large-scale residential schemes, which have honed my skills in sustainable design.</em></p>
            <p><em>My attention to detail, strong work ethic, and unwavering passion for design position me well to make meaningful contributions to your team. I am eager to join [Company], as it would provide an exceptional platform for me to further develop my skills and contribute to projects that align with my core values.</em></p>
            <p><em>Thank you for considering my application. I am available for an interview at your convenience to discuss how I can contribute to the success of [Company].</em></p>
            <p><em>Kind regards,</em></p>
            <div><em>[Your Full Name]&nbsp;</em></div>
            <div><em>[Phone Number]&nbsp;</em></div>
            <div><em>[Email Address]</em></div>
            <p/>
            <p/>
            <p>Before submitting your application, find out the name of the hiring manager/director. This will not only leave a good first impression behind but will also show that you invested time in carefully preparing your application. If you&rsquo;re not certain, address it to &lsquo;Dear Hiring Manager&rsquo;.</p>
            <p/>
            <p/>
            <h3>How Not to Start Your Cover Letter: Tips for Addressing the Hiring Manager</h3>
            <p>One of the most important aspects of writing a cover letter is addressing the hiring manager appropriately. However, it can be challenging to know exactly how to do this, especially if you are not sure who will be reading your application.&nbsp;</p>
            <p>Here are some tips for addressing the hiring manager in your cover letter:</p>
            <ol>
            <li>Avoid using generic greetings: Starting your cover letter with a generic greeting like "Dear Sir/Madam", "To Whom It May Concern" or "Hi" can make it seem impersonal and unprofessional. It's best to do some research and try to find out the name of the hiring manager or HR representative.</li>
            <li>Use LinkedIn or the company website to find the hiring manager's name: If the job listing doesn't include the name of the hiring manager, you can try using LinkedIn or the company's website to find the person's name. If all else fails, you can always call the company and ask for the name of the person responsible for hiring.</li>
            <li>Address the person formally, unless instructed otherwise: Once you have the name of the hiring manager, it's important to address them formally in your cover letter. In the UK, it's common to use the hiring manager's first name instead of their last name. However, if the job listing specifically instructs you to use a different form of address, be sure to follow those instructions.</li>
            <li>Use "Dear Hiring Manager" as a last resort: If you are unable to find the name of the hiring manager, you can use "Dear Hiring Manager" as a last resort. While it's not as personal as using the person's name, it's still better than using a generic greeting.</li>
            </ol>
            <p>By taking the time to address the hiring manager appropriately, you can make a great first impression and show that you are a professional and serious candidate for the position.</p>
            <p/>
            <p/>
            <h2>5. Salaries, Living Wage and RIBA Chartered Practices</h2>
            <p/>
            <p/>
            <h3>What are the usual salary ranges for a Part 2 Architectural Assistant in the Uk?</h3>
            <p></p>
            <div>
            <table>
            <tbody>
            <tr>
            <td><strong>Job Title</strong></td>
            <td><strong>London</strong></td>
            <td><strong>Outside London</strong></td>
            </tr>
            <tr>
            <td><a href="https://www.archjobs.co.uk/salary-guide/part-1-architectural-assistant-salary" target="_blank" rel="noopener">Part 1 Architectural Assistant</a></td>
            <td>&pound;23.000 - &pound;25.000</td>
            <td>&pound;20.825 - &pound;22.500</td>
            </tr>
            <tr>
            <td><a href="https://www.archjobs.co.uk/salary-guide/part-2-architectural-assistant-salary" target="_blank" rel="noopener">Part 2 Architectural Assistant</a></td>
            <td>&pound;28.000 - &pound;33.000</td>
            <td>&pound;23.000 - &pound;26.000</td>
            </tr>
            <tr>
            <td><a href="https://www.archjobs.co.uk/salary-guide/part-3-salary" target="_blank" rel="noopener">Part 3&nbsp;</a></td>
            <td>&pound;30.000 - &pound;35.000</td>
            <td>&pound;28.000 - &pound;30.000</td>
            </tr>
            <tr>
            <td><a href="https://www.archjobs.co.uk/salary-guide/architect-salary" target="_blank" rel="noopener">Architect</a></td>
            <td>&pound;35.000 - &pound;40.000</td>
            <td>&pound;31.000 - &pound;37.000</td>
            </tr>
            </tbody>
            </table>
            </div>
            <p>All figures are per year and based on 35 hrs per week and not RIBA Chartered Practices.*</p>
            <p>Please note that salaries will vary between practices and locations, so consider this as a guideline.</p>
            <p/>
            <p/>
            <h3>I am joining a RIBA Chartered Practice, do they have to pay a different minimum salary?</h3>
            <p>Yes, if you are joining a RIBA Chartered Practice, they will have to pay you the Living Wage which is set by the Living Wage Foundation and which is different to the National Minimum Wage done by the UK Government. The current hourly rates are </p>
            <p/>
            <p/>
            <h3 id="LivingWage">What is the current Living Wage?</h3>
            <p>The current Living Wage in the UK stands at &pound;12.60 per hour and &pound;13.85 per hour in London. Please note, the Living Wage is different from the National Living Wage set by the UK Government.</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/LivingWageFoundationNewRates.jpg" alt="Picture of the current Living Wage Foundation rates. UK £12.60 per hour and London £13.85 per hour."></img> </p>
            <p>Please note, Living Wage accreditation does not require employers to pay the Living Wage to volunteers or apprentices.</p>
            <p/>
            <p/>
            <h3>What is a RIBA Chartered Practice, and what does it have to do with the Living Wage?</h3>
            <p>Being a RIBA Chartered Practice in the UK signifies that the architectural company is officially recognised and accredited by the Royal Institute of British Architects (RIBA). This accreditation is a mark of quality and professionalism within the field of architecture. To achieve and maintain chartered status, a practice must adhere to certain standards, including ethical guidelines, professional conduct, and the provision of quality services.</p>
            <p>Additionally, RIBA Chartered Practices are required to pay at least the Living Wage to all their staff, as defined by the <a href="https://www.livingwage.org.uk/" target="_blank" rel="nofollow noopener">Living Wage Foundation</a>, ensuring fair compensation for employees.</p>
            <p/>
            <p/>
            <h3>So, what does that actually mean?</h3> 
            <p>If you join a RIBA Chartered Practice, they are required to pay all of their staff at least the current Living Wage.</p>
            <p>Let's assume you're a junior receiving a job offer from an RIBA Chartered Practice. Your minimum salary needs to be, depending on the working hours and location:</p>
            <p/> 
            <div>
            <table>
            <tbody>
            <tr>
            <td><strong>Hours per week</strong></td>
           <td><strong>UK (&pound;12.60 per hour)</strong></td>
            <td><strong>London (&pound;13.85 per hour)</strong></td>
            </tr>
            <tr>
            <td><strong>35 hrs</strong></td>
            <td>&pound;22,932</td>
            <td>&pound;25,207</td>
            </tr>
            <tr>
            <td><strong>37.5 hrs</strong></td>
            <td>&pound;24,570</td>
            <td>&pound;27,007.50</td>
            </tr>
            <tr>
            <td><strong>40 hrs</strong></td>
            <td>&pound;26,208</td>
            <td>&pound;28,808</td>
            </tr>
            </tbody>
            </table>
            </div>
            <p/>
            <p/>
            <p>If the practice offers you less than the above figures, they are breaching the RIBA Code of Practice Conduct.&nbsp;</p>
            <p>You can always refer back to the <a href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/code-of-practice-for-chartered-practices" target="_blank" rel="nofollow noopener">RIBA Code of Practice Document</a> - see Section 3.6.3.&nbsp;</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/RibaLivingWageRequirements.png" alt="Screenshot from the RIBA Code of Practice Document showing section 3.6.3. saying - Chartered Practices shall pay their staff fairly and facilitate and encourage their professional development - Chartered Practices are required to pay all staff, including freelance staff, apprentices and students, at least the Living Wage, as defined by the Living Wage Foundation..."></img> </p>
            <p/>
            <p/>
            <h3>What to do if your RIBA Chartered Practice does not follow Living Wage Standards?</h3>
            <p>If you find yourself working at a RIBA Chartered Practice and realise that your current salary does not meet the Living Wage standards, you have a couple of options. You can either discuss the matter with your director/HR team or reach out to the RIBA team directly, informing them that your current practice is not following the guidelines. You can contact them via email at <a href="mailto:professionalstandards@riba.org" target="_blank" rel="nofollow noopener">professionalstandards@riba.org</a>.</p>
            <p/>
            <p/>
            <h3>How do you know if the Practice is Chartered?</h3>
            <p>There are a few simple ways you can identify RIBA Chartered Practice. You can either go on the <a href="https://find-an-architect.architecture.com/practices/search" target="_blank" rel="nofollow noopener">RIBA website and find a practice by name</a> or you might see the logo on the company's website.&nbsp;</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ribacharteredlogo.PNG" alt="RIBA Chartered Practice logo"></img> </p>
            <p>So, the next time you're in an interview at a RIBA Chartered Practice and they inquire about your salary expectations, you can confidently state that, based on your research into current market rates and in alignment with the Living Wage, you are seeking a minimum of &pound;XYZ (depending on the working hours). If they are unwilling to meet the minimum, it's a big red flag. It may be in your best interest to explore other opportunities, and it could be worth considering reaching out to RIBA via email, as such behaviour should not be tolerated - <a href="mailto:professionalstandards@riba.org" target="_blank" rel="noopener">professionalstandards@riba.org</a>.</p>
            <p>To learn more on this subject please read <a href="https://www.archjobs.co.uk/articles/uk-architectural-salaries-and-living-wages" target="_blank" rel="noopener">UK Architectural Salaries, Living Wages, Unpaid Overtime and more.</a></p>
            <p/>
            <p/>
            <h3>Conclusion</h3>
            <p>Alrighty then, congratulations on reaching the end of this article! If you're still with me, you definitely deserve a round of applause.</p>
            <p>Finding your next Part 2 Architectural Assistant role can be tricky, but I hope that this article has helped you grasp the basics. Utilise and grow your professional network and ensure that your documents are in good shape. Aim for fewer but higher-quality applications. If you find yourself sending out hundreds of applications every week, it's likely that you need to make some changes to your application documents or strategy.</p>
            <p>Also, if your technical skills are lacking or you want to improve your Portfolio by adding technical drawings, please head over to the folks at ArchAdemia. They have created an incredible resource for people just like you. To get <a target=" blank" href="https://archademia.com/pricing/">10% off your Annual Membership subscription</a>, simply enter <strong>ARCHJOBS10</strong>. </p>
            <p>Additionally, you can use <a href="https://www.archjobs.co.uk/search/Part-2-Architectural-Assistant-Jobs-in-United-Kingdom" target="_blank" rel="noopener">ArchJobs to look for a Part 2 Architectural Assistant role</a>. Explore our resources on <a href="https://www.archjobs.co.uk/salary-guide/part-2-architectural-assistant-salary" target="_blank" rel="noopener">Part 2 Architectural Assistant Salary Guides</a>, <a href="https://www.archjobs.co.uk/articles/everything-you-need-to-know-before-signing-an-employment-contract" target="_blank" rel="noopener">Everything You Need to Know Before Signing an Employment Contract</a> and other <a href="https://www.archjobs.co.uk/articles" target="_blank" rel="noopener">articles which dive deep into various architectural-related topics</a> - I would highly recommend to read <a href="https://www.archjobs.co.uk/articles/uk-architectural-salaries-and-living-wages" target="_blank" rel="noopener">UK Architectural Salaries, Living Wages, Unpaid Overtime and more</a>. </p>            
            </ArticleFrame>
    }
}