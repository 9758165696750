import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class EmploymentContract extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="EmploymentContract" >
        <p>Whether you are looking for your first-ever architectural job in the UK or you have held several roles, this guide will highlight why it’s important to read and understand your employment contract before signing it. I will explain why you should never start a new job without having signed your contract, what to look out for when you receive a new contract, what to do if the contract does not reflect what was discussed or promised during the interview, and much more.</p>
        <p>When I was younger, I never put much emphasis on reading a contract, but over the years, I have learned my lesson and wished I had noticed that certain sections were either missing or needed clarification. Hindsight is always a beautiful thing, and hopefully, you can learn from my mistakes.</p>
        <p/>
        <p/>
        <h2>What is a Contract?&nbsp;</h2>
        <p>Let’s start with the basics: what is the definition of a contract? According to the Cambridge Dictionary, a contract is "<em>A legal document that states and explains a formal agreement between two different people or groups, or the agreement itself.</em>"</p>
        <p/>
        <p/>
        <h2>What Do You Need to Know Before Signing a Contract?</h2>
        <p>It's very important to understand that once you sign a contract, you have agreed to the company's rules. It’s highly unlikely that once you sign it, you’ll be able to go back and ask for adjustments. That’s another reason why you should never rush, take at least a couple of days to read it thoroughly and even ask some friends or family to help you.</p>
        <p/>
        <p/> 
        <h2>Why Should You Never Start a Job Without a Contract?</h2>
        <p>The reason you should never start a job without a signed contract is because you are not protected. I have heard some horror stories of juniors or overseas-qualified professionals being pressured into starting a new job without having seen or signed a contract. They were not paid the right amount or were let go without any notice.</p>
        <p>Starting a job without a contract leaves you vulnerable to misunderstandings and disputes over your rights and responsibilities. Without a written agreement, there’s no official record of your job title, salary, working hours, benefits, or terms of employment.</p>
        <p/>
        <p/> 
        <h2>Is an Offer Letter the Same as an Employment Contract?</h2>
        <p>No, an offer letter and an employment contract serve different purposes in the hiring process. Here’s how they differ:</p>
        <p>
          <strong>Offer Letter: </strong> 
          An offer letter is a formal document issued by an employer to a prospective employee, offering them a position within the company. It typically includes:</p>
        <ul>
          <li>Confirmation of the job title and responsibilities</li>
          <li>Proposed salary and any additional compensation (such as bonuses or stock options)</li>
          <li>Start date and location of work</li>
          <li>Any conditions of employment, such as passing a background check</li>
        </ul>
        <p>An offer letter is not legally binding in the same way as an employment contract. It outlines the basic terms and conditions of employment and serves as a precursor to the contract.</p>
        <p>
          <strong>Employment Contract: </strong>
          An employment contract is a legally binding agreement between an employer and an employee. It goes into more detail than an offer letter and typically includes:</p>
        <ul>
          <li>Specific terms of employment, such as job responsibilities, hours of work, and reporting structure</li>
          <li>Compensation details, including salary, benefits, and any allowances</li>
          <li>Duration of employment (whether it’s permanent or fixed-term)</li>
          <li>Terms related to termination of employment, including notice periods and grounds for dismissal</li>
          <li>Confidentiality clauses, non-compete agreements, and intellectual property rights</li>
        </ul>
        <p>Unlike an offer letter, an employment contract is signed by both parties to signify mutual acceptance of the terms and conditions outlined within it.</p>
        <p>In short, an offer letter precedes an employment contract and outlines the basic terms of a job offer, while the employment contract is a comprehensive legal document that solidifies the terms and conditions of employment once accepted and signed by both the employer and the employee.</p>
        <p/>
        <p/> 
        <h2>Understanding Different Types of Employment Contracts</h2>
        <p>When searching for a job in the UK architectural sector, it's crucial to understand the various types of employment contracts you may encounter.</p>
        <p>
          <strong>Permanent Contracts </strong>
          offer long-term job security and typically include benefits such as a steady salary, pension contributions, and holiday allowances. However, it's essential to scrutinize details like probationary periods, notice periods, and any clauses related to job responsibilities and performance expectations.&nbsp;</p>
        <p>
          <strong>Fixed-term Contracts</strong>, on the other hand, are set for a specific duration or project and may or may not include the same benefits as permanent contracts. Key considerations for fixed-term contracts include understanding the terms of renewal, the conditions under which the contract can be terminated early, and the implications for job continuity and career progression.&nbsp;</p>
        <p>
          <strong>Freelance or Consultancy Contracts </strong>
          provide greater flexibility but come with different protections and responsibilities - you would also need to determine if your contract is Inside or Outside IR35. Freelancers often handle their own taxes and do not receive typical employee benefits such as paid leave or pension contributions. It's vital to pay close attention to clauses on payment terms, intellectual property rights, and confidentiality to ensure fair compensation and protect your professional interests. </p>
          <p/>
          <p/>
        <h2>What to Look Out for in your New Employment Contract?&nbsp;</h2>
        <p>When you receive a contract, you should always check for the following details to ensure they are correct. You’d be surprised how many errors or typos you could find.</p>
        <ol>
          <li>
            <strong>Your Full Name and Other Personal Details</strong>: Check there are no spelling errors or typos</li>
          <li>
            <strong>Start Date</strong>: Confirm that the start date matches what was discussed during the interview</li>
          <li>
            <strong>Job Title</strong>: Verify that the job title is correct</li>
          <li>
            <strong>Salary</strong>: Check that the correct base salary is confirmed</li>
          <li>
            <strong>Working Hours</strong>: Standard full-time working hours can range from 35 to 40 hours per week</li>
          <li>
            <strong>Holiday Allowance</strong>: Ensure they offer at least 20 days plus 8 bank holidays</li>
          <li>
            <strong>Working Arrangements</strong>: Confirm whether the role is hybrid, flexible, or office-based</li>
          <li>
            <strong>Bonus Structure</strong>: If applicable, check how the bonus is structured and when it is paid</li>
           <li>
            <strong>Notice Period</strong>: The standard notice period for permanent employees is 4 weeks, but for more senior staff, it can be up to 3 months. Sometimes, the notice period increases by 1 week for each additional year of service, up to 12 weeks. If you are working as a contractor, you might have a 1 or 2 weeks notice period.</li>
          <li>
            <strong>Job Description/Responsibilities</strong>: Make sure the job description aligns with what was discussed</li>
            <li>
            <strong>Probationary Period</strong>: This can vary, usually from 3 to 6 months</li>
          <li>
            <strong>Intellectual Property Ownership</strong>: I.e. clarify ownership of items like laptop, designs etc.</li>
          <li>
            <strong>Paid Memberships</strong>: Double check if they cover professional memberships such as ARB, AABC, CMLI, MCIAT, or RIBA</li>
          <li>
            <strong>Pension Contribution</strong>: Check if they offer more than the standard 3%</li>
          <li>
            <strong>Other Benefits</strong>: Look for additional benefits such as car allowance, season loan, health insurance, and maternity or paternity leave.</li>
        </ol>
        <p>In case you want to understand more about UK architectural salaries, the National Minimum Wage, and how much a RIBA Chartered Practice has to pay as a minimum,
          <a target="_blank" href="https://www.archjobs.co.uk/articles/uk-architectural-salaries-and-living-wages"> read my article which covers all that and much more
          </a>.</p>
          <p/>
          <p/> 
        <h2>Does Your New Employer has to Pay for Overtime?</h2>
        <p>Employers do not have to pay workers for overtime. However, your average pay for the total hours you work must not fall below the <a href="https://www.gov.uk/your-right-to-minimum-wage" target="_blank" rel="nofollow noopener">National Minimum Wage</a>.</p>
          <p>Your employment contract will usually include details of any overtime pay rates and how they&rsquo;re worked out.</p>
          <p>There are a lot of employers out there who do not pay their staff for overtime, but what are things an employer could offer you instead? Some companies offer Flextime or TOIL (Time off in lieu). Those are usually good alternatives if you work unpaid overtime.</p>
          <p>Therefore, it is really important to have those details set in your contract. What happens if you work overtime? Will they pay for it, or will they give you Flextime or TOIL? Always make sure you have that written in your contract, if not, ask them to clarify it before you sign.</p>
          <p/>
         <p><strong>Free Tool: </strong>
          If you're interested in seeing how all the unpaid overtime is impacting your salary, try our  <a target="_blank" href="https://www.archjobs.co.uk/unpaid-overtime-calculator">
             free online overtime calculator
          </a>. It's super easy to use, and you'll be surprised by how a few additional hours here and there can impact your pay.</p>
          {/* <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UnpaidOvertimeCalcExample24.png" alt="A screenshot of ArchJobs's Unpaid Overtime Calculator. It will show you how much you should earn, how much you actually earn, and whether your hourly rate meets the National Minimum Wage and/or Living Wage."></img> </p> */}

          <p/>
          <p/>
        <h2>What to Do If You Receive Two Similar Job Offers?</h2>
        <p>If you receive two very similar job offers and can’t decide which one to choose, read through both contracts and identify differences in their benefits packages. Compare both packages, and you might start to see that one practice puts more emphasis on its training and mentoring scheme, whereas the other offers better holiday and pension contributions. You need to figure out what is important to you and what will help you move your career in the right direction.</p>
        <p>For example, if I were looking for a graduate role, I’d much rather know that the practice offers me as much support as I need, whether that includes providing me with a mentor, software training courses, paid study leave, and so on. </p>
        <p><strong>
          Read: </strong>
          <a target="_blank" href="https://www.archjobs.co.uk/articles/architectural-guide-how-to-choose-between-job-offers">
           How to Choose Between Job Offers
          </a>
        </p>
        <p/>
        <p/> 
        <h2>Which Benefits are Employees Entitled to in the UK?&nbsp;</h2>
        <p>In the UK, employees are entitled to a range of statutory benefits designed to protect their rights and ensure fair treatment in the workplace.</p>
        <p>Here are the key benefits: </p>
        <ol>
          <li>
            <strong>Workplace Pension: </strong>
            Employers are required to automatically enrol eligible employees into a workplace pension scheme and make contributions to their pension. As a minimum, you have to contribute 5%, and your employer pays 3%, totalling 8%. Some employers may match your contribution, exceeding the minimum requirements.</li>
          <li>
            <strong>Statutory Sick Pay (SSP): </strong>
            Employees who are unable to work due to illness are entitled to receive statutory sick pay from their employer, provided they meet the eligibility criteria.</li>
          <li>
            <strong>Statutory Annual Leave: </strong>
            Employees are entitled to a minimum of 28 days of paid annual leave per year, which can include public holidays. Some job adverts may state "20 days plus bank holidays," totalling 28 days including bank holidays.</li>
          <li>
            <strong>Statutory Maternity Pay (SMP): </strong>
            Pregnant employees are entitled to up to 39 weeks of statutory maternity pay, paid by the employer. Eligibility criteria are based on length of service and earnings.</li>
          <li>
            <strong>Paternity Leave: </strong>
            Eligible employees can take up to two weeks of paid paternity leave following the birth or adoption of a child.</li>
          <li>
            <strong>Shared Parental Leave: </strong>
            Parents can share up to 50 weeks of leave and up to 37 weeks of pay between them in the first year after their child is born or adopted.</li>
          <li>
            <strong>Adoption Leave and Pay: </strong>
            Statutory Adoption Leave is 52 weeks, consisting of 26 weeks of Ordinary Adoption Leave and 26 weeks of Additional Adoption Leave. Only one person in a couple can take adoption leave; the other partner may take paternity leave instead. Employees can also take paid time off work to attend 5 adoption appointments after being matched with a child.</li>
          <li>
            <strong>Parental Leave: </strong>
            Parental leave is unpaid and allows for 18 weeks' leave per child (up to their 18th birthday). Each parent can take up to 4 weeks of parental leave per child in a year, unless the employer agrees otherwise.</li>
        </ol>
        <p>
        <p>Source:<a target="_blank" href="https://www.gov.uk/browse/working"> Gov UK    </a>
        </p>
          <strong>Note: </strong>
          These are the benefits employers must provide. Payment for overtime, hybrid working arrangements, private healthcare, and bonus pay are not mandatory and vary between employers.</p>
          <p/>
          <p/>
        <h2>What are Good Benefits to Look Out for in the UK?</h2>
        <p>Are you currently applying for architectural roles in the UK but unsure which employment benefits truly stand out? It can be challenging to assess which company offers the best benefits package when interviewing with multiple practices simultaneously.</p>
        <p>Here are some key benefits that are worth paying attention to:</p>
        <p>
          <strong>Holiday Allowance: </strong>
          Look for packages that offer over 24 days of annual leave plus 8 bank holidays. A generous holiday allowance can significantly contribute to your work-life balance.</p>
        <p>
          <strong>Enhanced Pension Contribution: </strong>
          Consider employers who contribute more than the statutory minimum of 3% to your pension.</p>
        <p>
          <strong>Enhanced Maternity Leave: </strong>
          Some companies offer enhanced maternity leave, such as full pay for the first 6 weeks rather than the statutory 90% of average weekly earnings before tax.&nbsp;</p>
        <p>
          <strong>Enhanced Paternity Leave: </strong>
          Look for packages that include over 2 weeks of paid paternity leave.</p>
        <p>
          <strong>Flexible and Hybrid Working Arrangements: </strong>Employers offering flexible working hours or hybrid work-from-home options provide greater flexibility in managing your work schedule and personal commitments. Currently, it's quite common for practices to offer two days of working from home.</p>
          <p>However, if you are a junior professional, I highly recommend spending as much time as possible in the studio. Why? Being present allows you to learn more, interact with colleagues, and build your <a target="_blank" href="https://www.archjobs.co.uk/articles/where-to-find-architectural-networking-events-uk">
            professional network</a> more effectively.</p>
        <p>
          <strong>Paid Professional Memberships: </strong>
          Financial support for professional memberships (e.g., ARB, RIBA, AABC, CMLI, CIAT) can be particularly valuable, especially considering the rising costs of membership fees.</p>
        <p>
          <strong>Performance-Based Bonuses: </strong>
          While not guaranteed, performance-related bonuses can provide additional financial rewards based on individual or company performance achievements.&nbsp;</p>
        <p>These benefits can vary widely among employers, so it's crucial to prioritise those that align with your career goals and personal circumstances. Additional benefits to consider may include Private Healthcare Insurance, Life Assurance, Employee Assistance Programmes, Cycle to Work Scheme, Annual Study Trips, Paid Study Leave, Season Loan Tickets, and more. Evaluate which benefits are most useful to you based on your individual needs and preferences.</p>
        <p/>
        <p/>
        <h2>What Should You Do After You Sign the Contract?</h2>
        <p>Once you are satisfied with the contract, sign and send it back to HR or whoever is the recipient. Always save a copy for yourself as well.</p>
        <p>These days, it’s common to use an online service for signing contracts electronically. When you click the sign button, the recipient receives a notification that the contract has been signed. However, I highly recommend also emailing or calling the new company to confirm that you have signed the contract. There's always a chance it could end up in their junk folder.</p>
        <p>
          <strong>Tip: </strong>
          If you are currently employed, I personally would not hand in my notice until after I have read and signed my new contract. You can <a target="_blank" href="https://www.archjobs.co.uk/articles/architects-guide-how-to-hand-in-your-notice"> read more on how to hand in your notice here</a>.&nbsp;</p>
          <p/>
          <p/>
        {/* <h2>What Happens if You Sign a Contract and Change Your Mind?</h2>
        <p>Changing your mind after signing a contract happens more often than you think. Let me give you an example. Suppose you have multiple job interviews and receive a job offer from one practice. Most people tend to sign the contract because they don't know if they'll receive offers from other places.</p>
        <p>Then, a week or two later, the practice you really wanted contacts you and offers you the role of XYZ. That's a bit awkward, isn't it? What do you do? Do you tell them you've already accepted another offer? First of all, take a deep breath. The new offer might not be as competitive in terms of pay, but you could politely say, "Thank you, could you send me the contract to review the full offer?".</p>
        <p>If the second offer is significantly better and meets all your criteria in terms of practice, projects, commute, salary, etc., it's wise to review the contract from the practice you already signed first to check for any specific clauses addressing this scenario. If no such clause exists, promptly inform the first practice that you are withdrawing your acceptance. Be aware that this may disappoint them and potentially strain your relationship with them. Ensure you are absolutely certain about accepting the new offer before taking these steps.</p>
        <p/>
        <p/> */}
        <h2>FAQs</h2>
        <p>Let’s address some of the most common questions about employment contracts.</p>
        <p>
          <strong>What should I do if the contract states the wrong start date?</strong> If you notice that your contract has the wrong start date, simply contact the person who sent it to you and request an amendment to reflect the correct date.</p>
        <p>
          <strong>Can I request amendments to my standard contract?</strong>  Yes, you can. Just because it’s referred to as a standard contract doesn’t mean changes aren’t possible. If you’ve discussed specific terms such as improved pension terms or holiday allowance, you’ll likely need to request these amendments or additions to the contract.</p>
        <p>
          <strong>Should I accept the job without seeing the contract?</strong> Never accept a job without reviewing the contract first. Acknowledge the offer and request the full contract. Take a few days to review it thoroughly and inform them if you have any questions or intend to sign it.</p>
        <p>
          <strong>What should I do if I don’t understand a certain section of the contract?</strong> If you find a section of the contract confusing, it’s perfectly fine to seek clarification. Reach out via email to the person who sent you the contract and ask for clarification in writing.</p>
        <p>
          <strong>Tip: </strong> During such discussions, it’s beneficial to have everything in writing. If the conversation happens over the phone, politely request them to confirm or amend details via email or even better, to amend the contract.</p>
        <p>
          <strong>How long can I wait before signing the contract?</strong> Typically, when you receive a job offer, you’ll receive the contract within a few working days and usually have about a week to sign it. It’s worth asking them when they need the signed contract returned. If they indicate an unusually tight deadline like by tomorrow lunchtime, it could be a red flag. Practices shouldn’t pressure candidates unnecessarily. Urgency might be understandable for contractor roles needing immediate support on projects.</p>
        <p>
          <strong>What happens if I wait too long before signing the contract?</strong>
          If you delay more than 7 working days without communicating with the new practice, they may withdraw the offer and consider other candidates. Companies can’t wait indefinitely for a decision.</p>
        <p>
          <strong>What should I do if the offered salary is low?</strong> If you receive an offer with a lower-than-expected salary, you have three options: decline the offer, accept it, or negotiate for a higher salary.</p>
        <p>
          <strong>Tip: </strong>Remember, to negotiate before signing the contract. Once signed, renegotiating becomes pretty much impossible.&nbsp;</p>
        <p>
          <strong>If I get promoted, will I receive a new employment contract?</strong> If you’re promoted within your current practice, it’s likely you’ll receive a new contract. As always, review all details carefully and seek clarification if anything is unclear.</p>
          <p/>
          <p/>
        <h2>Summary</h2>
        <p>Securing a new job is an exciting milestone, but it's crucial to approach it with careful consideration of your employment contract and benefits. Before signing, take a few days to thoroughly review the contract, ensuring all terms align with what was discussed during interviews. Look out for key details such as salary, working hours, holiday entitlement, and specific benefits like pension contributions and maternity leave.</p>
        <p>Understanding the differences between an offer letter and an employment contract ensures you know what you're agreeing to before making a commitment. Benefits vary widely among employers, from flexible working arrangements to professional memberships and performance-related bonuses. It's essential to prioritise benefits that support your career goals and personal well-being.</p>
        <p>Finally, don't hesitate to ask for clarification on any contract terms or benefit packages that are unclear. Negotiate where possible and ensure everything agreed upon is documented in writing. Remember, just because they said “That’s our standard contract” it doesn’t mean it can’t be amended.&nbsp;</p>
            </ArticleFrame>
    }
}