import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class QuickGuideSamplePortfolio extends React.Component {
    constructor(props) {
      
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="QuickGuideSamplePortfolio"  >
          <p>If you&rsquo;re applying for architectural positions in the UK or Dubai, UAE, you&rsquo;ll need to submit a Sample Portfolio. A Sample Portfolio is a shorter version of your Full Portfolio - the one you&rsquo;d take to an interview.</p>
          <p>Why?</p>
          <p>Hiring Managers don&rsquo;t have the time to go through over 50 pages, and they certainly don&rsquo;t want to open file sizes so large they might crash their computers.</p>
          <p>Here&rsquo;s a step-by-step guide on what to include and what Hiring Managers want to see in your Sample Portfolio.</p>
          <p/> 
          <h3><strong>Basics</strong></h3>
          <ul>
          <li>Length: No longer than 12 pages</li>
          <li>Format: PDF</li>
          <li>File size: Under 10MB</li>
          <li>File name: First name Last name - Sample Portfolio</li>
          <li>Software: InDesign is the go-to for creating portfolios</li>
          <li>Images: High-Resolution and only use your own work</li>
          </ul>
          <p/> 
          <h3><strong>First Page</strong></h3>
          <p>The first page is important - it sets the tone for what&rsquo;s to follow and should include your contact details.</p>
          <ul>
          <li>Image: I.e. Hand sketch or photo of a model you&rsquo;ve created to add a personal touch</li>
          <li>Headline: I.e. "Sample Portfolio - Work from 2021-2024"</li>
          <li>Full Name</li>
          <li>Job Title</li>
          <li>Phone Number (include country code)</li>
          <li>Email</li>
          </ul>
          <p/> 
          <h3><strong>Choosing Projects</strong></h3>
          <p>Many professionals struggle to narrow down their projects, often saying, &ldquo;But I&rsquo;ve worked on so many projects, I can&rsquo;t choose!&rdquo; Well&hellip; you&rsquo;ll have to. Here&rsquo;s an easy approach.</p>
          <p><strong>Ask yourself:</strong></p>
          <ul>
          <li>Does my Sample Portfolio reflect my current level of experience?</li>
          <li>Am I showcasing a variety of skills and experiences?</li>
          <li>Am I avoiding duplicating similar types of projects?</li>
          <li>Am I demonstrating different software skills?</li>
          <li>Am I highlighting my technical detailing abilities?</li>
          <li>Is one project especially relevant to the company I&rsquo;m applying to?</li>
          </ul>
          <p>To narrow it down, select a few projects and keep asking yourself these questions until you have 4 or 5 solid choices.</p>
          <p/> 
          <h3><strong>Description</strong></h3>
          <p>Adding a brief description for each project is essential. The Hiring Manager will likely skim through your Sample Portfolio, so make it easy to understand what they&rsquo;re looking at and your level of involvement.</p>
          <p><strong>Include the following pieces of information in the top left corner:</strong></p>
          <ul>
          <li>Project Title/Location</li>
          <li>Value (if known)</li>
          <li>RIBA Stages</li>
          <li>Scale (e.g., 40-story residential tower with 120 units)</li>
          <li>Your Responsibilities (I.e. "I worked as an Architect, overseeing RIBA Stages 4-5.")</li>
          <li>Software</li>
          </ul>
          <p>If you include a render, list the software used underneath the image.</p>
          <p></p>
          <p/> 
          <h3><strong>Showcasing a Project</strong></h3>
          <p>When showcasing a project, remember that less is more. Avoid overcrowding a page with too many images or drawings.</p>
          <p><strong>To make sure your project stands out:</strong></p>
          <ul>
          <li>Start with your most recent work</li>
          <li>Keep the layout clean and simple</li>
          <li>Ensure images don&rsquo;t overlap</li>
          <li>Use high-resolution images to highlight the quality of your work</li>
          <li>For technical drawings, ensure they are large enough to be easily readable</li>
          <li>For renders, be sure to include the software you used underneath each image</li>
          </ul>
          <p><strong>Tip:</strong> If you’ve worked on only one sector - let’s say residential schemes- try to choose projects that are slightly different and allow you to showcase a variety of skills.</p>
          <p></p>
          <p/> 
          <h3><strong>Last Page</strong></h3>
          <p>The last page is a nice way to wrap things up - don&rsquo;t forget to add your contact details.</p>
          <p><strong>What to include:</strong></p>
          <ul>
          <li>Image: Another hand sketch or model photo adds a personal touch.</li>
          <li>Full Name</li>
          <li>Job Title</li>
          <li>Phone Number (include country code)</li>
          <li>Email</li>
          </ul>
          <p/> 
          <p>For more tips on portfolios and examples of first and last pages, as well as dos and don&rsquo;ts, check out <a href="https://www.archjobs.co.uk/articles/how-to-prepare-an-architecture-portfolio"><strong>How to Prepare an Architecture Portfolio for the UK</strong></a> or <a href="https://www.archjobs.co.uk/articles/crafting-the-perfect-architectural-portfolio-for-dubais-competitive-market"><strong>Crafting the Perfect Architectural Portfolio for Dubai&rsquo;s Competitive Market</strong></a>.</p>
        </ArticleFrame>
            }
}