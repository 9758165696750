import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


//first go to ArticleData.js and add all the relavant data
//next, rename "YourArticle" below to what you named the article data section
const thisArticleData = "DubaiPortfolio"


//rename YourArticle below to your article name
export default class DubaiPortfolio extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    render(){
        return <ArticleFrame ArticleDataName={thisArticleData} dubai={this.props.dubai} >
            <p>If you are either looking to relocate to Dubai or you&rsquo;re already here and applying for architectural positions, you will need to have both a strong portfolio and a sample portfolio on hand. A well-structured portfolio can make or break your chances of landing your next role or even securing a new client. In this article, I will cover questions such as: what are the differences between a full portfolio and a sample portfolio, when to use them, what to include, how to structure them, what not to do, and much more.</p>
            <p>FYI - This article focuses on the architectural job market in Dubai. If you are applying for roles in the UK, please read <a href="https://www.archjobs.co.uk/articles/how-to-prepare-an-architecture-portfolio" target="_blank" rel="noopener">How to Prepare an Architecture Portfolio</a>.</p>
            <p/>
            <p/>
            <h2>Purpose of a Portfolio</h2>
            <p/>
            <h3>What is a Portfolio?</h3>
            <p>An architectural portfolio is a unique body of work which represents your experience as an architectural professional. A portfolio should be used to showcase your best work that you can share with potential employers or clients. It provides a true reflection of your architectural skills, your design approach, your technical abilities and how you have evolved in your career.</p>
            <p/>
            <p/>
            <h3>2 Different Types of Portfolios</h3>
            <p>When applying for an architectural position, you&rsquo;ll often be asked to submit a smaller version, also known as a sample portfolio, rather than sharing your full portfolio. This is similar to the process in the UK. From speaking with many professionals, I know it can be quite confusing and daunting to create a smaller version of your full portfolio, especially if you&rsquo;ve worked on numerous projects over the years and want to showcase as much as possible. However, it&rsquo;s actually not as difficult as you might think.</p>
            <p>Your sample portfolio needs to highlight your most recent and best work, as well as a variety of skill sets. Whether that includes technical detailing drawings, renders, sketches, or projects of different scales&hellip; that&rsquo;s enough. You don&rsquo;t need to add 10 renders - just include your best and most recent one. Similarly, you don&rsquo;t need to cram in lots of small technical drawings - 2 or 3 larger drawings will suffice and will also enhance the user experience. Nobody wants to keep zooming in and out, and there&rsquo;s also a risk the image quality may degrade, making them grainy.</p>
            <p>Remember, your sample portfolio needs to complement your CV - if you&rsquo;ve stated that you&rsquo;re great at rendering or have excellent technical skills, recruiters and hiring managers will want to see evidence of that in your sample portfolio.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Andy Shaw - Portfolio.PNG" alt="Screenshot - Andy Shaw Managing Partner at AMA, Chair of RIBA Gulf Chapter. Very good advice Aylin Round - as an employer this is what I am looking for. A few pages of the best work is enough, don't have time to go through 30 pages when so many come in. Even more - if someone emails in a crazy big file over 20MB it's a red flag that they don't know how to handle digital information well."></img>
            <p>To read the full LinkedIn post, <a href="https://www.linkedin.com/feed/update/urn:li:activity:7092051336413724672/" target="_blank" rel="nofollow noopener">click here.</a></p>
            <p>Your full portfolio will come into play when you&rsquo;re invited to an interview, allowing you to walk them through your projects. You can include far more pages, showcasing not only more details on each project but also different schemes.</p>
            <p/>
            <p/>
            <h3>General Sample Portfolio Formatting Tips</h3>
            <div>Length: Ideally under 10-12 pages</div>
            <div>File type: PDF</div>
            <div>File size: Under 10MB</div>
            <div>Cover Page: Include your Full Name, Job Title, Phone number and Email </div>
            <div>Font type: Easy-to-read fonts like Arial, Times New Roman, or Calibri</div>
            <div>Font size: 10-11</div>
            <div>Structure: Start with your most recent work</div>
            <div>Naming your file: First and last name - Sample Portfolio. (e.g. Jane Doe - Sample Portfolio)</div>
            <p/>
            <p/>
            <h3>General Full Portfolio Formatting Tips</h3>
            <div>Length: Ideally under 40-50 pages</div>
            <div>File type: PDF</div>
            <div>File size: Under 20MB</div>
            <div>Cover Page: Include your Full Name, Job Title, Phone number and Email </div>
            <div>Font type: Easy-to-read fonts like Arial, Times New Roman, or Calibri</div>
            <div>Font size: 10-11</div>
            <div>Structure: Start with your most recent work</div>
            <div>Naming your file: First and last name - Portfolio. (e.g. Jane Doe - Portfolio)</div>
            <p/>
            <p/>
            <h3>What to include in your Portfolio?</h3>
            <p>Depending on your level of seniority, your portfolio will include different types of work. Practices in Dubai wouldn&rsquo;t expect a graduate to have led a large-scale residential or commercial scheme, but they would expect that from someone more senior. Dubai&rsquo;s market is competitive, and your portfolio should highlight projects that match the city's scale and ambition.</p>
            <p><strong>Interns/Students:</strong><strong><br/></strong>If you&rsquo;re an architecture student or intern, having a portfolio is essential, especially in Dubai, where practices look for innovative and forward-thinking designs. You should showcase your academic work, creativity, and ability to approach design problems from various angles. Employers in Dubai are particularly interested in candidates who have an understanding of all stages of the design and construction process. If you&rsquo;ve completed an internship, include the projects you worked on.</p>
            <p>New and prospective students should focus on demonstrating skills and a basic understanding of architectural principles, even if you don&rsquo;t yet have practical work experience.</p>
            <p><strong>Early Career Architects (1&ndash;3 years&rsquo; experience):</strong><strong><br/></strong>For early career architects, your portfolio should showcase live projects at various stages, including design and technical drawings. Since Dubai is known for its large-scale developments, it's important to highlight your experience working on similar projects. Focus on high-rise residential, mixed-use, hospitality, and commercial projects - these sectors are thriving in Dubai. Avoid adding small-scale residential or private refurbishments, as these won&rsquo;t carry the same weight in Dubai&rsquo;s market. Including some of your university work at the end is fine, but ensure it&rsquo;s relevant to the types of projects commonly seen in the region. Highlight any experience working on larger, complex schemes, as these are highly valued.</p>
            <p><strong>Mid-Level Architects (3&ndash;8 years&rsquo; experience):</strong><strong><br/></strong>By this stage, hiring managers in Dubai will expect to see a well-rounded architect or designer. Your portfolio should reflect experience across multiple sectors, ideally showcasing projects in the UAE, KSA, or other international markets. Highlight large-scale, complex projects you&rsquo;ve worked on, especially if they include Dubai-specific sectors like luxury hospitality, iconic skyscrapers, or mixed-use developments. Show your best work and make sure to include award-winning or publicly recognised projects, if applicable.</p>
            <p>You should also showcase your site experience and completed projects, which are particularly relevant in Dubai, where fast-paced construction and large development projects require professionals who are hands-on. Don&rsquo;t forget to include technical drawings&mdash;this is often overlooked but crucial for demonstrating your ability to execute large and complex designs.</p>
            <p><strong>Senior Architects (8+ years&rsquo; experience):</strong><strong><br/></strong>For senior architects, the focus of your portfolio should be on leadership and your ability to manage large teams and projects through all stages of development. In Dubai, where mega-projects are common, your portfolio should reflect a variety of large-scale schemes, such as master planning, high-end residential developments, or major commercial projects. Highlight the schemes you&rsquo;ve led from concept to completion, especially those involving international collaboration or cross-disciplinary teams, which are common in Dubai.</p>
            <p>When creating your sample portfolio, focus on the last 5 years of work, with a strong emphasis on large-scale and high-profile projects. Stick to your best and most recent work to show that you&rsquo;re up to date with current industry trends and technology.</p>
            <p><strong>General Tips:</strong><strong><br/></strong>It can be incredibly challenging to decide which schemes to include and which images to use, but for Dubai&rsquo;s market, it&rsquo;s important to keep your portfolio focused on your most significant contributions. This is especially important for large projects where you may have been part of a team - be sure to show only the work where you had substantial involvement. Don&rsquo;t overwhelm the reader with too much detail, keep it simple, easy to follow, and maintain a consistent structure throughout your portfolio.</p>
            <p/>
            <p/> 
            <h3>Which Software are popular in Dubai?</h3>
            <p>When crafting an architecture portfolio for the Dubai market, it's essential to highlight your proficiency in industry-specific technologies that are in high demand. In Dubai, software skills in Revit and Rhino are particularly valued, with Revit being critical for BIM (Building Information Modeling) integration, which is commonly used in large-scale projects across the region. Rhino, along with Grasshopper for parametric design, is also increasingly popular, especially for creating complex, innovative forms that align with Dubai's architectural ambitions. Including a brief section that showcases your expertise in these tools can demonstrate your technical competence and align your portfolio with the expectations of top firms in the region.</p>
            <p/>
            <p/>
            <h3>Which Sectors are booming in Dubai?</h3>
            <p>In Dubai, the architectural market is driven by sectors such as high-rise residential, commercial, mixed-use developments, hospitality, and retail. The city's skyline is renowned for its iconic towers, luxury hotels, and expansive malls, reflecting the continuous demand for large-scale, ambitious projects. High-rise residential developments cater to a growing population of expatriates, while mixed-use projects are popular for blending residential, office, and retail spaces in a single development. Additionally, hospitality remains a thriving sector, as Dubai is a major global tourist destination, with constant investment in world-class hotels and resorts. Retail developments, such as malls and shopping complexes, also remain essential due to the city&rsquo;s role as a shopping hub.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/zh.jpg" alt="A picture I took from inside the Opus building designed by Zaha Hadid. "></img>
            <p/>
            <p/>
            <h3>Which Sectors/Projects to exclude from your Portfolio?</h3>
            <p>On the other hand, it&rsquo;s important to note that smaller-scale projects, such as refurbishments of residential properties or conservation work on minor historical buildings, are not conducive to finding job opportunities in Dubai. The market here leans heavily towards large, futuristic developments, with significantly less focus on the preservation of smaller, older structures. If your current workload consists primarily of small residential schemes or projects with limited scope, you may find it challenging to secure a job in this competitive environment. Remember, you will be up against professionals who have experience with large-scale schemes or those who have previously worked on projects in Dubai or the broader KSA region.</p>
            <p><strong>Read:</strong> If you would like to learn more about how to structure a CV that fits the Dubai market, <a href="https://www.archjobs.co.uk/articles/how-to-write-an-architecture-cv-for-the-dubai-market" target="_blank" rel="noopener">view my article here</a>. If you're interested in finding out more about living and working in Dubai, <a href="https://www.archjobs.co.uk/articles/guide-for-uk-architects-moving-to-dubai" target="_blank" rel="noopener">you'll enjoy this article as well</a>.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/edinburgh to dubai.png" alt="Google map showing the distance between Edinburgh, UK to Dubai, UAE. It's a 9 hour direct flight."></img>
            <p/>
            <p/>
            <h2>Structuring your Portfolios</h2>
            <p/>
            <h3>Selecting Your Content + Example</h3>
            <p>Choosing the right content can be challenging, especially if you have more than 10 years of experience. Remember, it's about quality over quantity - only include your best work.</p>
            <p>For your <strong>Sample Portfolio</strong>, limit it to 10&ndash;12 pages of your most recent and standout work. Ask yourself: <em>Does this project reflect my current skills and abilities? Does it align with the practice&rsquo;s profile or Dubai&rsquo;s architectural landscape?</em> If not, leave it out. The Dubai market is fast-paced and competitive, so focus on large-scale projects that showcase your skills in delivering complex and high-profile schemes.</p>
            <p>A <strong>Full Portfolio</strong> should consist of a broader range of projects, spanning different sectors, stages, and skills such as hand sketches, model making, technical drawings, and design expertise. For Dubai, this means showing experience in sectors like hospitality, mixed-use developments, and high-rise commercial projects. Your portfolio should demonstrate that you are the right candidate for the job, especially considering Dubai&rsquo;s demand for professionals experienced in large-scale, innovative projects.</p>
            <p><strong>Tip:</strong> If you have 8+ years of experience, focus on your work from the last 5 years, especially highlighting projects that are relevant to the Dubai market.</p>
            <p/>
            <p/>
            <h3>Order</h3>
            <p>Your portfolios should be in chronological order and start with your most recent work and present one project at a time. Don't mix and match, otherwise it will be so confusing.</p>
            <p/>
            <h3>Description and Fonts</h3>
            <p>Provide a short description for each project and keep it under 100 words. It should be short and snappy - especially if it&rsquo;s your Sample Portfolio.</p>
            <p></p>
            <p>Details you should mentioned are:</p>
            <ul>
            <li><span >Project Title/Locations</span></li>
            <li><span >Value (if applicable)</span></li>
            <li><span >RIBA Stages</span></li>
            <li><span >Scale</span></li>
            <li><span >Your responsibilities</span></li>
            <li><span >Software&nbsp;</span></li>
            </ul>
            <p></p>
            <p>For the font, use no more than 2 different types and ideally stick to Arial, Times New Roman or Calibri. Also, please check your documents for any spelling errors.</p>
            <p><strong>Tip:</strong> If you have any renders, mention underneath the image which software you used.</p>
            <p/>
            <p/>
            <h2>Layout and Design of your Portfolio</h2>
            <p/>
            <h3>Design Software</h3>
            <p>When it comes to designing the layout for your Portfolio. Adobe InDesign provides simple tools that let you easily create, drag &amp; drop and edit documents. In case you want to edit any drawings/images, I would suggest using Photoshop and Illustrator.</p>
            <p><strong>Tip:</strong> Please never send a Powerpoint presentation or dozens of single drawings to a hiring manager. This will look extremely unprofessional and they will probably not even open it.</p>
            <p/>
            <p/>
            <h3>Cover Page + Examples</h3>
            <p>First impressions count - keep it simple. You can either use one of your hand sketches or opt for a more minimalistic look. On the cover page and last page, include your name, phone number, and email address. A nice touch would be to incorporate a specific colour from your CV into your cover page. I always appreciate this attention to detail.</p>
            <p>Below are some simple but clean cover pages.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/_Portfolio Cover 1.png" alt="Minimalist Cover Page - very simple Portfolio page - light background, with portflio, dates, name, job title and contact details"></img>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Sample Portfolio Cover 2.png" alt="Minimalist Cover Page - very simple Sample Portfolio page - dark background, with sample portflio, dates, name, job title and contact details"></img>
            <p/>
            <p/>
            <h3>Layout</h3>
            <p>Less is more! The layout is very important and shouldn&rsquo;t be confusing or busy. The way you organise and present your portfolio says a lot about you and your presentation skills.</p>
            <p>When the hiring manager or recruiter looks through your portfolio, is it easy to follow and understand? Is there a clear separation between projects? Have you provided a brief description for each? One of the most common mistakes I see in many portfolios is that professionals try to show too much on a single page - sometimes cramming in over nine images. Remember, less is more.</p>
            <p><strong>Tip:</strong> Only use high-quality images, stick to a font colour and present it in chronological order. In case you have to compress your file size, double-check that the image quality hasn&rsquo;t suffered too much.</p>
            <p/>
            <p/>
            <h3>Length</h3>
            <p>For your Sample Portfolio, aim for a max of 12 pages - remember it's only a sample and you will use this document when applying for positions.</p>
            <p>Your Full Portfolio can be up to 40-50 pages long or even longer. When you&rsquo;re presenting your work show them your best/recent work or you could also ask them if there is a specific project they want to see. But don&rsquo;t fall into the trap of talking through 50 pages&hellip; that would take too long.</p>
            <p/>
            <p/>
            <h3>Ending Strong: Include Clear Contact Information</h3>
            <p>To wrap up your portfolio professionally, it's a good idea to dedicate the final page to your contact details. This ensures potential employers have easy access to reach you. Include your full name, phone number, email address, and possibly a link to your LinkedIn profile or personal website if relevant. Keep the design consistent with the rest of the portfolio - clean and simple - so the focus remains on how to connect with you. This small but important step can make a lasting impression and facilitate further communication.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Sample Portfolio Last page Dubai .png" alt="Minimalist Cover Page - very simple last page - dark background, thank you, name, job title and contact details"></img>
            <p/>
            <p/>
            <h3>Ways to Create Portfolios</h3>
            <p>There are a few different ways you can present your Portfolio and I will give you a short overview.</p>       
            <p><strong>PDF Portfolios: </strong>PDF Portfolios are a quicker and easier way to share your work and you can prepare different versions. Also, most practices specifically ask for a Sample Portfolio in PDF version. Don&rsquo;t forget to name your document - &ldquo;Your Full Name - Portfolio'' or &ldquo;Your Full Name - Sample Portfolio&rdquo;. Keep in mind that there&rsquo;s a reason job adverts specify that your file size should be under 10&ndash;15MB: some systems struggle with large files and may not be able to open them.</p>
            <p><strong>Printed Portfolios:</strong> I feel like it&rsquo;s the most organic/natural way to present your work in person. However, there are a few things to keep in mind, it can take months to create a physical copy, it can be costly and you will need to keep adding new prints. If you do decide to have a printed version - stick to A3.</p>
            <p><strong>Build a website:</strong> This is a popular option if you're a freelance Architect/Designer. It will be the perfect way to attract new clients and for people to find you as well. However, if you&rsquo;re only starting your career or you&rsquo;re not thinking of going solo, it might not be the best option for you.</p>
            <p><strong>Portfolio Hosting Sites:</strong> Two platforms which keep popping up are Issuu.com and Behance, and they can be a good way to create and share your work. Personally, I don&rsquo;t enjoy viewing Portfolios via any of those websites, as the websites can be rather slow to load and I feel like it ruins the whole experience.</p>
            <p>The moral of the story - stick to PDFs and maybe invest in a printed version.</p>
            <p/>
            <p/> 
            <h2>Do&rsquo;s and Don&rsquo;ts</h2>
            <p/>
            <h3>Things you should do</h3>
            <div>✔ Prepare both Sample and Full Portfolios and submit your Sample Portfolio when applying for roles.</div>
            <div>✔ Select only your best work for both portfolios, showcasing a range of sectors, project stages and ideally international experience.</div>
            <div>✔ Maintain a clear and simple layout with crisp images.</div>
            <div>✔ Provide concise descriptions (max 100 words per image) and showcase your software proficiency.</div>
            <div>✔ Choose the right front cover for your Portfolio - first impressions count.</div>
            <div>✔ Include hand sketches (optional).</div>
            <div>✔ Begin with your most recent work in chronological order and consider adding relevant university work if applicable.</div>
            <p/>
            <p/>
            <h3>Things you should avoid doing</h3>
            <div>✖ Avoid excessive length; Sample Portfolio should be max 12 pages, and Full Portfolio under 50 pages.</div>
            <div>✖ Don’t start with your oldest work.</div>
            <div>✖ Avoid making it too wordy.</div>
            <div>✖ Avoid duplicating similar projects; showcase variety.</div>
            <div>✖ Don't make your Portfolio too busy or inconsistent in layout.</div>
            <div>✖ Do not claim others' drawings/images as your own.</div>
            <div>✖ Present your work as one comprehensive document, not single drawings or images.</div>

            <p/>
            <p/>
            <h3>Conclusion</h3>
            <p>Your architecture portfolio is a powerful tool that can significantly impact your career, especially in a fast-growing market like Dubai. It showcases your skills, design abilities, and professional evolution in an environment where high-quality architecture and innovation are key. Tailoring your portfolio to Dubai&rsquo;s unique architectural landscape and audience is crucial - whether you're submitting a sample portfolio for job applications or a comprehensive one for interviews. Quality over quantity is essential, so choose your strongest projects, maintain a clean, professional layout, and make a memorable first impression with your cover page. Avoid common mistakes like overcrowding your pages or lacking clarity, and instead, focus on presenting a diverse range of work that reflects the type of architecture valued in Dubai. With a well-structured and carefully curated portfolio, you'll stand out in this competitive market and boost your chances of landing your dream role or securing prestigious projects.</p>
            <p> If you&rsquo;ve just started thinking of relocating to Dubai, I highly recommend you read my article  <a href="https://www.archjobs.co.uk/articles/guide-for-uk-architects-moving-to-dubai" target="_blank" rel="noopener">Essential Guide for UK Architects Moving to Dubai</a>, as it covers everything from moving, costs, how to navigate through the job market and more. If you have any further questions or need guidance, don't hesitate to <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">reach out for tailored career coaching</a>.</p>
            <p></p>
            </ArticleFrame>
    }
}

// Now, go to the App.js file
// add this line at the top, rename {YourArticle} to whatever you called it. You also need to copy and rename this file to "{YourArticle}.js"
// import YourArticle from './articles/YourArticle'
// Add another line in the Routes section, renaming "YourArticle" again:
// <Route path="/articles/YourArticle" element={<YourArticle/>} />


// now in TERMINAL below, run the following command:
// npm run-script dev
// head to the article page and review the html to make sure its properly formatted
//add <p/> between rows where you want a larger space
//add any images that were missed out (will need to upload these to article_images folder too) eg. <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="Archjobs Keywords when applying"></img>
//add rel="nofollow noopener" to any links so it doesnt impact our SEO eg.  <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="nofollow noopener">Cambridge Dictionary</a>





// finally, publish the changes to github
// When its live, go to search console and inspect the new url: https://search.google.com/search-console?resource_id=sc-domain%3Aarchjobs.co.uk
// Might need a couple of attempts for it to get cached and display properly
// Make sure the rich text snippet is working properly
// If it is, request indexing from google to speed up the process