import React, { Component } from 'react'
import SearchBar from './SearchBar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation, Navigate
} from "react-router-dom";
import axios from 'axios';
import { Collapse } from '@mui/material';
import Button from './Buttons';
import {BsGeoAlt, BsCurrencyPound, BsBriefcase, BsChevronDown, BsChevronUp, BsFillShareFill, BsClock, BsLaptop, BsChevronLeft} from 'react-icons/bs'
import {FaRegBuilding, FaIndustry} from 'react-icons/fa'
import { RiDislikeLine, RiHeartLine } from 'react-icons/ri'
import JobAd from './JobAd'
import moment from 'moment';
import withRouter from '../withRouter'
import '../css/search.css'
import ShareComponent from './ShareComponent'
import {Helmet} from "react-helmet";
import tags from '../data/tags';
import WeeklyEmail from './WeeklyEmail';
import { RecruiterCard } from './RecruiterFind';
import { NewsLetterPopUp } from './NewsLetter';

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

const SearchUrl = "https://api.archjobs.co.uk/search"
const RecruiterSearchUrl = "https://api.archjobs.co.uk/search_recruiters"
const RecruiterAnalyticsUrl = "https://api.archjobs.co.uk/recruiter_analytics"
const SimilarUrl = "https://api.archjobs.co.uk/similar_jobs"
const BuildingIcon = <svg className="JobTagIcon" width="24" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 5a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v15a1 1 0 1 1-2 0V6H7v14a1 1 0 1 1-2 0V5z" fill="currentColor"/><path d="M11 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM11 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2 11a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2H4v8a1 1 0 1 1-2 0v-9zM17 11a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v9a1 1 0 1 1-2 0v-8h-2a1 1 0 0 1-1-1z" fill="currentColor"/></svg>

const RecruiterResultPositions = [3, 8, 13, 18, 23, 28, 33, 38, 43, 48, 53, 58]

export class SimilarJobSquare extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };  
  }     

  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  render() {
   
    let job_data = this.props.data
    job_data.secondary_string = {}
    if (job_data.salary_min){
      job_data.secondary_string.type = "Salary"
      if (job_data.salary_max){ 
        job_data.secondary_string.data = job_data.salary_min.toLocaleString() +" - "+job_data.salary_max.toLocaleString()
      } else {
        job_data.secondary_string.data = job_data.salary_min.toLocaleString() +"+"
      }
      
    } else {
      let parsed_tags = job_data.tags.split(";").map(joined_tag => {return {tag: joined_tag.split(",")[0], type: joined_tag.split(",")[1]}})
      var sectors = parsed_tags.filter(row => row.type == "sector")
      if (sectors.length > 3){
        sectors = sectors.slice(0, 3)
      }
      job_data.secondary_string.type = "Sectors"
      job_data.secondary_string.data = sectors.map(row => row.tag).join(", ")
    }
    job_data.parsed_location = JSON.parse(job_data.location.split(";")[0].replace("https://", "https:\/\/"))
    var location = ""
    if (("locality" in job_data.parsed_location) && (job_data.parsed_location.locality != null)){
      location = job_data.parsed_location.locality
      // if (job_data.parsed_location.county && (job_data.parsed_location.locality != job_data.parsed_location.county) && (!job_data.parsed_location.locality.includes(job_data.parsed_location.county))){
      //   location += ", " + job_data.parsed_location.county
      // }
    } else if ("region" in job_data.parsed_location) {
      location = job_data.parsed_location.region
    }
    var url = "/job/"
    url += job_data.job_title.replace(/[\W_]+/g, "-")
    url += "-with-"+job_data.company_name.replace(/[\W_]+/g, "-")
    if (location && location != ""){
      url+= "-in-"+location.replace(/[\W_]+/g, "-")
    }
    url+="-"+String(job_data.job_id)
    url = url.toLowerCase()

    job_data.location_string = location
    job_data.saved = new Date
    job_data.url = url

    return <div className="SimilarJobContainer">
          <div className="SimilarJobTop">
            <Link to={job_data.url} className="SimilarJobTitle" state={{ fromSearch: true }} onClick={() => {this.scrollToTop()}}>{job_data.job_title}</Link>
            <div className="SavedJobBusiness"><FaRegBuilding className="CompanyIcon"/>{job_data.company_name}</div>
            <div className="SavedJobSecondary"><BsGeoAlt className="LocationIcon"/>{job_data.location_string}</div>
            
            {(job_data.secondary_string && job_data.secondary_string.type == "Sectors") && <div className="SavedJobSecondary"> <BsBriefcase className="SectorIcon"/>{job_data.secondary_string.data}</div>}
            {(job_data.secondary_string && job_data.secondary_string.type == "Salary") && <div className="SavedJobSecondary"> <BsCurrencyPound className="SalaryIcon"/>{job_data.secondary_string.data}</div>}
          </div>  
          <Link to={job_data.url} className="SimilarJobLink"  onClick={() => {this.scrollToTop()}}>View Job</Link>
    </div>
  }
}

class SearchResultLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      shareOpen: false
    };  
  }        


  render () {
    let tags = []
    for (var i = 0; i<3; i++){
      tags.push(<div className="JobTag SkeletonTag skeleton" ></div>)
    }
    let salary = <div className="SearchResultSalary"><div className="MediumText skeleton"></div></div>

    return <div className="SearchResult" key={this.props.key}>
    <div className="SearchResultHeader">
      <div className="SearchResultDateUploaded"><div className="SmallText skeleton"></div></div>
      <div className="SearchHeaderButtons">
        {/* <Button btnClassName="SmallButton" onClickFunc={() => this.setShareOpen(true)}>Share <BsFillShareFill className="SmallButtonIcon"/></Button>
        <ShareComponent url={url} open={this.state.shareOpen} setOpen = {this.setShareOpen.bind(this)} id={this.props.data.job_id} shortJobDesc={shortJobDesc}/>
        <Button btnClassName={this.props.saved ? "SmallButton Saved": "SmallButton"}  onClickFunc = {() => {this.props.saveJob(this.props.data)}} >{this.props.saved ? "Saved": "Save"}<RiHeartLine className="SmallHeart"/></Button> */}
      </div>
    </div>
    <div className="SearchResultTop">
      <div className="SearchResultImg skeleton" alt={"loading logo"} />
      <div className="SearchResultSummary">
        <h1 className="SearchResultTitle"><div className="LongText skeleton"></div> </h1>
        <h2 className="SearchResultCompany"><div className="MediumText skeleton"></div></h2>
        <div className="SearchResultLocation"><div className="MediumText skeleton"></div></div>
        {salary}
        <div className="SearchResultTags">
          {tags}
        </div>
      </div>
    </div>
    {this.props.isSearch && <div className="SearchResultBottom">
      <div className="SearchResultSummaryLine">
        <div className="FullWidthText skeleton"></div>
        <div className="FullWidthText skeleton"></div>
      </div>
      <div className="ExpandButton">
        {/* {this.state.isOpen ? <BsChevronUp className="ExpandArrow"/>:<BsChevronDown className="ExpandArrow"/>} */}
        {/* <div className="SmallText skeleton"></div> */}
      </div>
    </div>}
    </div>
  }

}


class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      shareOpen: false
    };  
}    

  getTags = (tag_types, urgent, featured) => {
    var tag_list = []
    // if (featured == 1){
    //   tag_list.push(<div className="JobTag Featured">Featured</div>)
    // }
    if (urgent == 1){
      tag_list.push(<div className="JobTag Urgent">Urgent</div>)
    }
    if ("job_type" in tag_types){
      tag_types.job_type.forEach(tag => {
        tag_list.push(<div className="JobTag"><BsClock className="JobTagIcon"/>{tag}</div>)
      })
    }
    if ((tag_list.length < 4) && ("other" in tag_types) && (tag_types["other"].includes("AJ100"))){
      tag_list.push(<div className="JobTag AJ100">AJ100</div>)
    }
    if ((tag_list.length < 4) && ("work_arrangement" in tag_types)){
      tag_types.work_arrangement.forEach(tag => {
        if(tag_list.length < 4){
          tag_list.push(<div className="JobTag">{tag}</div>)
        }
      })
    }
    if ((tag_list.length < 4) && ("software" in tag_types)){
      tag_types.software.forEach(tag => {
        if(tag_list.length < 4){
          tag_list.push(<div className="JobTag"><BsLaptop className="JobTagIcon"/>{tag}</div>)
        }
      })
    }
    if ((tag_list.length < 4) && ("sector" in tag_types)){
      tag_types.sector.forEach(tag => {
        if(tag_list.length < 4){
          tag_list.push(<div className="JobTag">{BuildingIcon}{tag}</div>)
        }
      })
    }
    if ((tag_list.length < 4) && ("job_title" in tag_types)){
      tag_types.job_title.forEach(tag => {
        if(tag_list.length < 4){
          tag_list.push(<div className="JobTag">{tag}</div>)
        }
      })
    }

    return tag_list
  }

  setShareOpen = (open) => {
    console.log("SetShare Open")
    this.setState({shareOpen: open})
  }

  render() {

    var img_url="https://cdn.archjobs.co.uk/file/Archjobs/company_logos/" +this.props.data.company_name.replace(/[\W_]+/g, "").replace(/[ +]/g, "").toLowerCase()+".png"

    var salary

    if (this.props.data.salary_min){
      if (this.props.data.salary_max){
        salary = <div className="SearchResultSalary"><BsCurrencyPound className="SalaryIcon"/>{this.props.data.salary_min.toLocaleString()} - {this.props.data.salary_max.toLocaleString()}</div>
      } else {
        salary = <div className="SearchResultSalary"><BsCurrencyPound className="SalaryIcon"/>{this.props.data.salary_min.toLocaleString()} +</div>
      }
    } else {
      if (this.props.data.salary_max){
        salary = <div className="SearchResultSalary"><BsCurrencyPound className="SalaryIcon"/>up to £{this.props.data.salary_max.toLocaleString()}</div>
      } else {
        var sectors = this.props.data.parsed_tags.filter(row => row.type == "sector")
        if (sectors.length > 3){
          sectors = sectors.slice(0, 3)
        }
        salary = <div className="SearchResultSectors"><BsBriefcase className="SectorIcon"/>{sectors.map(row => row.tag).join(", ")}</div>
      }
    }


    var location = ""
    let parsed_location = this.props.data.parsed_location_list[0]
    if(this.props.location.search){
      const urlParams = new URLSearchParams(this.props.location.search);
      const lat = urlParams.get('lat')
      const lng = urlParams.get('lng')
      if (lat && lng){
        this.props.data.parsed_location_list.forEach(location => {
          location.distance = Math.sqrt((lat-location.latitude)**2 + (lng-location.longitude)**2)
        })
        this.props.data.parsed_location_list.sort((a, b) => a.distance - b.distance)
        parsed_location = this.props.data.parsed_location_list[0]
      }
    }

    if (("locality" in parsed_location) && (parsed_location.locality != null)){
      location = parsed_location.locality
      // if (parsed_location.county && (parsed_location.locality != parsed_location.county) && (!parsed_location.locality.includes(parsed_location.county)) && (!parsed_location.county.includes(parsed_location.locality))){
      //   location += ", " + parsed_location.county
      // }
    } else if ("region" in parsed_location && parsed_location.region != null) {
      location = parsed_location.region
    }  else if ("country" in parsed_location) {
      location = parsed_location.country
    }
    const basic_location = location
    if (this.props.data.parsed_location_list.length > 2){
      location += ` (+${this.props.data.parsed_location_list.length-1} locations)`
    } else if (this.props.data.parsed_location_list.length > 1){
      location += ` (+${this.props.data.parsed_location_list.length-1} location)`
    }

    var tags = this.getTags(this.props.data.parsed_tag_types, this.props.data.urgent, this.props.data.featured)
    //go through tags in order of priority

    var url = "https://www.archjobs.co.uk/job/"
    var shortJobDesc = this.props.data.job_title + " role"
    url += this.props.data.job_title.replace(/[\W_]+/g, "-")
    url += "-with-"+this.props.data.company_name.replace(/[\W_]+/g, "-")
    shortJobDesc += " with "+this.props.data.company_name
    if (location && location != ""){
      url+= "-in-"+basic_location.replace(/[\W_]+/g, "-")
      shortJobDesc += " in "+location
    }
    url+="-"+String(this.props.data.job_id)
    url = url.toLowerCase()

    var summary_line = this.props.data.job_description.replaceAll("\n", " ").replace(/<\/?[^>]+(>|$)/g, " ").replace(/&nbsp;/g, "").slice(0, 180)

    var banner

    banner = <div className="SearchResultDateUploaded">{moment(this.props.data.posted).fromNow()}</div>
    if (this.props.data.featured){
      banner = <div className="SearchResultDateUploaded Featured">Featured</div>
    }

    var helmet
    if (!this.props.isSearch){
      var JSONschema =  {
        "@context": "http://schema.org/",
        "@type": "JobPosting",
        "datePosted": moment(this.props.data.posted).toISOString(),
        "title": this.props.data.job_title,
        "description": this.props.data.job_description,
        "hiringOrganization": {
          "@type": "Organization",
          "name": this.props.data.company_name,
          "logo": img_url
        }
      }

      if (this.props.data.company_website){
        JSONschema["hiringOrganization"]["sameAs"] = this.props.data.company_website
      }

      var locationSchema = []
      this.props.data.parsed_location_list.forEach(parsed_location => {
        if (parsed_location != null){
          var locationData = {"@type": "Place",
              "address": {
              "@type": "PostalAddress",
              // "streetAddress": "1600 Amphitheatre Pkwy",
              // "addressLocality": "Mountain View",
              // "addressRegion": "CA",
              // "postalCode": "94043",
              // "addressCountry": "US"
              }
            }
          if (("name" in parsed_location) && parsed_location["name"] != null){
            locationData["address"]["streetAddress"] = parsed_location["name"]
          }
          if (("locality" in parsed_location) && parsed_location["locality"] != null){
            locationData["address"]["addressLocality"] = parsed_location["locality"]
          }
          if (("region" in parsed_location) && parsed_location["region"] != null){
            locationData["address"]["addressRegion"] = parsed_location["region"]
          }
          if (("country" in parsed_location) && parsed_location["country"] != null){
            locationData["address"]["addressCountry"] = parsed_location["country"]
          }
          if (("postal_code" in parsed_location) && parsed_location["postal_code"] != null){
            locationData["address"]["postalCode"] = parsed_location["postal_code"]
          }
          if ("latitude" in parsed_location) {
            locationData["latitude"] = parsed_location.latitude
            locationData["longitude"] = parsed_location.longitude
          }
          locationSchema.push(locationData)
        }
      })

      if (locationSchema.length == 1){
        JSONschema["jobLocation"] = locationSchema[0]
      } else if (locationSchema.length >= 2){
        JSONschema["jobLocation"] = locationSchema
      }

      //if fully remote add other tags
      if ("work_arrangement" in this.props.data.parsed_tag_types){
        if (this.props.data.parsed_tag_types["work_arrangement"].includes("Fully Remote")){
          JSONschema["jobLocationType"] = "TELECOMMUTE"
          JSONschema["applicantLocationRequirements"] = {
            "@type": "Country",
            "name": "United Kingdom"
          }
        }
      }

      if (this.props.data.apply_method >= 16){
        JSONschema["directApply"] = false
      } else {
        JSONschema["directApply"] = true
      }
      if (this.props.data.salary_min){
        JSONschema["baseSalary"]= {
          "@type": "MonetaryAmount",
          "currency": "GBP",
        }

        if (this.props.data.salary_max && (this.props.data.salary_max> this.props.data.salary_min)){
          JSONschema["baseSalary"]["value"] = {
            "@type": "QuantitativeValue",
            "minValue": this.props.data.salary_min,
            "maxValue": this.props.data.salary_max,
          }
        } else {
          JSONschema["baseSalary"]["value"] = {
            "@type": "QuantitativeValue",
            "value": this.props.data.salary_min,
          }
        }
        if (this.props.data.salary_type){
          const salary_types = {1: "YEAR", 2: "MONTH", 3: "WEEK", 4: "DAY", 5: "HOUR"}
          JSONschema["baseSalary"]["value"]["unitText"] = salary_types[this.props.data.salary_type]
        }
        if (this.props.data.reference_id && this.props.data.company_name){
          JSONschema["identifier"] = {
            "@type": "PropertyValue",
            "name": this.props.data.company_name,
            "value": this.props.data.reference_id
          }
        }
      }

      var employment_types = []
      const job_type_mapping = {"Full-Time": "FULL_TIME", "Part-Time": "PART_TIME", 'Contract': "CONTRACTOR", 'Fixed Term Contract': "TEMPORARY"}
      this.props.data.parsed_tag_types["job_type"].forEach(job_type => {
        if (job_type in job_type_mapping){
          employment_types.push(job_type_mapping[job_type])
        }
      })

      if (employment_types.length == 1){
        JSONschema["employmentType"] = employment_types[0]
      } else if (locationSchema.length >= 2){
        JSONschema["employmentType"] = employment_types
      }

      console.log("JSONschema", JSONschema)

      helmet =  <Helmet>
        <meta charSet="utf-8" />
            <title>ArchJobs | {this.props.data.job_title}</title>
            <link rel="canonical" href={url} />
            <meta name="description" content={shortJobDesc + ". Find the best architecture jobs in the UK with ArchJobs" }/>
            <meta name="og:title" content={shortJobDesc}/>
            <meta name="og:type" content={"website"}/>
            <meta name="og:image" content={img_url}/>
            <meta name="og:url" content={url}/>
            <script type="application/ld+json">{JSON.stringify(JSONschema)}</script>
        </Helmet>
    }
  // console.log(this.props)

  let SimilarJobs
  if (!this.props.isSearch){
    let SimilarJobsList = []
    let JobSearches = []
    let LocationSearches = []
    this.props.data.parsed_location_list.forEach(parsed_location => {
      if (parsed_location != null){
        let url = "/search/Architecture-Jobs-in-"
        let text = "Search for more Roles in "
        if (("locality" in parsed_location) && (parsed_location.locality != null)){
          url +=parsed_location.locality
          text += parsed_location.locality
        } else if ("region" in parsed_location && parsed_location.region != null) {
          url +=parsed_location.region
          text +=parsed_location.region
        }  else if ("country" in parsed_location) {
          url +=parsed_location.country
          text +=parsed_location.country
        } else {
          url +="United-Kingdom"
          text += "United Kingdom"
        }
        url = url.replaceAll(" ", "-")
        if ("latitude" in parsed_location) {
          url += `?lat=${parsed_location.latitude}`
          url += `&lng=${parsed_location.longitude}`
        }
        LocationSearches.push(<Link to={url} className="SimilarJobLink" style={{textAlign: "left"}}>{text}</Link>)
      }
    })
    if (this.props.data.parsed_tag_types.job_title){
      this.props.data.parsed_tag_types.job_title.forEach(tag => {
        let text = `Search for more ${tag} Roles`
        let url = `/search/${tag}-Jobs-in-United-Kingdom`
        JobSearches.push(<Link to={url} className="SimilarJobLink" style={{textAlign: "left"}}>{text}</Link>)
      })
    }

    if (this.props.similarJobs && this.props.similarJobs.job_id == this.props.data.job_id && this.props.similarJobs.similar_jobs){
      this.props.similarJobs.similar_jobs.forEach(job => {
        SimilarJobsList.push(<SimilarJobSquare data={job} key={job.job_id}/>)
      })
    }

    SimilarJobs = <div className="SimilarJobsSection">
      <div className="SimilarJobsSectionTitle">Similar Jobs</div>
      <div className="SimilarJobsSectionList">{SimilarJobsList}</div>
      <div className="SimilarSearches">
        <div className="SimilarSearchList">{JobSearches}</div>
        <div className="SimilarSearchList">{LocationSearches}</div>
      </div>
    </div>
  }

    return [<div className="SearchResult" key={this.props.data.job_id}>
        {helmet}
        <div className="SearchResultHeader">
          {banner}
          <div className="SearchHeaderButtons">
            {this.props.admin && <Button btnClassName="SmallButton" onClickFunc={() => this.props.EditJob(this.props.data)}>Edit</Button>}
            <Button btnClassName="SmallButton" onClickFunc={() => this.setShareOpen(true)}>Share <BsFillShareFill className="SmallButtonIcon"/></Button>
            <ShareComponent url={url} open={this.state.shareOpen} setOpen = {this.setShareOpen.bind(this)} id={this.props.data.job_id} shortJobDesc={shortJobDesc}/>
            <Button btnClassName={this.props.saved ? "SmallButton Saved": "SmallButton"}  onClickFunc = {() => {this.props.saveJob(this.props.data)}} >{this.props.saved ? "Saved": "Save"}<RiHeartLine className="SmallHeart"/></Button>
          </div>
        </div>
        <div className="SearchResultTop">
          <img src={img_url} className="SearchResultImg" alt={this.props.data.company_name+ " logo"} />
          <div className="SearchResultSummary">
            <Link to={url.replace("https://www.archjobs.co.uk", "")} onClick={() => this.props.updateData(this.props.data)} state={{ fromSearch: true }}><h1 className="SearchResultTitle">{this.props.data.job_title} </h1></Link>
            <h2 className="SearchResultCompany"><FaRegBuilding className="CompanyIcon"/>{this.props.data.company_name}</h2>
            <div className="SearchResultLocation"><BsGeoAlt className="LocationIcon"/>{location}</div>
            {salary}
            <div className="SearchResultTags">
              {tags}
            </div>
          </div>
        </div>
        {this.props.isSearch && <div className="SearchResultBottom">
          <div className="SearchResultSummaryLine">
            {htmlDecode(summary_line)}...
            <span className="SearchReadMore" onClick={() => {this.setState({isOpen: !this.state.isOpen})}}>{this.state.isOpen ? "Read Less": "Read More"}</span>
          </div>
          <div className="ExpandButton"  onClick={() => {this.setState({isOpen: !this.state.isOpen})}}>
            {this.state.isOpen ? <BsChevronUp className="ExpandArrow"/>:<BsChevronDown className="ExpandArrow"/>}
          </div>
        </div>}
        <Collapse in={(this.state.isOpen || !this.props.isSearch)}>
          <JobAd data={this.props.data} isSearch={this.props.isSearch}/>
        </Collapse>
      </div>,
      SimilarJobs]
  }
}



class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: {},
      savedJobs: {},
      similarJobs: {},
      recruiterResults: [],
      region_matches: [],
      searching: false,
    };  
    this.extending = false
    this.searching = false
    this.offset = 0
}    

  componentDidMount = () => {
    console.log("Mounted")
    document.addEventListener('scroll', this.trackScrolling);
    this.offset = 0
    this.search()
    this.getSavedJobs()
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  componentDidUpdate = (prevProps, prevState) => {
    console.log(this.props.router.location)
    if(this.props.router.location.pathname != prevProps.router.location.pathname){
      this.offset = 0
      this.extending = false
      this.searching = false
      // this.setState({results: {}})
      console.log("Searching from search page")
      this.search()
    }
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom-800 <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementsByClassName('SearchPageResults')[0];
    if (this.isBottom(wrappedElement)) {
      if (this.props.router.location.pathname.startsWith("/job/")) return
      if (!this.extending && !this.props.hidden){
        console.log('loading more results');
        this.search(true)
      }
    }
  };

  getSavedJobs = () => {
    var savedJobHistory = localStorage.getItem("savedJobHistory")
    if (savedJobHistory){
      savedJobHistory = JSON.parse(savedJobHistory)
      this.setState({savedJobs: savedJobHistory})
    }
  }
    
  saveJob = (new_job) => {
    console.log("Saving Job: ", new_job.job_id)
    var newSavedJobs = {...this.state.savedJobs}
    if (new_job.job_id in this.state.savedJobs) {
      delete newSavedJobs[new_job.job_id]
    } else {
      var secondary_string = {}
      if (new_job.salary_min){
        secondary_string.type = "Salary"
        if (new_job.salary_max){ 
          secondary_string.data = new_job.salary_min.toLocaleString() +" - "+new_job.salary_max.toLocaleString()
        } else {
          secondary_string.data = new_job.salary_min.toLocaleString() +"+"
        }
        
      } else {
        var sectors = new_job.parsed_tags.filter(row => row.type == "sector")
        if (sectors.length > 3){
          sectors = sectors.slice(0, 3)
        }
        // secondary_string.type = "Sectors"
        // secondary_string.data = sectors.map(row => row.tag).join(", ")
      }
      if (Object.keys(secondary_string).length !== 0){
        new_job.secondary_string = secondary_string
      }
      var location = ""
      if (("locality" in new_job.parsed_location) && (new_job.parsed_location.locality != null)){
        location = new_job.parsed_location.locality
        // if (new_job.parsed_location.county && (new_job.parsed_location.locality != new_job.parsed_location.county) && (!new_job.parsed_location.locality.includes(new_job.parsed_location.county))){
        //   location += ", " + new_job.parsed_location.county
        // }
      } else if ("region" in new_job.parsed_location) {
        location = new_job.parsed_location.region
      }
      var url = "/job/"
      url += new_job.job_title.replace(/[\W_]+/g, "-")
      url += "-with-"+new_job.company_name.replace(/[\W_]+/g, "-")
      if (location != ""){
        url+= "-in-"+location.replace(/[\W_]+/g, "-")
      }
      url+="-"+String(new_job.job_id)
      url = url.toLowerCase()

      new_job.location_string = location
      new_job.saved = new Date
      new_job.url = url
      newSavedJobs[new_job.job_id] = new_job
    }
    this.setState({savedJobs: newSavedJobs})
    localStorage.setItem("savedJobHistory", JSON.stringify(newSavedJobs))
  }


  updateData = (data) => {
    this.setState({results: {results: [data], count: 1}})
  }
  //search
 search = (extend) => {
  //get search terms from pathname
  if (this.searching) {
    console.log("Already Searched")
    return
  }
  if (this.offset > 0 && this.offset >= this.state.results.count) {
    console.log("Already at end")
    return
  }
  this.searching = true
  if(extend){
    this.extending = true
    this.setState({extending: true})
  }
  var location_data = this.props.router.location
  console.log("pathname", location_data)
  let recruiterLocation = ""
  if (location_data.pathname.startsWith("/job/")) {
    var url_split = location_data.pathname.split("-")
    var job_id = url_split[url_split.length-1]
    var fullSearchUrl = SearchUrl+"?job_id="+job_id
    axios.get(SimilarUrl+"?job_id="+job_id).then(response => {
      this.setState({similarJobs: {job_id, ...response.data}})
    })
  } else {
    var [search_term, location_term] = location_data.pathname.replace("/search/", "").replace("/admin/", "").split("-in-")
    var search_params = []
    const params = new Proxy(new URLSearchParams(location_data.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (location_data.search !== ""){
      search_params.push(location_data.search.replace("?", "")) 
      recruiterLocation = location_data.search.replace("?", "")
    }
    if (location_data.search.includes("location_lookup=true")){
      search_params.push("location_string="+location_term)
      recruiterLocation = "location_string="+location_term
    }
    var search_value
    if (!("search" in params)){
      if (search_term !== "Architecture-Jobs"){
        if (search_term.includes("-Architecture-Jobs")){
          search_value = search_term.split("-Architecture-Jobs")[0]
          search_params.push("tag="+search_value)
          
        } else {
          if (search_term.includes("-Jobs")){
            search_value = search_term.split("-Jobs")[0]
            var term_found = false
            console.log("Searching tags")
            for (const tag_groups of Object.keys(tags)){
              console.log(tags[tag_groups])
              tags[tag_groups].forEach(tag => {
                if (tag.name) return
                if (tag.replace(/[\W_]+/g, "-") == search_value){
                  search_params.push("tag="+tag)
                  term_found = true
                  search_value = tag
                }
              })
            }
            if (!term_found){
              search_params.push("tag="+search_value)
            }
          }
        }
      }
    } else {
      search_value = params.search
    }
  
    var new_state = {}
    if (search_value){
      new_state["search_value"] = decodeURIComponent(search_value).replace(/-/g, " ")
    }
    if (location_term && location_term !== "United-Kingdom"){
      new_state["location_value"] = decodeURIComponent(location_term).replace(/-/g, " ")
    }
    if (Object.keys(new_state).length > 0){
      this.setState(new_state)
    }
    search_params.push("limit=10")
    if(extend){
      this.offset += 10
      search_params.push("offset="+String(this.offset))
    }
    if (location_data.pathname.includes("/admin/")){
      search_params.push("all_results=true")
    }

    var fullSearchUrl = SearchUrl
    if (search_params.length > 0){
      fullSearchUrl += "?"+search_params.join("&")
    }
  }
  
  this.setState({searching: true})
  console.log("searching:", fullSearchUrl)
  // return
  axios.get(fullSearchUrl).then(response => {

    var response_data = response.data
    if ("results" in response.data){
      console.log(response_data)

      response_data.results.forEach(row => {
        var locations = row.location.split(";")
        var location_to_parse = locations[0].replace("https://", "https:\/\/")
        if (row.location.includes(";")){
          console.log(location_to_parse)
        }
        try {
          row.parsed_location = JSON.parse(location_to_parse)
          row.parsed_location_list = locations.map(x => JSON.parse(x.replace("https://", "https:\/\/")))
        } catch (error) {
          console.log(error)
          console.log(location_to_parse)
          row.parsed_location = {}
        }
        var tags = []
        if (row.tags){
          tags = row.tags.split(";")
        }
        row.parsed_tags = []
        row.parsed_tag_types = {}
        tags.forEach(tag => {
          var [tag_name, tag_type]= tag.split(",")
          if (!(tag_type in row.parsed_tag_types)){
            row.parsed_tag_types[tag_type] = []
          }
          row.parsed_tag_types[tag_type].push(tag_name)
          row.parsed_tags.push({tag: tag_name, type: tag_type})
        })
      })
      if (extend){
        this.setState({results: {...this.state.results, results:[...this.state.results.results, ...response_data.results]}, extending: false, searching: false})
        this.extending = false
      } else {
        this.setState({results: response_data, searching: false})
      }
      this.searching = false
    }


  })
  // recruiter search
  
  let RecruiterFullSearchUrl = RecruiterSearchUrl
  if (recruiterLocation){
    RecruiterFullSearchUrl += "?"+recruiterLocation
  }
  if(!extend){
    axios.get(RecruiterFullSearchUrl).then(response => {
      // shuffle, then order by lowest times shown, 
      let data = response.data.results
      
      data.forEach(recruiter => {
        // recruiter.date_added format: "Thu, 08 Jun 2023 09:33:35 GMT"
        const added = new Date(recruiter.date_added)
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - added.getTime();
        recruiter.days_active  = timeDifference / (1000 * 60 * 60 * 24)
        // recruiter.shown_per_day = recruiter.times_shown /recruiter.days_active
        recruiter.shown_per_day = recruiter.times_shown
      })
      data = data.sort(() => Math.random() - 0.5);
      data = data.sort((a, b) => a.shown_per_day - b.shown_per_day);
      console.log("recruiter_data; ", data)
      data.forEach(recruiter => {
          if (recruiter.location) {
              recruiter.locations = recruiter.location.split(";").map(location => {return {city: location.split(",")[0], lat: location.split(",")[1], lng: location.split(",")[2]}})
          }
          recruiter.parsed_tags = {}
          if (recruiter.tags) {
              recruiter.tag_list = recruiter.tags.split(";")
              recruiter.tag_list.forEach(tag => {
                  const tag_data = tag.split(",")
                  if (!recruiter.parsed_tags[tag_data[1]]) {
                      recruiter.parsed_tags[tag_data[1]] = []
                  }
                  recruiter.parsed_tags[tag_data[1]].push(tag_data[0])
              })
          }
      })
      let region_matches = []
      if (response.data.region_matches){
        region_matches = response.data.region_matches
      }
      this.setState({recruiterResults: data, region_matches: region_matches}, () => {
        // first 2 recruiter ids
        const recruiter_ids = data.map(recruiter => recruiter.recruiter_id).slice(0, 2).join(",")
        axios.post(RecruiterAnalyticsUrl+`?recruiter_ids=${recruiter_ids}&event=impression&page=search`).then(response => {
          console.log("Analytics response: ", response.data)
        })
      })
    })
  } else {
    // get the next few recruiters based on this.offset
    const base_recruiter_id = Math.floor(2*this.offset/10)
    
    const recruiter_ids = this.state.recruiterResults.map(recruiter => recruiter.recruiter_id).slice(base_recruiter_id, base_recruiter_id+2).join(",")
    if (recruiter_ids.length > 0){
      axios.post(RecruiterAnalyticsUrl+`?recruiter_ids=${recruiter_ids}&event=impression&page=search`).then(response => {
        console.log("Analytics response: ", response.data)
      })
    }
  }

 }



  render() {
    
    var isSearch = true
    if (this.props.router.location.pathname.startsWith("/job/")){
      isSearch=false
    }
    var response_list = []
    if ("results" in this.state.results && !(!isSearch && this.state.results.results.length > 1)){
      if (isSearch){
        if (this.state.searching) {
          response_list.push(<div className="ResultsNumber">{"Loading results..."}</div>)
        } else {
          response_list.push(<div className="ResultsNumber">{ String(this.state.results.count)+" results found"}</div>)
        }
      } else {
        if (this.props.router.location && this.props.router.location.state && this.props.router.location.state.fromSearch){
          response_list.push(<Link className="ResultsGoBack" to={-1} ><BsChevronLeft className="BackArrow"/>Back to Search</Link>)
        } else {
          response_list.push(<Link className="ResultsGoBack" to="/search" ><BsChevronLeft className="BackArrow"/>Back to Search</Link>)
        }
      }
      if (this.state.results.results.length == 0 && !this.state.searching){
        
        let gif_url = "https://media.tenor.com/xTvpjzTNfZMAAAAS/despairiounge-cat.gif"
        if(Math.random() < 0.5){
          gif_url = "https://media1.giphy.com/media/ji6zzUZwNIuLS/giphy.gif?cid=ecf05e47mtgd12k3gnjzn5ossdt0gblv8ybmnegujfts0if9&rid=giphy.gif&ct=g"
        }
        
        response_list.push(<div className="SearchNoResults">
          <img src={gif_url} alt="No Search Results" className="SearchNoResultsImg"/>
          <div className="SearchNoResultsTitle">
            No Results Found
          </div>
          <div className="SearchNoResultsSubtitle">
            We can't find any suitable jobs matching your search.
          </div>
          <div className="SearchNoResultsSubtitle">
            Please change your search and try again
          </div>
        </div>)

      }
      // iterate over results with index
      console.log("SearchPageState: ", this.state)
      for (const index_string in this.state.results.results){
        const index = parseInt(index_string)
        const result = this.state.results.results[index]
        console.log(index)
        if (RecruiterResultPositions.includes(index)){
          // get position of recruiter
          console.log(RecruiterResultPositions)
          const recruiter_index = RecruiterResultPositions.indexOf(index)
          const recruiter = this.state.recruiterResults[recruiter_index]
          console.log(recruiter)
          if (recruiter){
            response_list.push(<RecruiterCard recruiter={recruiter} key={recruiter.recruiter_id} isSearch={isSearch} selectedRegions={this.state.region_matches}/>)
          }
        }
        response_list.push(<SearchResult data={result} key={result.job_id} isSearch={isSearch} saveJob={this.saveJob} saved={result.job_id in this.state.savedJobs} updateData={this.updateData.bind(this)} admin={this.props.admin} EditJob={this.props.EditJob} hidden={this.props.hidden} similarJobs={this.state.similarJobs} location={this.props.router.location}/>)
      }
      
    }
    if (!this.state.results.results || this.state.extending || (!isSearch && this.state.results.results.length > 1)){
      if (!this.state.extending){
        response_list.push(<div className="ResultsNumber">{isSearch && "Loading results..."}</div>)
      }
      let num_results = 1
      if (isSearch) num_results = 10
      for (var i =0; i< num_results; i++){
        response_list.push(<SearchResultLoading key={i} isSearch={isSearch}/>)
      }
    }

    var savedJobsElement = []
    if (Object.keys(this.state.savedJobs).length == 0){
      savedJobsElement.push(<div classname="SaveJobsElement">
        <div className="SavedJobMessage">No saved jobs</div>
        <div className="SavedJobMessageSubtitle">Press Save <RiHeartLine className="SmallHeartSave"/> to save a job here</div>
      </div>)
    } else {
      var saved_job_data = Object.values(this.state.savedJobs).sort((a, b) => b.saved -  a.saved)
      if (saved_job_data.length > 3){
        saved_job_data = saved_job_data.slice(0, 3)
      }
      for (const job_data of saved_job_data){
        // const job_data = this.state.savedJobs[job]
        savedJobsElement.push(<div className="SaveJobsElement">
          <div className="SavedJobInfo">
            <Link to={job_data.url} className="SavedJobTitle" state={{ fromSearch: true }}>{job_data.job_title}</Link>
            <div className="SavedJobBusiness"><FaRegBuilding className="CompanyIcon"/>{job_data.company_name}</div>
            <div className="SavedJobSecondary"><BsGeoAlt className="LocationIcon"/>{job_data.location_string}</div>
            
            {(job_data.secondary_string && job_data.secondary_string.type == "Sectors") && <div className="SavedJobSecondary"> <BsBriefcase className="SectorIcon"/>{job_data.secondary_string.data}</div>}
            {(job_data.secondary_string && job_data.secondary_string.type == "Salary") && <div className="SavedJobSecondary"> <BsCurrencyPound className="SalaryIcon"/>{job_data.secondary_string.data}</div>}
          </div>
          <div className="SavedJobUnSave">
            <RiDislikeLine onClick={() => {this.saveJob(job_data)}} />
          </div>
        </div>)
        savedJobsElement.push(<div className="SaveJobsDivider"></div>)
      }
      savedJobsElement.pop()
    }

    var savedJobs = <div className="SavedJobs">
      <div className="SavedJobsTitle">
        <div className="BigHeartContainer">
          <RiHeartLine  className="BigHeart"/>
        </div>
        Your Saved Jobs
      </div>
      {savedJobsElement}
    </div>
  
    
    var default_title = "Architecture Jobs in the UK"
    if (this.props.router.location.pathname.startsWith("/search/")){
      default_title = this.props.router.location.pathname.replace("/search/", "").replace(/-/g, " ")
    }

    var helmet =  <Helmet>
    <meta charSet="utf-8" />
        <title>ArchJobs | {default_title}</title>
        <link rel="canonical" href={this.props.router.location.pathname} />
        <meta name="description" content={"Find the best " + default_title + " with ArchJobs" }/>
        <meta name="og:title" content={"ArchJobs | "+default_title}/>
        <meta name="og:type" content={"website"}/>
        <meta name="og:image" content={"https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_banner.png"}/>
        <meta name="og:url" content={this.props.router.location.pathname}/>
    </Helmet>

    let style = {}
    if (this.props.hidden) {
      style = {display: "none"}
    }

    return <div className="SearchPage" style={style}>
        <NewsLetterPopUp />
        {isSearch && helmet}
        <h1 className="job_search">Search {default_title}</h1>
        <div className="SearchPageSearch">
          <SearchBar search_value ={this.state.search_value} location_value={this.state.location_value} admin={this.props.admin}/>
          {isSearch && <div className="SearchPageFilters">

          </div>}
        </div>
        <div className="SearchPageResultsSection" >
          <div className="SearchPageResults">
            <div className="SearchPageBanner">
              <WeeklyEmail type="banner"/>
            </div>
            {response_list}
          </div>
          <div className="SearchPageSidebar">
            <WeeklyEmail />
            <div className="PostJobAd">
              <div className="PostJobAdHeader">Are you Hiring?</div>
              {/* <div className="PostJobAdText"> </div> */}
              <div className="AdvertBoxText">Advertise your architectural position on ArchJobs. We understand the job market and can provide you with hands-on help.</div>
              <Link className="PostJobAdLink link" to="/post-job">
                Start Now
              </Link>
            </div>
            <div className="AdvertBox">
              <div className="AdvertBoxHeader">Career Coaching</div>
              <div className="AdvertBoxText">Feeling stuck in your job search? Get your CV and portfolio reviewed, and let's even discuss the best strategies.</div>
              <Link className="PostJobAdLink link" to="/architectural-career-coaching">
              View all Sessions
              </Link>
            </div>
            {/* <div className="ArchAdemia">
              <div className="AdvertBoxHeader">ArchAdemia</div>
              <div className="AdvertBoxText">Want to improve your technical skills? ArchAdemia is here to help. To get 10% off your Annual Membership subscription, enter <strong>ARCHJOBS10</strong>.</div>
              <a className="AdvertBoxLink link" href="https://archademia.com/pricing/?utm_source=archjobs&utm_medium=homepage&utm_campaign=archjobs" target="_blank">Start Now</a>
             
            </div> */}
            
            {/* <div className="AdvertBox">
              <div className="AdvertBoxHeader">Architects Benevolent Society Fundraiser</div>
              <div className="AdvertBoxText">Helping the architectural community to rebuild their lives.</div>
              <a className="AdvertBoxLink link" href="https://www.justgiving.com/page/the-broke-architect-podcast-with-jason-boyle-1677091538620?utm_source=archjobs&utm_medium=homepage&utm_campaign=archjobs" target="_blank">
              Support here
              </a>
            </div> */}
            {savedJobs}
          </div>
        </div>
      </div>
  }
}

/* export the component to be used in other components */
export default withRouter(SearchPage)