import './App.css';
import { useLocation, Navigate, BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import HomePage from './recruitment-components/HomePage'
import AdminPage from './components/AdminPage'
import NavBar from './recruitment-components/NavBar';
import SearchPage from './components/SearchPage'
import PostJob from './components/PostJob'
import Apply from './components/Apply'
import Email from './components/Email'
import GoogleAnalytics from './GoogleAnalytics'
import PrivacyPolicy from './recruitment-components/PrivacyPolicy'
import Footer from './recruitment-components/Footer'
import Team from './components/TheTeam'
import RecruitmentPage from './recruitment-components/RecruitmentPage'
import JobSeekerPage from './recruitment-components/JobSeekerPage'
import ContactPage from './recruitment-components/ContactPage'
import SubmitCVPage from './recruitment-components/SubmitCVPage'
import ForClients from './recruitment-components/ForClients'
import ArticleList from './articles/ArticleList';
// articles
import DubaiGuide from './articles/DubaiGuide';
import DubaiCV from './articles/DubaiCV';
import DubaiPortfolio from './articles/DubaiPortfolio';

function App() {
  return (
    <div className="Page Recruitment">
      <Router>
        <NavBar/>
        <Routes >
          <Route exact path="/" element={<HomePage />} />
          <Route path="/privacy" element={<PrivacyPolicy/>} />
          <Route path="/the-team" element={<Team/>} />
          <Route path='/recruitment' element={<RecruitmentPage/>} />
          <Route path='/job-seekers' element={<JobSeekerPage/>} />
          <Route path='/contact-me' element={<ContactPage/>} />
          <Route path='/submit-cv' element={<SubmitCVPage/>} />
          <Route path='/for-clients' element={<ForClients/>} />

          <Route path="/author/aylin-round" element={<ArticleList author="Aylin Round" dubai={true}/>} />
          <Route path="/articles" element={<ArticleList dubai={true}/>} />

          {/* articles */}
          <Route path="/articles/guide-for-uk-architects-moving-to-dubai" element={<DubaiGuide dubai={true}/>} /> 
          <Route path="/articles/how-to-write-an-architecture-cv-for-the-dubai-market" element={<DubaiCV dubai={true}/>} /> 
          <Route path="/articles/crafting-the-perfect-architectural-portfolio-for-dubais-competitive-market" element={<DubaiPortfolio dubai={true}/>} />
        </Routes >
        <GoogleAnalytics/>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
