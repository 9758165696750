import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class UKQuickGuideCV extends React.Component {
    constructor(props) {
      
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="UKQuickGuideCV"  >
        <p>This is a quick guide on what to include and address in your CV when applying for architectural roles in the UK. I&rsquo;ve reviewed thousands of CVs throughout my 5 years in the industry, and people tend to make the same mistakes. Often, they aren&rsquo;t specific enough, miss important information, or provide too much detail, making the CV overwhelming for hiring managers.</p>
        <p>The goal of your CV is to give the reader an understanding of your skills and experiences, supported by specific examples from your work history and sample portfolio.</p>
        <p/>
        <p>Let&rsquo;s break it down section by section.</p>
        <p/>
        <h3><strong>Personal Information</strong></h3>
        <p>Ensure you don&rsquo;t miss any of these details. Double-check your contact information and if the link to your LinkedIn profile is working:</p>
        <ul>
        <li>Full Name</li>
        <li>Current Job Title</li>
        <li>Phone Number + Country Dial (+44 for the UK)</li>
        <li>Email</li>
        <li>Location (City &amp; Country)</li>
        <li>LinkedIn (if available)</li>
        </ul>
        <p/>
        <h3><strong>About Me</strong></h3>
        <p>This is a short section that gives the reader a quick overview of who you are. Keep this section between 150-200 words maximum. Here&rsquo;s what to include:</p>
        <ul>
        <li>Current Job Title</li>
        <li>Years of Experience</li>
        <li>Sector Experience</li>
        <li>Software Abilities (pick your strongest ones)</li>
        <li>Specific Skills such as site visits, leadership, technical detailing, etc. (again, choose your strongest ones)</li>
        </ul>
        <p/>
        <h3><strong>Work Experience</strong></h3>
        <p>This is where many professionals struggle - they either provide far too much information and turn their CV into a 5 page document or they simply add too little.</p>
        <p>Here’s the issue: if you provide too much info, you overwhelm the reader and fail to highlight your key skills and responsibilities. And if you’re too vague, your impact is lost. It’s important to find the right balance between too much detail and too little.</p>
        <p>Here&rsquo;s what you should do:</p>
        <ul>
        <li>Use bullet points to highlight your skills and responsibilities</li>
        <li>Start with your most recent work experience</li>
        <li><strong>Subheader</strong>: Job Title, Name of the Practice, Location and Dates</li>
        <ul>
        <li>Who did you work with? What was the team structure? Did you work on the project by yourself?</li>
        <li>What type of projects did you work on? I.e. New build, refurbs, extensions; high-rise residential, mixed-use; UK, UAE, Australia - be specific</li>
        <li>What RIBA stages were involved?</li>
        <li>What software did you use?</li>
        <li>What were your responsibilities?</li>
        <li>Can you include any statistics or figures throughout the text?</li>
        </ul>
        </ul>
        <p>Repeat this format for all your experiences. Note that the hiring manager/recruiter will place more emphasis on your last 3-5 years of work experience.</p>
        <p/>
        <h3><strong>Education</strong></h3>
        <p>This section doesn&rsquo;t need to be long. What they want to know:</p>
        <ul>
        <li>Name of the University (if applicable)</li>
        <li>Degree</li>
        <li>Grade</li>
        <li>Year</li>
        </ul>
        <p/>
        <h3><strong>Skills Section</strong></h3>
        <p>This is an important part of your CV because it allows the reader to quickly scan through your skills and get a better idea of your experience. Separate this section into <strong>Soft Skills</strong> and <strong>Software Skills</strong>:</p>
        <ul>
        <li><strong>Soft Skills</strong>: Focus on architectural terms (i.e. technical detailing, site visits, communication, design drawings, hand sketching, liaising with clients and contractors, feasibility studies etc.).</li>
        <li><strong>Software Skills</strong>: (i.e. Revit, Adobe Creative Suite, Rhino, Grasshopper etc.)</li>
        </ul>
        <p/>
        <p/>
        <h3><strong>Awards</strong></h3>
        <p>If you have received any awards that would add credibility to your CV, list them concisely:</p>
        <ul>
        <li>Name of the Award</li>
        <li>Year</li>
        <li>Reason for Receiving the Award (keep it brief)</li>
        </ul>
        <p/>
        <p/>
        <h3><strong>References</strong></h3>
        <p>Avoid providing full reference details on your CV. Why? Because you want to maintain control over when and who will contact your referees. You can prepare them and let them know that Company XYZ will reach out for a reference.</p>
        <ul>
        <li><strong>References</strong>: Available upon request.</li>
        </ul>
        <p/>
        <p/>
        <h3><strong>Other</strong></h3>
        <p>If you want to include additional details, consider a section at the bottom of your CV for:</p>
        <ul>
        <li>ARB registration or other affiliations</li>
        <li>Languages</li>
        <li>Driver&rsquo;s License</li>
        <li>Specific certificates/training courses you have completed</li>
        </ul>
        <p/>
        <p/>
        <h3><strong>Hobbies</strong></h3>
        <p>Including hobbies is optional, they can be a great icebreaker during your first conversation with the hiring manager. However, please avoid generic ones like &ldquo;cooking, gym, running, travel&rdquo;. Instead, be specific and more exciting.</p>
        <p></p>
        <p>If you’d like to learn more about how to structure your CV, what to avoid, and how long it should be, please check out &ldquo;<a href="https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-architectural-cv"><strong>How to Write and Structure an Architecture CV</strong></a>&rdquo;.</p>
        </ArticleFrame>
            }
}