import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


//first go to ArticleData.js and add all the relavant data
//next, rename "YourArticle" below to what you named the article data section
const thisArticleData = "Portfolio"


//rename YourArticle below to your article name
export default class Portfolio extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
            <p><span >Your Portfolio will play a crucial part throughout your architecture career and can make or break your chances of landing your next dream job or securing a new client. We will cover questions like - What is a Portfolio used for? What should I include in my Portfolio? What is the purpose of 2 different Portfolios? How should I structure my Portfolio? and many more.</span></p>
            <p><span >FYI - In this article, I will focus on the architecture portfolio for employment rather than an educational portfolio.</span></p>
            <p/>
            <h2>Purpose of a Portfolio</h2>
            <p/>
            <h3>What is a Portfolio?</h3>
            <div>An architectural portfolio is a unique body of work which represents your experience as a Designer. A portfolio should be used to showcase your best work that you can share with potential employers or clients. It provides a true reflection of your architectural skills, your design approach, technical abilities and how you have evolved in your career.</div>
            <p/>
            <p/>
            <h3>2 Different Types of Portfolios</h3>
            <div>There are 2 different types of Portfolios - the Sample and the Full Portfolio. However, before you start organising your work into a portfolio, ask yourself &ldquo;Who is my audience?&rdquo;, &ldquo;What am I trying to achieve?&rdquo;.</div>
            <p><span >If you&rsquo;re looking for a new job, it is common to submit your CV, Cover Letter with a Sample Portfolio.&nbsp;</span></p>
            <p><span >Your Sample Portfolio goes hand in hand with your CV and shouldn&rsquo;t be longer than 10-12 pages and no larger than 8MB in size. It must compliment your CV i.e. if you mentioned your design and technical skills, the hiring manager would expect to see evidence of that in your Sample Portfolio.&nbsp;</span></p>
            <p><span >Once you have received an interview invite, that&rsquo;s when your Full Portfolio springs into action. You&rsquo;ll be able to show more relevant work examples and prove you&rsquo;re the right fit for the job during the interview. Your Portfolio could include various key projects you worked on, show your involvement across various stages, completed projects (if applicable), your construction detailing skills, your creative problem-solving skills and your design skills.</span></p>
            <p/>
            <p>You can also read what Andy Shaw, Managing Partner at AMA thinks about Sample Portfolios.</p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Andy Shaw - Portfolio.PNG" alt="Screenshot - Andy Shaw Managing Partner at AMA, Chair of RIBA Gulf Chapter. Very good advice Aylin Round - as an employer this is what I am looking for. A few pages of the best work is enough, don't have time to go through 30 pages when so many come in. Even more - if someone emails in a crazy big file over 20MB it's a red flag that they don't know how to handle digital information well."></img>
            <p>To read the full LinkedIn post, <a href="https://www.linkedin.com/feed/update/urn:li:activity:7092051336413724672/" target="_blank" rel="nofollow noopener">click here.</a></p>
            <p/>
            <p/>
            <h3>General Sample Portfolio Formatting Tips</h3>
            <div>Length: Ideally under 10-12 pages</div>
            <div>File type: PDF</div>
            <div>File size: Under 10MB</div>
            <div>Cover Page: Include your Full Name, Job Title, Phone number and Email </div>
            <div>Font type: Easy-to-read fonts like Arial, Times New Roman, or Calibri</div>
            <div>Font size: 10-11</div>
            <div>Structure: Start with your most recent work</div>
            <div>Naming your file: First and last name - Sample Portfolio. (e.g. Jane Doe - Sample Portfolio)</div>
            <p/>
            <p/>
            <h3>General Full Portfolio Formatting Tips</h3>
            <div>Length: Ideally under 40-50 pages</div>
            <div>File type: PDF</div>
            <div>File size: Under 20MB</div>
            <div>Cover Page: Include your Full Name, Job Title, Phone number and Email </div>
            <div>Font type: Easy-to-read fonts like Arial, Times New Roman, or Calibri</div>
            <div>Font size: 10-11</div>
            <div>Structure: Start with your most recent work</div>
            <div>Naming your file: First and last name - Portfolio. (e.g. Jane Doe - Portfolio)</div>
            <p/>
            <p/>
            <h3>What to include in your Portfolio?</h3>
            <div>Depending on your level of seniority, your portfolio will include different types of work. Practices would not expect that a Part 1 has run a large-scale residential scheme but they would expect something like that from someone more senior.</div>
            <p/>
            <p><span ><strong>Interns/Students:</strong></span><span > If you&rsquo;re an architecture student or intern then having a portfolio is an absolute must. You can show future employers your academic work, how you think, your creativity, and how you tackle different design problems. Employers will want to see that you have a general understanding of all the procurement stages</span></p>
            <p><span >New and prospective students need to demonstrate that they have the skills, basic understanding and passion for the subject, rather than the physical work to prove it.&nbsp;</span></p>
            <p/>
            <p><span ><strong>Early Career Architects (1-3 years experience):</strong></span><span > Your portfolio should consist of live projects at different stages and show design and technical drawings. Your portfolio should prove that you have evolved as an architect/designer and have gained more experience. Don&rsquo;t forget to add some of your university work as well.&nbsp;&nbsp;</span></p>
            <p/>
            <p><span ><strong>Mid-Level Architects (3-8 years experience): </strong></span><span >Most hiring managers would expect to see a well-rounded Architect/Designer by this stage, and your Portfolio should reflect that. Whether you have worked across different sectors, worked on more complex schemes or found your niche, showcase your best work and highlight any award-winning schemes. You should certainly include site experience and completed projects.&nbsp;&nbsp;</span></p>
            <p/>
            <p><span ><strong>Senior Architects (8+ years experience): </strong></span><span >By now you would&rsquo;ve gained a lot of valuable experience including team leading, business development skills and more. However, your main priority will be to show a vast mix of schemes you have led, from pre-design through construction phases. I would suggest staying away from schemes which are older than 6+ years unless it is crucial to the position. Stick with your best and most recent work.&nbsp;</span></p>
            <p><span >It can be incredibly hard to choose which schemes to show and which images to use. But keep it simple. Be careful to show only the work that you have had the most involvement in, this is particularly relevant for large projects where you may have been part of a team.</span></p>
            <p/>
            <p/>
            <h3>Want to Improve your Technical Skills and Enhance Your Portfolio?</h3>
            <p>We understand that everyone's experience and exposure to certain software and projects will vary. At ArchJobs, we are always looking for ways to support our community and provide you with trustworthy sources.  </p>
            <p>That's why we've partnered with <a target="_blank" href="http://www.archademia.com?utm_source=ArchJobs&utm_medium=Article">ArchAdemia</a>, who share our mission of providing architectural professionals with the technical skills they need to advance in their careers.</p>
            <p>For professional, ArchAdemia is your bridge from theory to practice. Software tutorials under the guidance of industry professionals will give you the capability to make an impact from day 1. Master the art of drawing, manage projects like a pro, and immerse yourself in podcasts that give you the inside scoop from those who've paved the way.</p>
            <p>But ArchAdemia isn't just tutorials and resources. It's a vibrant community where passions meet, discussions flourish, and connections are forged. It's a space where students and professionals alike share, learn, and grow together, transcending traditional boundaries.</p>
            <p>With 35 meticulously curated <a target="_blank" href="http://www.archademia.com/lessons?utm_source=ArchJobs&utm_medium=Article">courses</a>, offering everything from beginner basics to advanced expertise in parametric design and BIM, we will help you to master architecture. Whether you're refining your software skills or diving deep into design theory, we’ve got you covered.</p>
            <p>To get <a target=" blank" href="https://archademia.com/pricing/"><strong>10% off your Annual Membership subscription</strong></a>, simply enter <strong>ARCHJOBS10</strong>. </p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemia_Course Display.jpg" alt="Screenshot from ArchAdemia website - The image is a screenshot of the homepage of a website that offers courses in 3D design, architecture, and animation software. The homepage features a grid of cards, each of which represents a different course. The cards include the course title, a brief description, and an image. Some of the courses featured on the homepage include: SketchUp Complete Guide, The Perfect Floor Plan,Rhino & Grasshopper Essentials and much more."></img> </p> 
            <p/>
            <p/>
            <h2>Structuring your Portfolio</h2>
            <p/>
            <h3>Selecting your Content + Example&nbsp;</h3>
            <div>Selecting the right content can be difficult especially if you have more than 10 years of experience. Remember, only choose your best work. It&rsquo;s all about Quality over Quantity.&nbsp;</div>
            <p><span >For your Sample Portfolio, present your best and most recent work across 10-12 pages maximum. Ask yourself, does this particular project reflect my current skills and abilities? Does it suit the practice&rsquo;s profile? If not, leave it out.&nbsp;</span></p>
            <p><span >A Full Portfolio could consist of a variety of projects, sectors, stages, hand sketches, model making, technical drawings and design skills. Depending on the role, your portfolio should show them that you are the right candidate for the position.&nbsp;</span></p>
            <p><span ><strong>Tip:</strong> If you have more than 8+ years of experience - focus on the last 5 years.</span></p>
            <p/>
            <p><span ><strong>Example</strong></span></p>
            <p><span >Let&rsquo;s look at an actual job brief and I will show you what your sample portfolio should include - This is a real job brief but I have removed the practice's name.</span></p>
            <p><span >Ask yourself:</span></p>
            <ul>
            <li><span >Do they mention a particular sector?&nbsp;</span></li>
            <li><span >Which software are they using?</span></li>
            <li><span >What are they looking for?</span></li>
            </ul>
            <p><span >I have highlighted some keywords relevant to these questions in the text.</span></p>
            <p/>
            <span ><strong>Job description:</strong></span>
            <p><span ><em>"We are looking for an enthusiastic and experienced ARB registered Architect to join our busy studio to work on national projects, along with other UK-wide studios.</em></span></p>
            <p><span ><em>You will need to have worked in the UK construction/design industry for a minimum of 2 years as an Architect, and be familiar with </em></span><span ><strong><em>Revit </em></strong></span><span ><em>software.</em></span></p>
            <p><span ><em>In this role, you will run and project manage a variety of interesting projects, working both independently on </em></span><span ><strong><em>drawing packages</em></strong></span><span ><em> and managing assistants where necessary to ensure quality control of issued information.</em></span></p>
            <p><span ><em>Candidate Requirements</em></span></p>
            <ul>
            <li><span ><em>Architectural experience in the design of public buildings is essential; eg. </em></span><span ><strong><em>education, healthcare or community;</em></strong></span></li>
            <li><span ><em>Able to carry out design reviews and identify inconsistencies, discrepancies and missing information and record, report and track the close out of these items;</em></span></li>
            <li><span ><em>Good knowledge of UK Building Regulations and the Planning / Building Regulations application process;</em></span></li>
            <li><span ><em>Demonstrable design flair and presentational ability</em></span></li>
            <li><span ><em>An interest in Low / Zero Carbon design and Passivhaus would be an advantage;</em></span></li>
            <li><span ><em>Capable of robust </em></span><span ><strong><em>UK construction detailing</em></strong></span><span ><em>;</em></span></li>
            <li><span ><em>Experience in leading meetings on-site with the client, design and contractor team;</em></span></li>
            <li><span ><em>Experience in delivering projects from </em></span><span ><strong><em>conception to completion;</em></strong></span><span ><em> ideally worked in all RIBA Stages;</em></span></li>
            <li><span ><em>Very good Revit and Autocad skills;</em></span></li>
            <li><span ><em>Experience in using Photoshop and Indesign useful;</em></span></li>
            <li><span ><em>Excellent communication skills: spoken and written."</em></span></li>
            </ul>
            <p><span ><strong>Let&rsquo;s look at the answers:</strong></span></p>
            <ul>
            <li><span >Do they mention a particular sector? Yes, </span><span ><em>Education, Healthcare and Community</em></span></li>
            <li><span >Which software are they using?</span><span ><em> Revit, Autocad, Photoshop and Indesign but the main focus is Revit</em></span></li>
            <li><span >What are they looking for?</span><span ><em> Job running Architect with strong design and construction detailing experience.&nbsp;</em></span></li>
            </ul>
            <p><span >If you know the answers to these 3 questions, this will give you a strong foundation to tailor your Sample Portfolio, </span><a href="https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-cv" target="_blank" rel="noopener"><span>CV</span></a><span > and </span><a href="https://www.archjobs.co.uk/articles/how-to-structure-an-architecture-cover-letter" target="_blank" rel="noopener"><span>Cover Letter</span></a><span >.</span></p>
            <p/>
            <p/>
            <h3>Order</h3>
            <div>Your portfolios should be in chronological order and start with your most recent work.&nbsp;</div>
            <p><span >Present one project at a time.</span></p>
            <p/>
            <p/>
            <h3>Description and Fonts</h3>
            <div>Provide a short description for each project and keep it under 100 words. It should be short and snappy - especially if it&rsquo;s your Sample Portfolio.&nbsp;</div>
            <p><span >Details worth mentioning are:</span></p>
            <ul>
            <li><span >Project Title/Locations</span></li>
            <li><span >Value (if applicable)</span></li>
            <li><span >RIBA Stages</span></li>
            <li><span >Scale</span></li>
            <li><span >Your responsibilities</span></li>
            <li><span >Software&nbsp;</span></li>
            </ul>
            <p><span >For the font, use no more than 2 different types and ideally stick to Arial, Times New Roman or Calibri.&nbsp;</span></p>
            <p><span >Also, please check your documents for any spelling errors.&nbsp;</span></p>
            <p/>
            <p/>
            <h2>Ways to Create Portfolios</h2>
            <p><span >There are a few different ways you can present your Portfolio and I will give you a short overview.</span></p>
            <p><span ><strong>Printed Portfolios:</strong></span><span > I feel like it&rsquo;s the most organic/natural way to present your work in person. However, there are a few things to keep in mind, it can take months to create a physical copy, it can be costly and you will need to keep adding new prints. If you do decide to have a printed version - stick to A3.&nbsp;</span></p>
            <p/>
            <p><span ><strong>PDF Portfolios:</strong></span><span > PDF Portfolios are a quicker and easier way to share your work and you can prepare different versions. Also, most practices specifically ask for a Sample Portfolio in PDF version. Don&rsquo;t forget to name your document - &ldquo;Your Full Name - Portfolio''.&nbsp;</span></p>
            <p/>
            <p><span ><strong>Build a website:&nbsp; </strong></span><span >This is a popular option if you're a freelance Architect/Designer. It will be the perfect way to attract new clients and for people to find you as well.</span></p>
            <p><span >However, if you&rsquo;re only starting your career or you&rsquo;re not thinking of going solo, it might not be the best option for you.</span></p>
            <p/>
            <p><span ><strong>Portfolio Hosting Sites:</strong></span><span > Two platforms which keep popping up are Issuu.com and Behance, and they can be a great way to create and share your work. But I would recommend against it as I find the websites rather slow to load and I feel like it ruins the whole experience.</span></p>
            <p><span >The moral of the story - stick to PDFs and maybe invest in a printed version as well.</span></p>
            <p/>
            <p/>
            <h2>Layout and Design&nbsp;</h2>
            <p/>
            <h3>Design Software</h3>
            <div>When it comes to designing the layout for your Portfolio. Adobe InDesign provides simple tools that let you easily create, drag &amp; drop and edit documents.&nbsp;</div>
            <p><span >In case you want to edit any drawings/images, I would suggest using Photoshop and Illustrator.</span></p>
            <p><span ><strong>Tip:</strong> Please never send a Powerpoint presentation or dozens of single drawings to a hiring manager. This will look extremely unprofessional and they will probably not even open it.</span></p>
            <p/>
            <p/>
            <h3>Cover Page + Examples</h3>
            <div>First impressions count - keep it simple. You could either use one of your hand sketches or go for more of a minimalistic look.</div>
            <p><span ><strong>Tip:</strong> On the cover page and last page, you should include your name, phone number and email address.</span></p>
            <p><span >Below are some simple but clean cover pages</span></p>
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/_Portfolio Cover 1.png" alt="Minimalist Cover Page - very simple Portfolio page - light background, with sample portflio, dates, name, job title and contact details"></img>
            {/* <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/keira_yang_zhan_cover_page.jpg" alt="Minimalist Cover Page by Keira Yang Zhan"></img> */}
            <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Sample Portfolio Cover 2.png" alt="Minimalist Cover Page - very simple Sample Portfolio page - dark background, with sample portflio, dates, name, job title and contact details"></img>
            <p/>
            <p/>
            <h3>Layout&nbsp;</h3>
            <div>Less is more! The layout is very important and shouldn&rsquo;t be confusing or busy. The way you organise and present your portfolio says a lot about you and your presentation skills.&nbsp;</div>
            <p><span >When the hiring manager looks through your Portfolio, is it easy to follow and understand, is there a clear cut between projects, have you provided them with a short description?</span></p>
            <p><span ><strong>Tip:</strong> Only use high-quality images, stick to a font colour and present it in chronological order.&nbsp;</span></p>
            <p/>
            <p/>
            <h3>Length</h3>
            <div>For your Sample Portfolio, aim for a max of 12 pages - remember it's only a sample.</div>
            <p><span >Your Full Portfolio can be up to 40-50 pages long. Show them your best/recent work but don&rsquo;t fall into the trap of showing them everything you ever worked on.&nbsp;</span></p>
            <p/>
            <p/>
            <h2>Summary of Do&rsquo;s and Don&rsquo;ts</h2>
            <p/>
            <h3>Things you should do</h3>
            <div>✔ Prepare both Sample and Full Portfolios.</div>
            <div>✔ Select only your best work for both portfolios, showcasing a range of sectors and project stages.</div>
            <div>✔ Maintain a clear and simple layout with crisp images.</div>
            <div>✔ Provide concise descriptions (max 100 words per image) and showcase your software proficiency.</div>
            <div>✔ Choose the right front cover for your Portfolio - first impressions count</div>
            <div>✔ Include hand sketches.</div>
            <div>✔ Begin with your most recent work in chronological order and consider adding relevant university work if applicable.</div>
            <p/>
            <p/>
            <h3>Things you should avoid doing:</h3>
            <div>✖ Avoid excessive length; Sample Portfolio should be max 12 pages, Full Portfolio under 50 pages.</div>
            <div>✖ Don&rsquo;t start with your oldest work</div>
            <div>✖ Avoid making it too wordy.</div>
            <div>✖ Avoid duplicating similar projects; showcase variety.</div>
            <div>✖ Don't make your Portfolio too busy or inconsistent in layout.</div>
            <div>✖ Use a maximum of two fonts.</div>
            <div>✖ Do not claim others' drawings/images as your own.</div>
            <div>✖ Present your work as one comprehensive document, not single drawings or images.</div>
            <p/>
            <p/>
            <h3>Conclusion</h3>
            <p>Your architecture portfolio is a powerful tool that can significantly impact your career. It serves as a showcase of your skills, design abilities, and professional evolution. Understanding its purpose and tailoring it to your audience is essential, whether you're preparing a sample portfolio for job applications or a comprehensive one for interviews. Remember, quality trumps quantity, so select your best work, maintain a clear and simple layout, and make a strong first impression with your cover page. Avoid common pitfalls like excessive length and complexity, and focus on presenting a diverse range of your achievements. With a well-structured and thoughtfully curated portfolio, you'll increase your chances of securing your dream job or winning over potential clients in the competitive field of architecture.</p>
            </ArticleFrame>
    }
}

// Now, go to the App.js file
// add this line at the top, rename {YourArticle} to whatever you called it. You also need to copy and rename this file to "{YourArticle}.js"
// import YourArticle from './articles/YourArticle'
// Add another line in the Routes section, renaming "YourArticle" again:
// <Route path="/articles/YourArticle" element={<YourArticle/>} />


// now in TERMINAL below, run the following command:
// npm run-script dev
// head to the article page and review the html to make sure its properly formatted
//add <p/> between rows where you want a larger space
//add any images that were missed out (will need to upload these to article_images folder too) eg. <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="Archjobs Keywords when applying"></img>
//add rel="nofollow noopener" to any links so it doesnt impact our SEO eg.  <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="nofollow noopener">Cambridge Dictionary</a>





// finally, publish the changes to github
// When its live, go to search console and inspect the new url: https://search.google.com/search-console?resource_id=sc-domain%3Aarchjobs.co.uk
// Might need a couple of attempts for it to get cached and display properly
// Make sure the rich text snippet is working properly
// If it is, request indexing from google to speed up the process