import withRouter from "../withRouter";
import React, { Component } from 'react';
import Helmet from 'react-helmet'
import './articles.css'
import ArticleData from './ArticleData';
import { WeeklyEmail } from '../components/NewsLetter';
import { NewsLetterPopUp } from '../components/NewsLetter';
import {BsLinkedin, BsTwitter, BsFillBellFill} from 'react-icons/bs'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

class ArticleFrame extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }


    render(){

        let authorData

        const thisArticleData = ArticleData.ArticleData[this.props.ArticleDataName]

        console.log(this.props)
        const JSONschema = {
            "@context": "https://schema.org",
            "@type": "BlogPosting"
        }
        if (thisArticleData.article_title) JSONschema["headline"] = thisArticleData.article_title
        if (thisArticleData.article_title) JSONschema["name"] = thisArticleData.article_title
        if (thisArticleData.article_description) JSONschema["description"] = thisArticleData.article_description
        if (thisArticleData.article_url) JSONschema["url"] = thisArticleData.article_url
        if (thisArticleData.published) JSONschema["datePublished"] = thisArticleData.published.toISOString()
        if (thisArticleData.modified) JSONschema["dateModified"] = thisArticleData.modified.toISOString()

        const images = ["main_img_1x1", "main_img_4x3", "main_img_16x9"]

        for (const image_field of images){
            if (thisArticleData[image_field]){
                if (!(JSONschema.image)){
                    JSONschema.image = []
                }
                JSONschema.image.push(thisArticleData[image_field])
            }
        }
        if (!(JSONschema.image) && thisArticleData.main_img){
            JSONschema.image = [thisArticleData.main_img]
        }

        if (thisArticleData.author && ArticleData.AuthorData[thisArticleData.author]){
            const AuthorDetails = ArticleData.AuthorData[thisArticleData.author]
            JSONschema["author"] =  [{
                "@type": "Person",
                "name": AuthorDetails.name,
                "url": AuthorDetails.profile_page
              }]
              authorData = ArticleData.AuthorData[thisArticleData.author]
        }

        JSONschema["publisher"] =  {
            "name": "ArchJobs",
            "url": "https://www.archjobs.co.uk"
          }

        let dubai = false
        if (this.props.router?.location?.pathname?.includes("archjobsrecruitment.com")){
            JSONschema["publisher"]["name"] = "ArchJobs Recruitment"
            JSONschema["publisher"]["url"] = "https://www.archjobsrecruitment.com"
            dubai = true

        }

        return <div className="ArticlePage">
        <Helmet>
        <meta charSet="utf-8" />
            <title>{thisArticleData.article_title}</title>
            <link rel="canonical" href={thisArticleData?.article_url} />
            <meta name="description" content={thisArticleData?.article_description}/>
            <meta name="og:title" content={thisArticleData?.article_title}/>
            <meta name="og:type" content={"article"}/>
            <meta name="og:image" content={thisArticleData?.main_img}/>
            <meta name="og:url" content={thisArticleData?.article_url}/>
            <script type="application/ld+json">{JSON.stringify(JSONschema)}</script>
        </Helmet>
        <div className="ArticleContainer">
            <img className="ArticleHeaderImg" src={thisArticleData?.main_img} alt={thisArticleData?.article_title+" Main Image"}></img>
            <div className="ArticleTitle">{thisArticleData?.article_title}</div>
            <div className="ArticleSubtitle">
                by {thisArticleData?.author}
            </div>
            {this.props.children} 
            <div className="ArticleCoaching">If you want more advice and 1-to-1 career coaching from Aylin, find out more <Link to="/architectural-career-coaching" className="Link inline">here</Link>. </div>
            {authorData && <div className="AuthorComponent">
                <div className="AuthorProfile">
                    <img src={authorData.profile_img} className="AuthorImg" alt={"Article Author: "+authorData.name}></img>
                    <a className="AuthorName Link" href={authorData.linkedin} target="_blank" rel="noreferrer">
                        <BsLinkedin className="LinkedinLogo"></BsLinkedin>
                        {authorData.name}
                    </a>
                </div>
                <div className="AuthorDescription">
                    {authorData.description}
                    
                </div>
            </div>}
            {!dubai && <div className="NewsletterEmailContainer">
                <div className="NewsletterEmailTitle">Enjoy this article? Get our latest jobs and articles with our weekly newsletter</div>
                <WeeklyEmail/>
            </div>}
        </div>
        {!dubai && <NewsLetterPopUp />}
    </div>
    }
}

export default withRouter(ArticleFrame)