
import React from 'react'
import {
    Navigate, 
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Button from './Buttons.js'
import Autocomplete from '@mui/material/Autocomplete';
import tags from '../data/tags.js'
import Quill from 'quill';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {MdClose} from 'react-icons/md';
import { BiLinkExternal } from 'react-icons/bi';
import axios from 'axios';
import withRouter from '../withRouter'
import MultipleUpload from './MultipleUpload'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BsLaptop, BsChevronLeft } from 'react-icons/bs'
import {Helmet} from "react-helmet";

const BuildingIcon = <svg className="JobTagIcon" viewBox="0 0 24 24" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 5a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v15a1 1 0 1 1-2 0V6H7v14a1 1 0 1 1-2 0V5z" fill="currentColor"/><path d="M11 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM11 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" fill="currentColor"/><path fill-rule="evenodd"  d="M2 11a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2H4v8a1 1 0 1 1-2 0v-9zM17 11a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v9a1 1 0 1 1-2 0v-8h-2a1 1 0 0 1-1-1z" fill="currentColor"/></svg>

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const SearchUrl = "https://api.archjobs.co.uk/search"

const EmailUrl = "https://api.archjobs.co.uk/email_signup"

const RecruiterSearchUrl = "https://api.archjobs.co.uk/search_recruiters"
const RecruiterAnalyticsUrl = "https://api.archjobs.co.uk/recruiter_analytics"

const application_fields = [
  {id: "ApplyWebsite", upload: false, text: "apply on website", multiple: 16},
  {id: "ApplyCoverLetter", text:"Cover Letter", upload: false, type: "checkbox", multiple: 4},
  {id: "ApplyPortfolio", text:"Portfolio", upload: true, type: "checkbox", multiple: 2},
  {id: "ApplyCV", text:"CV", upload: true, type: "checkbox", multiple: 1},
]

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



class Apply extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //for testing
            // job_submission_status: {status: "success"},
            // success_open: true,
            job_submission_status: {},
            success_open: false,
            job_data: {},
            redirect: false,
            recruiter: null,
        }
        this.quill = null
        this.multiSelectValues = {}
        this.data_loading = false
        this.files = []
      }    
    
      componentDidMount = () => {
        
        window.scrollTo(0, 0)
        if (!this.quill && Object.keys(this.state.job_data).length > 0){
          this.quill = new Quill('#jobdescriptioninput', {
            theme: 'snow',
            formats: [
              'bold',
              'header',
              'italic',
              'link',
              'list',
              'blockquote',
              'image',
              'indent'
            ],
            modules: {
              toolbar: [
                [{'header': 1}, {'header': 2}],
                ['bold', 'italic', 'link'],
                ['blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
              ],
              clipboard: {
                matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
              }
            }
          });
        }
        this.get_job_data()
      }

      componentDidUpdate = (prevProps, prevState) => {
        if (prevState.job_data !== this.state.job_data){
          if (!this.quill){
            this.quill = new Quill('#jobdescriptioninput', {
              theme: 'snow',
              formats: [
                'bold',
                'header',
                'italic',
                'link',
                'list',
                'blockquote',
                'image',
                'indent'
              ],
              modules: {
                toolbar: [
                  [{'header': 1}, {'header': 2}],
                  ['bold', 'italic', 'link'],
                  ['blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
                ],
                clipboard: {
                  matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
                }
              }
            });
          }
        }
      }

      updateMultiSelectValue = (id, value) => {
        this.multiSelectValues[id] = value
      }

      recruiterEvent = (recruiter, event_type) => {
        const recruiter_id = recruiter.recruiter_id
        let page = "apply"
        axios.post(RecruiterAnalyticsUrl+`?recruiter_ids=${recruiter_id}&event=${event_type}&page=${page}`).then(response => {
          console.log("Analytics response: ", response.data)
        }).catch(error => {
            console.log(error)
        })
    }

      get_job_data = async() => {
        var location_data = this.props.router.location
        if (this.data_loading){
          return
        }
        if (location_data.pathname.startsWith("/apply")) {
          var url_split = location_data.pathname.split("apply")
          var fullSearchUrl = SearchUrl+location_data.search
          console.log("searching:", fullSearchUrl)
          this.data_loading = true
          axios.get(fullSearchUrl).then(response => {
            this.data_loading = false
            var response_data = response.data
            if ("results" in response.data){
              console.log(response_data)
              response_data.results.forEach(row => {
                var locations = row.location.split(";")
                var location_to_parse = locations[0].replace("https://", "https:\/\/")
                if (row.location.includes(";")){
                  console.log(location_to_parse)
                }
                try {
                  row.parsed_location = JSON.parse(location_to_parse)
                  row.parsed_location_list = locations.map(x => JSON.parse(x.replace("https://", "https:\/\/")))
                } catch (error) {
                  console.log(error)
                  console.log(location_to_parse)
                  row.parsed_location = {}
                }
                var tags = row.tags.split(";")
                row.parsed_tags = []
                row.parsed_tag_types = {}
                tags.forEach(tag => {
                  var [tag_name, tag_type]= tag.split(",")
                  if (!(tag_type in row.parsed_tag_types)){
                    row.parsed_tag_types[tag_type] = []
                  }
                  row.parsed_tag_types[tag_type].push(tag_name)
                  row.parsed_tags.push({tag: tag_name, type: tag_type})
                })
                var application_dict = {}

                var job_apply_method = row.apply_method
                application_fields.forEach(type => {
                  if (job_apply_method > type.multiple){
                    application_dict[type.id] = true
                    job_apply_method = job_apply_method - type.multiple
                  } else {
                    application_dict[type.id] = false
                  }
                })
                row.application_dict = application_dict

                this.setState({job_data: row})
                // get a recruiter to suggest after applying
                let RecruiterFullSearchUrl = RecruiterSearchUrl
                if (row.parsed_location && row.parsed_location.latitude){
                  // just get first location, but can potentially get more
                  RecruiterFullSearchUrl += `?lat=${row.parsed_location.latitude}&lng=${row.parsed_location.longitude}`
                }
                axios.get(RecruiterFullSearchUrl).then(response => {
                  // shuffle, then order by lowest times shown, 
                  let data = response.data.results
                  if (data.length > 0){
                    data.forEach(recruiter => {
                        recruiter.highlight_tags = []
                        if (recruiter.location) {
                            recruiter.locations = recruiter.location.split(";").map(location => {return {city: location.split(",")[0], lat: location.split(",")[1], lng: location.split(",")[2]}})
                            recruiter.locations.forEach(location => {
                              //  check if a specialist city matches locality
                              if (row.parsed_location && row.parsed_location.locality && location.city.toLowerCase() == row.parsed_location.locality.toLowerCase()){
                                recruiter.highlight_city = location
                                recruiter.highlight_tags.push({tag: location.city, type: "location"})
                               
                              }
                            })
                        }
                        recruiter.parsed_tags = {}
                        
                        if (recruiter.tags) {
                            recruiter.tag_list = recruiter.tags.split(";")
                            recruiter.tag_list.forEach(tag => {
                                const tag_data = tag.split(",")
                                if (!recruiter.parsed_tags[tag_data[1]]) {
                                    recruiter.parsed_tags[tag_data[1]] = []
                                }
                                recruiter.parsed_tags[tag_data[1]].push(tag_data[0])

                                if (tag_data[1] == "job_title"  && row.parsed_tag_types["job_title"] && row.parsed_tag_types["job_title"].includes(tag_data[0])){
                                  recruiter.highlight_tags.push({tag: tag_data[0]+" roles", type: tag_data[1]})
                                }
                                if (tag_data[1] == "job_type" && row.parsed_tag_types["job_type"] && row.parsed_tag_types["job_type"].includes(tag_data[0])){
                                  recruiter.highlight_tags.push({tag: tag_data[0], type: tag_data[1]})
                                }
                            })
                        }
                        recruiter.region_matches = []
                        if (response.data.region_matches){
                          recruiter.region_matches = response.data.region_matches
                          if (recruiter.parsed_tags.region){
                            recruiter.region_matches = recruiter.region_matches.filter(region => recruiter.parsed_tags.region.includes(region))
                            if (recruiter.region_matches.length > 0 && !recruiter.highlight_city){
                              recruiter.highlight_tags.push({tag: recruiter.region_matches[0], type: "location"})
                            }
                          }
                        }
                    })
                    data.forEach(recruiter => {
                      // recruiter.date_added format: "Thu, 08 Jun 2023 09:33:35 GMT"
                      const added = new Date(recruiter.date_added)
                      const currentDate = new Date();
                      const timeDifference = currentDate.getTime() - added.getTime();
                      recruiter.days_active  = timeDifference / (1000 * 60 * 60 * 24)
                      recruiter.shown_per_day = recruiter.times_shown //recruiter.times_shown/recruiter.days_active
                    })

                    data.sort(() => Math.random() - 0.5);

                    data.sort((a, b) => {
                      if (a.highlight_city && !b.highlight_city) return -1
                      if (!a.highlight_city && b.highlight_city) return 1
                      if (a.highlight_tags.length > b.highlight_tags.length) return -1
                      if (a.highlight_tags.length < b.highlight_tags.length) return 1
                      return a.shown_per_day - b.shown_per_day
                    });

                    const chosenRecruiter = data[0]
                    this.setState({recruiter: chosenRecruiter}, () => {
                      // first 2 recruiter ids
                      // const recruiter_ids = data.map(recruiter => recruiter.recruiter_id).slice(0, 2).join(",")
                      // axios.post(RecruiterAnalyticsUrl+`?recruiter_ids=${recruiter_ids}&event=impression&page=search`).then(response => {
                      //   console.log("Analytics response: ", response.data)
                      // })
                    })
                  }

                })
                
              })
            }
          })
        }      
      }
      
      Redirect = () => {
        this.setState({redirect: true})
      }

      updateFiles = (files) => {
        this.files = files
      }

      Submit = (type) => {
        if (type == "actual"){
          console.log("submitting")
          this.setState({job_submission_status: {status: "loading"}})
        } else {
            console.log("validating")
            this.setState({job_submission_status: {status: "validating"}})
        }

        const fields = [
            {id: "first_name", field_name:"first_name", required: true, label: "First Name"},
            {id: "last_name", field_name:"last_name", required: true, label: "Last Name"},
            {id: "email", field_name:"email", required: true, label: "Email Address"},
          ]

          var jobSubmission = new FormData()
          let email = ""
          for (const field of fields){
            var input = document.getElementById(field.id)
      
            if (input != null){
              if (field.type == "checkbox"){
                jobSubmission.append(field.field_name, input.checked ? 1: 0)
              } else if (field.type == "numeric") {
                var int_value = parseInt(input.value)
                if (int_value < 0){
                  int_value = null
                }
                jobSubmission.append(field.field_name, int_value)
              } else {
                if ((input.value == "") && (field.required == true)){
                  this.setState({job_submission_status: {status: "error", message: "Required field "+field.label+" not filled out", field: field.id, field_message: "Required Field"}})
                  return
                } else {
                  jobSubmission.append(field.field_name, input.value)
                  if (field.field_name == "email") {
                    email = input.value
                    if (!validateEmail(email)){
                      this.setState({job_submission_status: {status: "error", message: "Invalid email submitted", field: field.id, field_message: "Invalid email format"}})
                      return
                    }
                  }
                }
                // jobSubmission.append(field.field_name, input.value)
              }
            } else {
              console.log("Couldnt get input: ", field.id)
            }
        }
    
        //job description
        if (this.quill){
            var job_description_content = this.quill.root.innerHTML
            // console.log(job_description_content)
            
            if (job_description_content.replace(/<[^>]*>/g, "").replace(/\W/g, "").length == 0){
              if (this.state.job_data.application_dict.ApplyCoverLetter){
                this.setState({job_submission_status: {status: "error", message: "This job requires a Cover Letter, please fill out the cover letter field", field: "job_description", field_message: "Required Field"}})
                return
              }
            } else {
              jobSubmission.append("cover_letter", job_description_content)
            }
        }
        var required_files = []
        if (this.state.job_data.application_dict.ApplyCV) required_files.push("CV")
        if (this.state.job_data.application_dict.ApplyPortfolio) required_files.push("Portfolio")

        console.log(this.files)
        if (this.files.length < required_files.length){
          this.setState({job_submission_status: {status: "error", message: "This application requires a "+required_files.join(" and ")+", please check your attached files", field: "job_description", field_message: "Required Field"}})
          return
        } else {
          this.files.forEach(file => {
            jobSubmission.append("file",file)
          })
        }
        
        //get job details
        if (Object.keys(this.state.job_data).length == 0 ){
          this.setState({job_submission_status: {status: "error", message: "unable to load job data, please refresh the page",}})
          return
        }
        jobSubmission.append("apply_url", this.state.job_data.apply_url)
        jobSubmission.append("job_title", this.state.job_data.job_title)
        jobSubmission.append("company_name", this.state.job_data.company_name)
        if (this.state.job_data.reference_id){
          jobSubmission.append("reference_id", this.state.job_data.reference_id)
        }
        

        var location = ""
        if (("locality" in this.state.job_data.parsed_location) && (this.state.job_data.parsed_location.locality != null)){
          location = this.state.job_data.parsed_location.locality
          // if (this.state.job_data.parsed_location.county && (this.state.job_data.parsed_location.locality != this.state.job_data.parsed_location.county) && (!this.state.job_data.parsed_location.locality.includes(this.state.job_data.parsed_location.county))){
          //   location += ", " + this.state.job_data.parsed_location.county
          // }
        } else if ("region" in this.state.job_data.parsed_location) {
          location = this.state.job_data.parsed_location.region
        }
        var url = "https://www.archjobs.co.uk/job/"
        var shortJobDesc = this.state.job_data.job_title + " role"
        url += this.state.job_data.job_title.replace(/[\W_]+/g, "-")
        url += "-with-"+this.state.job_data.company_name.replace(/[\W_]+/g, "-")
        shortJobDesc += " with "+this.state.job_data.company_name
        if (location){
          url+= "-in-"+location.replace(/[\W_]+/g, "-")
          shortJobDesc += " in "+location
        }
        url+="-"+String(this.state.job_data.job_id)
        url = url.toLowerCase()

        jobSubmission.append("location", location)
        jobSubmission.append("url", url)
        jobSubmission.append("shortJobDesc", shortJobDesc)

        if (this.state.job_data.hiring_name && this.state.job_data.hiring_name.length > 0){
          jobSubmission.append("hiring_name", this.state.job_data.hiring_name)
        }

        if (type=="pre"){
          this.setState({success_open: true})
        }

        // for (var pair of jobSubmission.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]); 
        // }
        //check if we should sign up for newsletter
        var Newsletterinput = document.getElementById("Newsletter")
        if (Newsletterinput != null && Newsletterinput.checked){
          this.signUp(email)
        }

        // console.log(jobSubmission)
        const SubmitUrl = "https://api.archjobs.co.uk/apply" 
        if (type == "actual"){
          axios({
              url: SubmitUrl,
              method: "POST",
              data: jobSubmission,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then(response => {
              console.log(response.data)
              if ("error" in response.data){
                  this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at hello@archjobs.co.uk and let us know!"}})
              } else {
                  this.setState({success_open: true})
                  this.setState({job_submission_status: {status: "success"}})
                  if (this.state.recruiter)
                  this.recruiterEvent(this.state.recruiter, "impression")
                  // setTimeout(() => {
                  //   this.setState({redirect: true})
                  // }, 2000);
              }
            }).catch(error =>{
              console.log("error: ", error)
              this.setState({job_submission_status: {status: "error", message: "Could not submit form, please let us know us at hello@archjobs.co.uk so we can sort it out"}})
            })
        }

        
      }

      signUp = (email) => {
        var email_submit = {}
        console.log(validateEmail(email))
        if ((email == "") || (!validateEmail(email))){
          this.setState({status: {type: "error", message: "Please submit a valid email address"}})
          return
        }
        email_submit["email"] = email
        var submit_date = new Date()
        email_submit["signed_up"] = submit_date.toISOString().replace("T", " ").split(".")[0]
        email_submit["subscribed"] = 1
        axios.post(EmailUrl, {
          body: JSON.stringify(email_submit),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          }
        }).then(response => {
          console.log(response.data)
          if (response.data.status == "success"){
            this.setState({status: {type: "success", message: response.data.message}})
          } else {
            this.setState({status: {type: "error", message: "Error submitting email address: "+JSON.stringify(response.data)}})
          }
        }).catch(error =>{
          console.log("error: ", error)
          var ErrorMessage
          if (error.response) {
            ErrorMessage = error.response.data
          } else {
            ErrorMessage = error.message
          }
          this.setState({status: {type: "error", message: "Network error submitting email address: "+JSON.stringify(ErrorMessage)}})
        })
      }

      handleClose = () => {
        this.setState({success_open: false})
      }

    render() {
    
    var error_text = ""

      if (Object.keys(this.state.job_data).length == 0 ){
        var job_text = "Loading Job Information..."
        var url = "https://www.archjobs.co.uk/job/jobid-"+this.props.router.location.pathname.split("=")[1]
      } else {
        var location = ""
        if (("locality" in this.state.job_data.parsed_location) && (this.state.job_data.parsed_location.locality != null)){
          location = this.state.job_data.parsed_location.locality
          // if (this.state.job_data.parsed_location.county && (this.state.job_data.parsed_location.locality != this.state.job_data.parsed_location.county) && (!this.state.job_data.parsed_location.locality.includes(this.state.job_data.parsed_location.county))){
          //   location += ", " + this.state.job_data.parsed_location.county
          // }
        } else if ("region" in this.state.job_data.parsed_location && this.state.job_data.parsed_location.region !== null) {
          location = this.state.job_data.parsed_location.region
        } else if ("country" in this.state.job_data.parsed_location && this.state.job_data.parsed_location.country !== null) {
          location = this.state.job_data.parsed_location.country
        }
        var url = "https://www.archjobs.co.uk/job/"
        var shortJobDesc = this.state.job_data.job_title + " role"
        url += this.state.job_data.job_title.replace(/[\W_]+/g, "-")
        url += "-with-"+this.state.job_data.company_name.replace(/[\W_]+/g, "-")
        shortJobDesc += " with "+this.state.job_data.company_name
        if (location != ""){
          url+= "-in-"+location.replace(/[\W_]+/g, "-")
          shortJobDesc += " in "+location
        }
        url+="-"+String(this.state.job_data.job_id)
        url = url.toLowerCase()
    
        var job_text = `Application for ${this.state.job_data.job_title} with ${this.state.job_data.company_name}.`

        const application_dict = this.state.job_data.application_dict
        var document_requirements = ""
        if (application_dict.ApplyCV){
          if (application_dict.ApplyCoverLetter){
            document_requirements = <span className='RequiredNote'>A CV and Portfolio are required.</span>
          } else {
            document_requirements = <span className='RequiredNote'>A CV is required.</span>
          }
        } else {
          if (application_dict.ApplyPortfolio){
            document_requirements = <span className='RequiredNote'>A Portfolio is required.</span>
          }
        }
        var coverletter_requirements = ""
        if (application_dict.ApplyCoverLetter){
          coverletter_requirements = <span className='RequiredNote'>A Cover Letter is required.</span>
        } else {
          coverletter_requirements = "(this is optional)"
        }

        var tag_list = []
        if (this.state.job_data.additional_info){
          const info_list = this.state.job_data.additional_info.split(", ")
          info_list.forEach(tag => {
            tag_list.push(<div className="JobTag">{tag}</div>)
          })
        }
        if(this.state.job_data.parsed_tag_types){
          if ("software" in this.state.job_data.parsed_tag_types){
            this.state.job_data.parsed_tag_types.software.forEach(tag => {
              tag_list.push(<div className="JobTag"><BsLaptop className="JobTagIcon"/>{tag}</div>)
            })
          }
          if ("sector" in this.state.job_data.parsed_tag_types){
            this.state.job_data.parsed_tag_types.sector.forEach(tag => {
              tag_list.push(<div className="JobTag">{BuildingIcon}{tag}</div>)
            })
          }
        }

        let additional_notes
        if (this.state.job_data.application_notes || this.state.job_data.hiring_name){
          let notes_list = []
          if (this.state.job_data.application_notes) notes_list.push(<span className="additionalNote">{this.state.job_data.application_notes}</span>)
          if (this.state.job_data.hiring_name) notes_list.push(<span className="additionalNote"> Please address your Letter to <strong>{this.state.job_data.hiring_name}</strong>.</span>)
          additional_notes = <div className="ApplicationNotes"><span className='ApplicationNoteTitle'>Application Notes: </span>{notes_list}</div>
        }

        let img_url = "https://www.archjobs.co.uk/static/images/archjobs_logo.png"
        let short_name = ""
        let highlights_list = []
        let recruiter_url = "/recruiters"
        if (this.state.recruiter){
           img_url="https://cdn.archjobs.co.uk/file/Archjobs/recruiters/" +this.state.recruiter.recruiter_name.replace(/[\W_]+/g, "").replace(/[ +]/g, "").toLowerCase()+".png"
           short_name = this.state.recruiter.recruiter_name.split(" ")[0] 
           this.state.recruiter.highlight_tags.forEach(tag => {
              highlights_list.push(<div className={"RecruiterCardHighlight"}>{tag.tag}</div>)
              // highlights_list.push(<div className={"RecruiterCardHighlight RecruiterCardHighlighthighlight_"+tag.type}>{tag.tag}</div>)
           })
           recruiter_url = "/recruiters/"+this.state.recruiter.recruiter_name.replace(/[\W_]+/g, "-").toLowerCase()+"-"+this.state.recruiter.recruiter_id
        }

        return (
          <div className="SearchPage">
            <Helmet>
                <meta charSet="utf-8" />
                <title>ArchJobs - Apply for {job_text}</title>
                <meta name="robots" content="noindex"/>
              </Helmet>
              <div className="PostJobFormPage">
                <Link className="ResultsGoBack" to={-1} ><BsChevronLeft className="BackArrow"/>Go Back</Link>
                <div className="PostJobFormContainer">
                
                    <div className="PostJobFormHeader"> 
                        Application Form
                    </div>
                    <div className="PostJobFormText"> 
                      {job_text}
                        <a href={url} target="_blank" className="ApplyJobUrl"> 
                          View full job description <BiLinkExternal/>
                        </a>
                    </div>
                    <div className="PostJobFormText"> 
                        Your information is passed straight to the hiring manager and is not stored by us. Please provide suitable contact details for the company to contact you with.
                    </div>

                    <div className="PostJobForm">
                      <form>
                          <div className="PostJobFormHeader">
                              Your Contact Details
                          </div>
                          <div className="PostJobFormInputRow">
                              <div className="PostJobFormInputTitle">First Name</div>
                              <TextField
                                  variant="outlined"
                                  id="first_name"
                                  className="PostJobInput"
                                  type="text"
                                  placeholder="First Name"
                                  error={this.state.job_submission_status.field == "first_name"}
                                  helperText={(this.state.job_submission_status.field == "first_name") && this.state.job_submission_status.field_message}
                                  />
                          </div>
                          <div className="PostJobFormInputRow">
                              <div className="PostJobFormInputTitle">Last Name</div>
                              <TextField
                                  variant="outlined"
                                  id="last_name"
                                  className="PostJobInput"
                                  type="text"
                                  placeholder="Last Name"
                                  error={this.state.job_submission_status.field == "last_name"}
                                  helperText={(this.state.job_submission_status.field == "last_name") && this.state.job_submission_status.field_message}
                                  />
                          </div>
                          <div className="PostJobFormInputRow">
                              <div className="PostJobFormInputTitle">Email Address</div>
                              <TextField
                                  variant="outlined"
                                  id="email"
                                  className="PostJobInput"
                                  type="email"
                                  placeholder="Contact Email"
                                  error={this.state.job_submission_status.field == "email"}
                                  helperText={(this.state.job_submission_status.field == "email") && this.state.job_submission_status.field_message}
                                  />
                          </div>
                          <div className="PostJobFormInputRow">
                              <div className="PostJobFormInputTitle">Newsletter</div>
                              <FormGroup>
                                <FormControlLabel className="NewsletterLabel" control={<Checkbox id="Newsletter"/>} label="Stay up to date on the latest opportunities with our Friday Newsletter" />
                              </FormGroup>
                          </div>
                          <div className="PostJobFormDivider"></div>
                          <div className="PostJobFormHeader">
                              Application Documents
                          </div>
                          {/* <div className="PostJobFormText"> 
                              If you have a job in mind that you would like to post, please give us some info to get us started.
                          </div> */}
                          <div className="PostJobFormInputRow TopAligned">
                              <div className="PostJobFormInputTitle">Upload CV & Sample Portfolio</div>
                              <div className="FileInputContainer">
                                <div className="PostJobFormText"> 
                                  Upload your CV/Portfolio and any other relevant files via the Upload Files button below. Max total file size is 15MB (pdf, doc, docx, jpeg).
                                  <span style={{color: "#353535"}}> {document_requirements}</span>
                                </div>
                                
                                <MultipleUpload updateFiles = {this.updateFiles}/>
                              </div>
                          </div>
                          <div className="PostJobFormInputRow TopAligned">
                              <div className="PostJobFormInputTitle">Cover Letter</div>
                              <div className="JobDescriptionInputContainer">
                                  Please enter your Cover Letter below {coverletter_requirements}
                                  {additional_notes}
                                  <div id="jobdescriptioninput" className="JobDescriptionInput" ></div>
                              </div>
                          </div>
                        </form>
                        <div className="ApplyReviewMessageContainer">

                          {tag_list.length > 0 && <div className="ApplyReviewTagsMessage">
                            <strong>Adding these keywords to your CV & Cover Letter will make your application stronger:</strong>
                            <div className="ApplyReviewTags">
                              {tag_list}
                            </div>
                          </div>}
                          <div className="ApplyReviewMessage">
                            Please take a moment to review your application before submitting.
                          </div>
                        </div>
                        <div className="PostJobFormSubmit"> 
                            <Button btnClassName="JobAdHeaderApplyButton" onClickFunc = {() => {this.Submit("pre")}}>Submit</Button>
                        </div>
                        <div className="PostJobFormError"> 
                            {(this.state.job_submission_status.status == "error") && this.state.job_submission_status.message}
                        </div>
                    </div>
                  </div>
              </div>
              <BootstrapDialog
                  onClose={this.handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.success_open}
                  className="Modal"
                 >
                  <div className="ModalClose"><MdClose className="CloseIcon" onClick={this.handleClose}></MdClose></div>
                  {this.state.job_submission_status.status !== "success" && <div className="Modal">
                    <div className="ApplyModalTitle">Are You Sure?</div>
                    <div className="SuccessModalText">Once you have submitted you cannot edit your application</div>
                    <div className="ApplyModalButtons"> 
                      <Button btnClassName="SmallButton" onClickFunc = {this.handleClose}>Cancel</Button>
                      <Button btnClassName="SmallApplyButton" onClickFunc = {() => {this.Submit("actual")}} disabled={(this.state.job_submission_status.status == "loading") || (this.state.job_submission_status.status == "success") }>Submit</Button>
                    </div>
                    <div className="ApplyModalMessage">
                      {this.state.job_submission_status.status == "loading" && "Submitting..."}
                      {(this.state.job_submission_status.status == "error") && this.state.job_submission_status.message}
                      
                    </div>
                  </div>}
                  {(this.state.job_submission_status.status == "success" && this.state.recruiter) && <div className="Modal">
                  <div className="ApplyModalTitle">Application Successful!</div>
                    <div className="SuccessModalText">Your application has been successfully submitted. You should receive an email to confirm.</div>
                    <div className="ModalRecruiterContainer">
                      <div className="RecruiterCardBanner">
                        Want access to more roles or expert advice? {short_name} could be a good match for you
                      </div>
                      <div className="RecruiterCardHeader" style={{borderBottom: "0px"}}>
                          <img className="RecruiterCardImage" src={img_url} alt={this.state.recruiter.recruiter_name+" - Recruiter Profile Image"}/>
                          <div className="RecruiterCardHeaderRight">
                              <div className="RecruiterCardHeaderInfo">
                                  <div className="RecruiterCardName">{this.state.recruiter.recruiter_name}</div>
                                  <div className="RecruiterCardCompany">{this.state.recruiter.recruiter_title} - {this.state.recruiter.company_name}</div>
                                  <div className="RecruiterCardHighlightText">Expertise: </div>
                                  <div className="RecruiterCardHighlights">{highlights_list}</div>
                              </div>
                          </div>
                      </div>

                    </div>
                    <div className="ApplyModalButtons">
                      <Button btnClassName="SmallButton" onClickFunc = {this.Redirect}>Back to Search</Button>
                      <Link to={recruiter_url} state= {{recruiter: this.state.recruiter}} onClick={() => {this.recruiterEvent(this.state.recruiter, "click")}}>
                        <Button btnClassName="SmallApplyButton" >Work With {short_name}</Button>
                      </Link>
                  
                    </div>
                  </div>}
                  {(this.state.job_submission_status.status == "success" && !this.state.recruiter) && <div className="Modal">
                    <div className="ApplyModalTitle">Success!</div>
                    <div className="SuccessModalText">Your application has been successfully submitted. You should receive an email to confirm.</div>
                    <div className="ApplyModalButtons"> 
                      <Button btnClassName="SmallApplyButton" onClickFunc = {this.Redirect}>Back to Search</Button>
                    </div>
                  </div>}
              </BootstrapDialog>
              {this.state.redirect && <Navigate to="/search" />}
          </div>
        )
      }
    }
  }

  export default withRouter(Apply)