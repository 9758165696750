import React, { Component } from 'react'
import SearchBar from './SearchBar';
import Button from './Buttons'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { WeeklyEmail, NewsLetterPopUp } from './NewsLetter';
import tags from '../data/tags';

const popular_searches = ["Architect", "Senior Architectural Technologist", "BIM Coordinator/Consultant", "Part 1 Architectural Assistant", "Project Architect", "Senior Architect", "Architectural Technologist", "Urban Designer", 'Landscape Architect', 'Part 2 Architectural Assistant', "Hybrid",  "Associate", "Flexible Schedule", "Part-Time", "Fixed Term Contract" ]

// add "Fully Remote", when we have fully remote roles'Landscape Architect', 'Part 1 Architectural Assistant', , "Senior Landscape Architect", "Senior Urban Designer", "Part 3", 'Project Architect', 'Senior Architect'

const search_terms = [...tags.JobTitles, ...tags.WorkArrangements, ...tags.JobType, ...tags.Software, ...tags.Sectors]


class HomePage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }
  
  search = (search_term) => {
    //construct the search url
    //search term
    var search_url = ""
    var search_param = ""
    if (tags.JobTitles.includes(search_term)){
      search_url += search_term.replace(/[\W_]+/g, "-")+"-Jobs"
    } else if (search_terms.includes(search_term)){
      search_url += search_term+"-Architecture-Jobs"
    } else {
      search_url = "Architecture-Jobs"
      if (search_term!= ""){
        search_param = "?search="+search_term
      }
    }
    //location
    // var location_input = document.getElementById("location_input")
    var location_search = ""
    if (location_search != "") {
      search_url += "-in-"+location_search
      if (search_param == ""){
        search_param+="?"
      } else {
        search_param+="&"
      }
      var location_search_data = this.state.location_data.filter(x => x.city == location_search)[0]
      if (location_search_data){
        search_param+="lat="+location_search_data.lat+"&lng="+location_search_data.lng
      } else {
        search_param+="location_lookup=true"
      }
    } else {
      search_url += "-in-United-Kingdom"
    }
    search_url = search_url+search_param
    return "/search/"+search_url
  }


  render() {
    var search_list = []
    for (const search_term of popular_searches){
      search_list.push(<Link to={this.search(search_term)} className="PopularSearchElement">{search_term}</Link>)
    }

    return <div className="HomePage ArchjobsHome" >
    <div className="WhyWorkWithUsBanner">
      <div className="WhyWorkWithUsBannerTextContainer">
        <h1 className="WhyWorkWithUsSectionTitle">The place to advance your Architectural Career</h1>
        <p className = "WhyWorkWithUsSectionSubtitle">
          From open jobs to useful career guides
        </p>

      </div>
      <div className="WhyWorkWithUsBannerBG HomepageBannerBG"> </div>
    </div>
    <div className="HomePageSection">
      <div className="HomePageSplitSection SplitLeft">
        <div className="SplitSectionText">
          <h2 className="HomePageSectionTitle">Are you looking for a new architectural role in the UK?</h2>
          <p className="HomePageSectionSubtitle">
          If you’re ready to take the next step in your career, take a look through our online job board, which only lists architectural positions. You’ll never see a single IT role. Pinky promise. 
          </p>
          <Link to="/search" className="Link">
            <Button className="Button">Search Jobs</Button>
          </Link>
          </div>
          <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/archjobs_recruitment_jobseeker_small.png" alt="ArchJobs for Job Seekers"/>
      </div>
      <div className="SectionSeparator">~</div>
      <div className="HomePageSplitSection SplitRight">
        <div className="SplitSectionText">
          <h2 className="HomePageSectionTitle">Are you looking to advertise your architectural position?</h2>
          <p className="HomePageSectionSubtitle">
          Look no further! Submit the architectural position you are hiring for, and our team will help you write your job advert. We understand that first impressions count, so let’s get it right and increase your chances of attracting suitable talent.
          </p>
          <Link to="/post-job" className="Link">
            <Button className="Button">Submit a Job</Button>
          </Link>
        </div>
        <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_homepage_ad_clients_new.jpg" alt="ArchJobs for Practices"/>
      </div>
    
    <div className="SectionSeparator">~</div>
    <div className="HomePageSplitSection SplitLeft">
        <div className="SplitSectionText">
          <h2 className="HomePageSectionTitle">Feeling stuck in your job search and not sure where you’re going wrong?</h2>
          <p className="HomePageSectionSubtitle">
          Searching for a new job can feel overwhelming, and Aylin has your back. She provides tailored and actionable advice for your unique situation, enabling you to focus on the right steps and take your career to the next level. So, what are you waiting for? Invest in yourself and book a session.
          </p>
          <Link to="/architectural-career-coaching" className="Link">
            <Button className="Button">Book Now</Button>
          </Link>
          </div>
          <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_homepage_coaching.jpg" alt="ArchJobs Career Coaching Sessions"/>
      </div>
      </div>
    <div className="HomePageSectionSeparatorContainer">
      <div className="HomePageSectionSeparator"></div>
    </div>
    <div className="HomePageSection">
      <div className="HomePageSplitSection SplitRight">
        <div className="SplitSectionText">
          <h2 className="HomePageSectionTitle">Hi, I’m Aylin Round the founder and owner of ArchJobs 🙋‍♀️</h2>
          <p className="HomePageSectionSubtitle">
          With over 5 years in the architectural industry, I've gained valuable insights into matching talent with practices. Every job seeker's journey is unique, which is why I built ArchJobs as a platform for architectural professionals to find the resources they need to advance their careers.
          </p>
          <p className="HomePageSectionSubtitle">
          Understanding why talented UK architects are drawn to Dubai, I can help you attract these professionals by refining your hiring strategies, reviewing your job adverts, and providing targeted content feedback for your website or social media.</p>
          <p className="HomePageSectionSubtitle">My aim with ArchJobs is to create the go-to educational platform for architectural professionals. Thank you for being part of this exciting journey.</p>
          <p className="HomePageSectionSubtitle">Aylin</p>
          {/* <Link to="/contact-me" className="Link">
            <Button className="Button">Contact Me</Button>
          </Link> */}
        </div>
        <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruiters/AylinLightBG_1x1.png" alt="Aylin Round - Archjobs Recruitment"/>
      </div>
    </div>
    <div className="HomePageSectionSeparatorContainer">
      <div className="HomePageSectionSeparator"></div>
    </div>
    <div className="HomePageSection NoBottomBorder">
      <div className="HomePageSectionText">
          <h2 className="HomePageSectionTitle">I care about the profession</h2>
          <p className="HomePageSectionSubtitle">I’m passionate about sharing my knowledge to help aspiring architectural professionals. My advice to job seekers has been featured in...</p>
          <div className="HomePageLogos">
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/building_design_logo.png" alt="BD Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/architects_journal_logo_small.jpg" alt="Architects Journal Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/arch_social_logo.png" alt="Architecture Social Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/aspiration_logo.jpg" alt="Aspiration Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/archademia_logo.png" alt="Archidemia Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/ciatwessex_logo.png" alt="CIAT Wessex Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/CareerSidekick.png" alt="Career Sidekick Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/architecturebusinessclub.jpg" alt="Architecture Business Club Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/thebrokearchitectpodcast_logo.png" alt="The Broke Architect Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/riba.png" alt="RIBA Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/grandbestexpo_logo.jpg" alt="Grand Best Expo Logo"/>
            <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/nottinghamandderbysocietyofarchitects_logo.png" alt="Nottingham and Derby Society of Architects Logo"/>
          </div>
      </div>
    </div>
<div className="HomePageContent HomePageNewsletter">
  <div className="HomePageNewsletterBackground">
    <h1 className="NewsletterPageTitle">Weekly Job Alerts Straight to your Inbox</h1>
    <div className="WhyWorkWithUsHeaderText">UK Architecture Roles & Market Updates. 1 Email a Week, no Spam.</div>
      {/* <h2 className="NewsletterPageSubtitle">Sign up for our free weekly newsletter and be the first to know about hiring opportunities. Discover practices offering excellent benefits packages, part-time and contract roles. We'll also keep you in the loop with a quick summary of the past 7 days and any market trends we notice. </h2> */}
    <div className="NewsletterEmailContainer">
          <WeeklyEmail/>
    </div>
  </div>
</div>           
<NewsLetterPopUp />
</div>
    
  }
}

/* export the component to be used in other components */
export default HomePage